<template>
	<common-view></common-view>
</template>
<script>
import CommonView from './SummayOfKYCDetail/CommonView.vue'

export default {
	components: {CommonView},
	data () {
		return {}
	},
	computed: {},
	watch: {},
	methods: {
		submitForm () {
			this.$emit('nextBtnRequest')
		},
		setKYC () {}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () { },
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
}
</script>
<style lang='css' scoped>
</style>