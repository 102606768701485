// token设置、获取、删除
export function SET_token(val) {
    localStorage.setItem('token', val)
}
export function GET_token() {
    return localStorage.getItem('token')
}
export function REMOVE_token() {
    localStorage.removeItem('token')
}
// username设置、获取、删除
export function SET_username(val) {
    localStorage.setItem('username', val)
}
export function GET_username() {
    return localStorage.getItem('username')
}
export function REMOVE_username() {
    localStorage.removeItem('username')
}

// providerOption
export function SET_providerOption(val) {
    localStorage.setItem('providerOption', val)
}
export function GET_providerOption() {
    return localStorage.getItem('providerOption')
}

// area
export function SET_areaCodeList(val) {
    localStorage.setItem('areaCodeList', val)
}
export function GET_areaCodeList() {
    return JSON.parse(localStorage.getItem('areaCodeList'))
}

// auto save
export function SET_AutoSave(val) {
    localStorage.setItem('autosave', val)
}
export function GET_AutoSave() {
    return localStorage.getItem('autosave')
}
export function Remove_AutoSave() {
    return localStorage.removeItem('autosave')
}