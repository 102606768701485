<template>
	<div class="user displayFlex flexAlignCenter">
		<div class="displayFlex flexAlignCenter">
			<img :src="imgs[headerDate.gender]" alt="">
			<span>{{headerDate.name}}</span>
		</div>
		<div class="displayFlex" style="margin-left: auto;">
			<div v-if="headerDate.ai === 'Y'" class="tagItem" style="border-color: #F87F18">
				<span style="color: #F87F18">AI</span>
			</div>
			<div v-if="!!headerDate.cka&&'1,2'.indexOf(headerDate.cka) > -1" class="tagItem" style="border-color: #26BF20">
				<span style="color: #26BF20">CKA</span>
			</div>
			<div v-if="!!headerDate.grade" class="tagItem" style="border-color: #FF4802">
				<span style="color: #FF4802">{{headerDate.grade}}</span>
			</div>
			<i class="el-icon-right pointer iStyle" @click="$router.push('/PersonalInformation')"></i>
		</div>
	</div>
</template>

<script>

export default {
	props: {
    headerDate: {
      type: Object
    }
	},
	components: {},
	data () {
		return {
			imgs: {
				Male: require('@/assets/img/avatar/avatar.mr.lg.png'),
				Female: require('@/assets/img/avatar/avatar.mrs.lg.png'),
			}
		};
	},
	computed: {},
	watch: {},
	methods: {

	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
.user {
	padding: 0 40px 0 20px;
	height: 54px;
	border-bottom: 1px solid #c1c1c1;
}
.tagItem {
	margin-right: 10px;
	height: 22px;
	display: flex;
	align-items: center;
	border: 1px solid;
	padding: 1px 3px;
}
img {
	width: 40px;
	height: 40px;
	margin-right: 10px;
}
i {
	line-height: 26px;
	font-size: 22px;
	color: #707070;
}
</style>