<template>
	<div class='displayFlex flexColumn h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Planning for dependant's protection</Navbar>
		<div class="wrapper displayFlex flexColumn flexAlignCenter h90 OverflowYAuto">
			<!-- 单选 -->
			<el-radio-group v-model="radioVal" size="medium" fill="#FF9941">
				<el-radio-button v-for="item in elRadioGroup" @click.native.prevent="handleChange(item)" :label="item.name" :key="item.id"></el-radio-button>
			</el-radio-group>
			<!-- 表格 -->
			<div v-for="(item, i) in tableArrList" :key="i" style="width: 100%">
				<el-table :data="item" :cell-style="{cursor: 'pointer'}">
					<el-table-column prop="name" :label="item[0].label">
					</el-table-column>
					<el-table-column prop="Amount" label="" width="400">
						<template slot-scope="scope">
							<AmountInput :inputValue.sync="scope.row.AmountShow" :currentRow="scope.row" :currentArr="item" @updateArr="updateArr" v-if="scope.row.hasOwnProperty('Amount')">
							</AmountInput>
							<el-select v-model="scope.row.select" @change="handlerChange(scope.row, i)" :disabled="scope.row.disabled" placeholder="" style="height: 40px;" v-if="!scope.row.hasOwnProperty('Amount')">
								<el-option v-for="(item, index) in scope.row.options" :key="index" :label="item.name" :value="item.code" />
							</el-select>
						</template>
					</el-table-column>
					<el-table-column prop="" label="delete" width="100">
						<template slot="header">
					　　　　<i @click="handlerDelete(item[0].label, item[0].needs)" class="icon el-icon-delete" style="font-size: 24px;" />
					　　</template>
					</el-table-column>
				</el-table>
				<!-- total -->
				<div class="ml60 mt20px mb20px" style="color: #606266;">Total shortfall S$ <span style="color: black;">{{$formatMoney(item[0].short_fall_show)}}</span></div>
			</div>
		</div>
		<!-- 底部total -->
		<div class="bottomClass displayFlex TextAlginCenter">
			<!-- 左侧total -->
			<div style="padding: 15px;">
				<p style="font-size: 22px;">S$ {{$formatMoney(total_for_current)}}</p>
				<p>Total shortfall for {{radioVal}}</p>
			</div>
			<!-- total for all -->
			<div style="padding: 15px;">
				<p style="font-size: 22px;">S$ {{$formatMoney(total_for_All)}}</p>
				<p>Total shortfall for all dependants</p>
			</div>
			<!-- 右侧添加按钮 -->
			<div class="circle">
				<i class="el-icon-plus" @click="AddDetails()"></i>
			</div>
		</div>
		<!-- dialog -->
		<ElementDialog Title="Select" :Visible.sync="Dialog.visible">
			<el-table slot="content" :data="dialogTableData" @row-click="rowClick" :show-header="false">
				<el-table-column prop="name"></el-table-column>
			</el-table>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="Dialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';
import ElementDialog from '@/components/ElementDialog/index.vue';

export default {
	components: { Navbar, AmountInput, ElementDialog },
	data () {
		return {
			// 顶部单选框
			radioVal: '',
			elRadioGroup: [],
			currentid: '', //用来区分节点
			plan_dependant_protection: {},
			// 表格模板
			assetsTableData0: [
				{
					index: 0,
					Amount: '',
					AmountShow: '',
					name: "I would like to provide him/her with a sum assured of",
					value: 'sum_assured',
					// 用于展示
					needs: '',
					label: '',
					short_fall: '',
					short_fall_show: ''
				}, {
					index: 1,
					Amount: '',
					AmountShow: '',
					name: 'Less: Existing insurance',
					value: 'less_existing_insurance',
				}
			],
			assetsTableData1: [
				{
					index: 0,
					Amount: '',
					AmountShow: '',
					name: "I would like to provide him/her with a sum assured of",
					value: 'sum_assured',
					// 用于展示
					needs: '',
					label: '',
					short_fall: '',
					short_fall_show: ''
				}, {
					index: 1,
					Amount: '',
					AmountShow: '',
					name: 'Less: Existing insurance',
					value: 'less_existing_insurance',
				}, {
					index: 2,
					select: '',
					name: 'Preferred hospital type',
					value: 'hospital_type',
					options: global.hospital
				}, {
					index: 3,
					select: '',
					name: 'Preferred ward type',
					value: 'ward_type',
					options: global.governmentOtherBenefit,
					disabled: true
				}, {
					index: 4,
					select: '',
					name: 'Rider for deductible & Co-Insurance',
					value: 'rider_for_deductible',
					options: global.yesOrNo
				}
			],
			// 表格数据---当前人物
			tableArrList: [],
			// 表格数据---所有人物
			AlltableDataObj: {},
			// Dialog弹窗
			Dialog: {
				visible: false,
				status: "Add",
			},
			dialogTableData: global.priority,
			kycAllData: {},
			total_for_All: null
		};
	},
	computed: {
		total_for_current () {
			this.set_total_for_All()
			if (this.AlltableDataObj[this.currentid].length == 0) return '0.00'
			let total = 0
			this.AlltableDataObj[this.currentid].map(res => {
				total += parseFloat(res[0].short_fall || 0)
			})
			return total.toFixed(2)
		}
	},
	watch: {},
	methods: {
		set_total_for_All () {
			let total = 0
			Object.keys(this.AlltableDataObj).map(key => {
				this.AlltableDataObj[key].map(res => {
					total += parseFloat(res[0].short_fall || 0)
				})
			})
			this.total_for_All = total
		},
		// 切换人物
		handleChange (val) {
			this.currentid = val.id
			this.radioVal = val.name
			this.setCurrentData()
		},
		updateArr (arr) {
			// 计算total
			arr[0].short_fall = parseFloat(arr[1].Amount - arr[0].Amount).toFixed(10)
			let show = parseFloat(arr[0].short_fall).toFixed(2)
			arr[0].short_fall_show = isNaN(show) ? '0.00' : show
			// // 更新表格数据
			// let index = this.tableArrList.findIndex(n => n.label === arr[0].label)
			// if (index) this.tableArrList.splice(index, 1, arr)
			// 将表格值赋给kyc
			// 筛选选中id的数据
			let tableArr = this.plan_dependant_protection.dependants_needs_list.filter(n => n.dependant_id === this.currentid)[0]
			tableArr.dependant_total = 0
			// 表格模板赋值
			tableArr.needs_list.map(res => {
				if (res.needs === arr[0].needs) {
					res.sum_assured = arr[0].Amount
					res.less_existing_insurance = arr[1].Amount
					res.short_fall = arr[0].short_fall
				}
				tableArr.dependant_total += parseFloat(res.short_fall)
			})
		},
		// 下拉选变化事件
		handlerChange (val, i) {
			if (val.value === 'hospital_type') {
				let obj = this.tableArrList[i]
				obj[3].disabled = val.select !== 'Government'
				this.$set(this.tableArrList, i, obj)
			}
			let tableArr = this.plan_dependant_protection.dependants_needs_list.filter(n => n.dependant_id === this.currentid)[0]
			tableArr.needs_list[i][val.value] = val.select
			
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.plan_dependant_protection.dependants_needs_list = this.plan_dependant_protection.dependants_needs_list
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.plan_dependant_protection.dependants_total_all = this.total_for_All
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 初始化表格赋值
		setTableData () {
			this.AlltableDataObj = {}
			this.elRadioGroup.map(name => {
				this.AlltableDataObj[name.id] = []
				// 筛选选中id的数据
				let tableArr = this.plan_dependant_protection.dependants_needs_list.filter(n => n.dependant_id === name.id)[0]
				// 表格模板赋值
				tableArr.needs_list.map(res => {
					let tableTemplate = res.is_special == '0' ? JSON.parse(JSON.stringify(this.assetsTableData0)) : JSON.parse(JSON.stringify(this.assetsTableData1))
					tableTemplate[0].Amount = res.sum_assured
					tableTemplate[0].AmountShow = 'S$ ' + this.$formatMoney(parseFloat(res.sum_assured).toFixed(2))
					tableTemplate[1].Amount = res.less_existing_insurance
					tableTemplate[1].AmountShow = 'S$ ' + this.$formatMoney(parseFloat(res.less_existing_insurance).toFixed(2))
					// 624特殊情况 需要包含3个下拉选
					if (res.is_special == '1') {
						tableTemplate[2].select = res.hospital_type
						tableTemplate[3].select = res.ward_type
						tableTemplate[3].disabled = res.hospital_type !== 'Government'
						tableTemplate[4].select = res.rider_for_deductible
					}
					// 表头、总计设置
					tableTemplate[0].needs = res.needs
					tableTemplate[0].label = global.priority.filter(n => n.code == res.needs).length > 0 ? global.priority.filter(n => n.code == res.needs)[0].name : ''
					tableTemplate[0].short_fall = parseFloat(res.less_existing_insurance - res.sum_assured).toFixed(10)
					tableTemplate[0].short_fall_show = parseFloat(tableTemplate[0].short_fall).toFixed(2)
					this.AlltableDataObj[name.id].push(tableTemplate)
				})
			})
			this.setCurrentData()
		},
		// 添加
		AddDetails () {
			this.Dialog.visible = true
		},
		// 表格行点击事件
		rowClick (row) {
			// 判断当前用户 是否重复选择
			// 1.根据currentid获取需要进行操作的数据
			let needs_list = this.plan_dependant_protection.dependants_needs_list.filter(n => n.dependant_id === this.currentid)[0].needs_list
			// 2.判断所选数据是否已经包含在页面中
			let repeatCount = needs_list.filter(n => n.needs === row.code).length
			if (repeatCount === 0) {
				// 不重复,添加
				// 1.添加到kyc数据中
				let obj = {
					needs: row.code,
					sum_assured: "0",
					less_existing_insurance: "0",
					hospital_type: "",
					ward_type: "",
					is_special: row.code == '624' ? '1' : '0',
					rider_for_deductible: "",
					short_fall: "0"
				}
				this.plan_dependant_protection.dependants_needs_list.map(res => {
					if (res.dependant_id === this.currentid) {
						res.needs_list.push(obj)
					}
				})
				// 2.添加到表格数据中
				this.setTableData()
				this.Dialog.visible = false
			} else {
				// 重复
				this.$confirm('Duplicate needs cannot be selected?', 'Alert', {
					confirmButtonText: 'CONFIRM',
					showCancelButton: false
				}).catch()
			}
		},
		// 表格删除数据
		handlerDelete (label, needs) {
			this.$confirm('confirm to delete?', 'Alert', {
				confirmButtonText: 'CONFIRM',
				cancelButtonText: 'CANCEL',
				type: "info",
			}).then(() => {
				// 删除逻辑
				let index, index1 = 0
				this.AlltableDataObj[this.currentid].map((res, i) => {
					if (res[0].label === label) index = i
				})
				this.AlltableDataObj[this.currentid].splice(index, 1)
				this.AlltableDataObj = Object.assign({}, this.AlltableDataObj)
				this.setCurrentData()
				// dependants_needs_list数据删除
				let tableArr = this.plan_dependant_protection.dependants_needs_list.filter(n => n.dependant_id === this.currentid)[0].needs_list
				tableArr.map((res, i) => {
					if (res.needs === needs) index1 = i
				})
				this.plan_dependant_protection.dependants_needs_list.filter(n => n.dependant_id === this.currentid)[0].needs_list.splice(index1, 1)
				// dependants_needs_list 重新计算total
				this.plan_dependant_protection.dependants_needs_list.filter(n => n.dependant_id === this.currentid)[0].dependant_total = this.total_for_current
			}).catch(() => { })
		},
		// 当前表格数据赋值
		setCurrentData () {
			Object.keys(this.AlltableDataObj).map(key => {
				if (key === this.currentid) {
					this.tableArrList = this.AlltableDataObj[key]
				}
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.plan_dependant_protection = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.plan_dependant_protection
		// radio框赋值
		this.elRadioGroup = []
		this.plan_dependant_protection.dependants_needs_list.map(res => {
			if (res.dependant_name) {
				this.elRadioGroup.push({
					name: res.dependant_name,
					id: res.dependant_id
				})
			}
		})
		this.radioVal = this.elRadioGroup[0].name
		this.currentid = this.elRadioGroup[0].id
		// 初始化表格赋值
		this.setTableData()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
}
</script>
<style lang='scss' scoped>
.bottomClass {
	border-top: 1px solid #c7c6c6;
}
.circle {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 10px 30px;
	line-height: 50px;
	text-align: center;
	font-size: 24px;
	color: #fff;
	background: #ff9941;
	cursor: pointer;
	margin-left: auto;
}
</style>