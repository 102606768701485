<template>
	<div>
		<ElementDialog Title="Choose Fund" :Visible.sync="Dialog.visible" :fullscreen="true" class="my-dialog">
			<div slot="content">
				<!-- 查询条件 -->
				<NavbarVue padding="5px 60px">
					<el-form :inline="true" :model="searchParam" class="demo-form-inline" size="medium">
						<el-form-item>
							<el-input v-model="searchParam.funds_name" placeholder="Fund Name">
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchParam.provider_code" @change="selectChange" placeholder="Product Provider" filterable>
								<el-option v-for="(item, index) in providerOptions" :key="index" :value="item.value" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchParam.asset_class" placeholder="Asset Class">
								<el-option v-for="(item, index) in assetClassOptions" :key="index" :value="item.value" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchParam.risk_rating" placeholder="Product Risk Rating">
								<el-option v-for="(item, index) in riskRatingOptions" :key="index" :value="item.value" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchParam.scheme_eligibility" placeholder="Investment Scheme Eligibility">
								<el-option v-for="(item, index) in schemeEligibilityOptions" :key="index" :value="item.value" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-button type="info" @click="getTableData('1')" size="medium" class="grewBtn">Search</el-button>
					</el-form>
				</NavbarVue>
				<!-- product内容 -->
				<div class="content" id="content">
					<div style="font-size: 20px;color: #5D5D5D;">{{pages.total}} Product(s)</div>
					<!-- 产品列表盒子 -->
					<div class="mt20px displayFlex flexWrap flexJCspaceBetween" style="height: 650px;overflow-y: scroll;">
						<!-- 各个产品 -->
						<div class="box displayFlex flexColumn" style="height: 150px;" v-for="(item, index) in boxContent" @click="AddInfomation(item)" :key="index">
							<div class="box_title">{{item.product_name}}</div>
							<div class="box_text">
								<div class="IncomeStyle w100px h40px" >{{item.provider_short_name}}</div>
								<!-- <div class="ellipsis" v-ellipsis="{ 'line': 2, 'showTitle': true}">{{item.product_description}}</div> -->
								<div class="ellipsis1" :title="item.product_description">{{item.product_description}}</div>
							</div>
						</div>
					</div>
					<PaginationVue :pages="pages" listKey="getTableData" pageKey="pages" :Multilevel="true" @SetPagination="$SetPagination">
					</PaginationVue>
				</div>
			</div>
		</ElementDialog>
		<!-- CI -->
		<ElementDialog :Title="CIDialog.title" :Visible.sync="CIDialog.visible" :showClose="false" class="my-dialog-title">
			<el-form slot="content" :model="CIDetailsForm" v-if="CIDialog.visible" class="my-form addWidth">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Lump Sum Amount" prop="amount">
							<amount-input-form :currentObj="CIDetailsForm" :inputValue="CIDetailsForm.amount" fieldName="amount" @updateArr="updateArr"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Allocation of Premium (%)" prop="percentage_allocation">
							<el-input v-model="CIDetailsForm.percentage_allocation" type="number" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Regular Investment Scheme (RIS)" prop="type_account">
							<amount-input-form :currentObj="CIDetailsForm" :inputValue="CIDetailsForm.type_account" fieldName="type_account" @updateArr="updateArr"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Allocation of Premium (%)" prop="ris_allocation">
							<el-input v-model="CIDetailsForm.ris_allocation" type="number" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Remarks" prop="remarks">
							<el-input v-model="CIDetailsForm.remarks" type="textarea" style="margin-top: 35px;" placeholder="Remarks"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="SaveAdd('CI')">Confirm</el-button>
				<el-button @click="CIDialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
		<!-- ILP -->
		<ElementDialog :Title="ILPDialog.title" :Visible.sync="ILPDialog.visible" :showClose="false" class="my-dialog-title">
			<el-form slot="content" :model="ilpDetailsForm" v-if="ILPDialog.visible" class="my-form addWidth">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Lump Sum Amount/RSP Amount" prop="amount">
							<amount-input-form :currentObj="ilpDetailsForm" :inputValue="ilpDetailsForm.amount" fieldName="amount" @updateArr="updateArr1"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Percentage Allocation for RSP (%)" prop="percentage_allocation">
							<el-input v-model="ilpDetailsForm.percentage_allocation" type="number" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Type Account" prop="type_account">
							<el-input v-model="ilpDetailsForm.type_account"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Remarks" prop="remarks">
							<el-input v-model="ilpDetailsForm.remarks" type="textarea" style="margin-top: 35px;"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="SaveAdd('ILP')">Confirm</el-button>
				<el-button @click="ILPDialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
		<!-- 除了CI\ILP之外的 -->
		<ElementDialog :Title="LIDialog.title" :Visible.sync="LIDialog.visible" :showClose="false" class="my-dialog-title">
			<el-form slot="content" :model="liDetailsForm" v-if="LIDialog.visible" class="my-form addWidth">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Lump Sum Amount" prop="amount">
							<amount-input-form :currentObj="liDetailsForm" :inputValue="liDetailsForm.amount" fieldName="amount" @updateArr="updateArr2"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Type Account" prop="type_account">
							<el-input v-model="liDetailsForm.type_account"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Remarks" prop="remarks">
							<el-input v-model="liDetailsForm.remarks" type="textarea" style="margin-top: 35px;" placeholder="Remarks"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="SaveAdd('LI')">Confirm</el-button>
				<el-button @click="ILPDialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
	</div>
</template>

<script>
import ElementDialog from "@/components/ElementDialog/index.vue";
import NavbarVue from "@/components/Navbar.vue";
import PaginationVue from "@/components/Pagination.vue";
import { productCommonInfoQuery } from '@/api/Product'
import { getProductFundsByParam, getAllMatchRiskRating } from '@/api/KYC/index'
import { GET_username } from '@/utils/localStorage';
import { GET_providerOption } from '../../../../utils/localStorage';
import AmountInputForm from '../../components/amountInputForm.vue';
export default {
	components: { ElementDialog, NavbarVue, PaginationVue, AmountInputForm },
	props: ['searchObj', 'product'],
	data () {
		return {
			// product--------------------------------------
			Dialog: {
				visible: false,
				status: "Add",
			},
			searchParam: {
				funds_name: '',
				provider_code: '',
				asset_class: '',
				risk_rating: '',
				scheme_eligibility: ''
			},
			boxContent: [],
			productLineOptions: [],
			providerOptions: [
				{
					value: '',
					label: 'All'
				}
			],
			assetClassOptions: global.assetClassOptions,
			riskRatingOptions: global.riskRatingOptions,
			schemeEligibilityOptions: global.schemeEligibilityOptions,
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			currentProduct: {},
			// fund详情页-----------------------------
			CIDialog: {
				visible: false,
				status: "Add",
				title: ''
			},
			ILPDialog: {
				visible: false,
				status: "Add",
				title: ''
			},
			LIDialog: {
				visible: false,
				status: "Add",
				title: ''
			},
			CIDetailsForm: {
				amount: '',
				percentage_allocation: '',
				ris_allocation: '',
				type_account: '',
				remarks: ''
			},
			ilpDetailsForm: {
				amount: '',
				type_account: '',
				percentage_allocation: '',
				remarks: ''
			},
			liDetailsForm: {
				amount: '',
				type_account: '',
				remarks: ''
			},
			// true 表示从preview页面打开  false表示从2.2主产品页面打开
			noRecommendation: false,
			kycAllData: {},
			dataValue: {}
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.CIDetailsForm = arr
			this.CIDetailsForm.percentage_allocation = parseFloat(arr.amount) / parseFloat(this.product.benefit_amount) * 100
			this.CIDetailsForm.ris_allocation = parseFloat(arr.type_account) / parseFloat(this.product.premium_amount) * 100
		},
		updateArr1 (arr) {
			this.ilpDetailsForm = arr
			this.ilpDetailsForm.percentage_allocation = parseFloat(arr.amount) / parseFloat(this.product.premium_amount) * 100
			console.log(this.product.benefit_amount);
		},
		updateArr2 (arr) {
			this.liDetailsForm = arr
		},
		// 获取product provider
		async getCommonInfo () {
			if (GET_providerOption()) {
				this.providerOptions = JSON.parse(GET_providerOption())
			} else {
				let res = await productCommonInfoQuery({ advisor_code: GET_username() })
				res.content.provider.map(provider => {
					this.providerOptions.push({ value: provider.provider_code, label: provider.provider_name })
				})
			}
			// 查询数据
			this.getTableData()
		},
		getTableData (val) {
			let obj = {
				page_number: val ? val : String(this.pages.pageNum),
				page_size: String(this.pages.pageSize),
			}
			this.pages.pageNum = obj.page_number
			getProductFundsByParam({ ...obj, ...this.searchParam, ...this.searchObj }).then(res => {
				this.pages.total = parseInt(res.content.total)
				this.boxContent = res.content.fund_products
			})
		},
		// 打开详细信息
		AddInfomation (row) {
			if (row.product_type_name === 'ILP') {
				this.ilpDetailsForm = this.$ResetFrom(this.ilpDetailsForm)
				this.ILPDialog.title = row.product_name
				this.ILPDialog.visible = true
				this.currentProduct = row
				console.log(this.ilpDetailsForm);
			} else if (row.product_line_code === 'CI' || row.product_line_code_str === 'CI') {
				this.CIDetailsForm = this.$ResetFrom(this.CIDetailsForm)
				this.CIDialog.title = row.product_name
				this.CIDialog.visible = true
				this.currentProduct = row
			} else {
				this.liDetailsForm = this.$ResetFrom(this.liDetailsForm)
				this.LIDialog.title = row.product_name
				this.LIDialog.visible = true
				this.currentProduct = row
			}
		},
		// 保存详细信息
		async SaveAdd (temp) {
			let risk = await getAllMatchRiskRating({})
			let risk_profile = ''
			let score = parseInt(this.dataValue.risk_profile_questionnaire.score)
			let myMatch = risk.content.find(item => item.risk_rating.indexOf(this.currentProduct.product_risk_rating) > -1)
			if (myMatch) {
				if (typeof myMatch.score_value_min != 'undefined') {
					myMatch.score_value_min = parseInt(myMatch.score_value_min)
					// If with min attribute
					if (score == myMatch.score_value_min) {
						risk_profile = 'Match';
					} else if (score > myMatch.score_value_min) {
						risk_profile = 'Below';
					}
					// If with Max attribute
					if (typeof myMatch.score_value_max != 'undefined') {
						myMatch.score_value_max = parseInt(myMatch.score_value_max)
						if (
							score >= myMatch.score_value_min &&
							score <= myMatch.score_value_max
						) {
							risk_profile = 'Match';
						} else if (score > myMatch.score_value_max) {
							risk_profile = 'Below';
						}
					}
				} else {
					// If there is no condition, it is below
					risk_profile = 'Below';
				}
			}
			let obj = {
				needs_analysis_list: this.currentProduct.financial_goals.split('|'),
				fund_id: this.currentProduct.infinitum_product_code,
				fund_name: this.currentProduct.product_name,
				risk_profile: risk_profile,
				product_risk_rating: this.currentProduct.product_risk_rating,
				is_select: this.noRecommendation ? 'Y' : 'N',
				is_recommendation: this.noRecommendation ? 'N' : 'Y',
				product_description: this.currentProduct.product_description,
				product_img: this.currentProduct.product_img,
				provider_logo_url: this.currentProduct.provider_logo_url,
				company: this.currentProduct.provider_name,
				product_line_code: this.currentProduct.product_line_code
			}
			if (temp === 'ILP') {
				Object.assign(obj, this.ilpDetailsForm)
			} else if (temp === 'CI') {
				Object.assign(obj, this.CIDetailsForm)
			} else {
				Object.assign(obj, this.liDetailsForm)
			}
			this.CIDialog.visible = false
			this.ILPDialog.visible = false
			this.LIDialog.visible =false
			this.Dialog.visible = false
			this.$emit('add_product_list', obj)
		},
		// 选择产品页面-查询条件-needs全选
		selectChange (val) {
			if (val.includes('selectAll')) {
				let arr = []
				this.productLineOptions.map(res => { arr.push(res.code) })
				this.searchParam.needs = Object.assign([], arr)
			}
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.dataValue = this.kycAllData.kyc.client[this.kycClient]
		this.getCommonInfo()
		this.noRecommendation = !this.$router.currentRoute.query.hasOwnProperty('proposal')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
.box {
	align-items: flex-end;
	width: 45%;
	margin-top: 30px;
	cursor: pointer;
}
.box_title {
	margin-right: auto;
	font-size: 22px;
	color: #5a5b5e;
}
.box_text {
	width: 90%;
	font: menu;
	font-size: 16px;
	color: #5d5d5d;
}
.IncomeStyle {
	border: 1px solid #409eff;
    color: #409eff;
    margin-top: 10px;
    border-radius: 3px;
    line-height: 40px;
    white-space: nowrap;
    float: right;
    width: fit-content;
	padding: 0 10px;
	cursor: pointer;
}
.ellipsis {
	margin-top: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.ellipsis1 {
	margin-top: 60px;
	overflow:hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: -webkit-box;
}

.content {
	flex: 1;
	overflow-y: scroll;
	padding: 15px 60px 10px 60px;
}
.overFlow {
	overflow: scroll;
}

.el-form >>> .el-input__inner {
	height: 50px !important;
}
/* form表单样式 */
.demo-form-inline {
	display: flex;
}

.addWidth >>> .el-input__inner {
	padding-left: 260px;
}
.addWidth >>> .el-input--prefix .el-input__inner {
	padding-left: 260px;
}
</style>