import { checkUserPassWord, loginOut, sendVerification, checkVerificationCode } from '@/api/Login'
import { Message } from 'element-ui'
import router from 'router'
import { GET_token, SET_token, SET_username, GET_username } from '../../utils/localStorage'
const name = {
    state: {
        // 用户名
        UserName: GET_username(),
        // Token
        Token: GET_token(),
        // 當前所在頁面節點 0,1,2,3
        PageItem: 0,
        // rep信息
        Rep: {},
        // step为1展示账号密码  step为2展示发送code
        Step: '1',
        // ResetPwdStep为1展示id\code  ResetPwdStep为2展示密码 确认密码
        ResetPwdStep: '2',
        // 倒计时临时存储
        countData: null,
        // 用户名称集合(KYC流程顶部中间标题、3.3名称)
        clientNameArr: []
    },

    getters: {
    },

    mutations: {
        SET_USERNAME(state, data) {
            state.UserName = data
        },
        SET_TOKEN(state, data) {
            state.Token = data
        },
        SET_PAGEITEM(state, data) {
            state.PageItem = data
        },
        SET_REP(state, data) {
            state.Rep = data
        },
        SET_STEP(state, data) {
            state.Step = data
        },
        SET_RESETPWD_STEP(state, data) {
            state.ResetPwdStep = data
        },
        SET_COUNTDATA(state, data) {
            state.countData = data
        },
        SET_CLIENTNAME(state, data) {
            state.clientNameArr = data
        }
    },

    actions: {
        LoginByUserName({ commit }, data) {
            return new Promise((resolve, reject) => {
                SET_username(data.infinitum_rep_code)
                commit('SET_USERNAME', data.infinitum_rep_code)
                checkUserPassWord(data).then(res => {
                    // {}表示账号密码错误
                    if (JSON.stringify(res.content) == "{}") {
                        Message.error(res.rtn_mesg)
                        return false
                    }
                    // 账号密码登录
                    if (res && !res.content.sendOPT) {
                        successLogin(commit, res.content.token, data.infinitum_rep_code)
                        return resolve()
                    }
                    // 需要发送otp的情况(修改密码页面跳转过去的不需要发送验证码)
                    if (res && router.currentRoute.query.code !== 'ResetPassword') { 
                        sendCode(commit, data.infinitum_rep_code)
                        return false
                    } else {
                        return Promise.reject('error')
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        LogoutByUserName({ commit }, data) {
            return new Promise((resolve, reject) => {
                loginOut(data).then(res => {
                    if (res.rtn_code === '101') {
                        successLogin(commit, '', '')
                        router.push('/login')
                        localStorage.clear()
                        return resolve()
                    } else {
                        return Promise.reject('Logout error')
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        LoginByVerificationCode({ commit }, data) {
            return new Promise((resolve, reject) => {
                SET_username(data.infinitum_rep_code)
                commit('SET_USERNAME', data.infinitum_rep_code)
                checkVerificationCode(data).then(res => {
                    if (res.content.result === '1') {
                        successLogin(commit, res.content.token, data.infinitum_rep_code)
                        return resolve()
                    } else {
                        Message.error(res.rtn_mesg)
                        return Promise.reject('error')
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
}
function sendCode(commit, name) {
    sendVerification({
        infinitum_rep_code: name,
        verificationCodeType: 1
    }).then(res => {
        if (res.content.result === '1') {
            commit('SET_STEP', '2')
        } else {
            Message.error(res.rtn_mesg)
        }
    })
}
function successLogin(commit, token, name) {
    commit('SET_STEP', '1')
    SET_token(token)
    commit('SET_TOKEN', token)
    if (name === '') {
        SET_username(name)
        commit('SET_USERNAME', name)
    }
}
export default name