<template>
	<ElementDialog Title="Assumptions" :Visible.sync="Dialog.visible">
		<div slot="content">
			<el-form label-position="right" label-width="300px" :model="AssumptionsFormDialog">
				<el-form-item label="What I feel is a reasonable annual rate of inflation （%）">
					<el-input v-model="AssumptionsFormDialog.annual_growth_rate" @input="AssumptionsFormDialog.annual_growth_rate = $util.inputReg(AssumptionsFormDialog.annual_growth_rate)"></el-input>
				</el-form-item>
				<el-form-item label="What I feel is a reasonable annual rate of return (ROR) on investment（%）">
					<el-input v-model="AssumptionsFormDialog.ror" @input="AssumptionsFormDialog.ror = $util.inputReg(AssumptionsFormDialog.ror)"></el-input>
				</el-form-item>
				<el-form-item label="*Inflation adjusted rate of return (i) (%)">
					<el-input v-model="AssumptionsFormDialog.inflation_adjusted" style="width: 85%;" @input="AssumptionsFormDialog.inflation_adjusted = $util.inputReg(AssumptionsFormDialog.inflation_adjusted)"></el-input>
					<el-button type="warning" @click="calculateYield" size="medium" style="position: absolute;right: 0;">Reset</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div slot="BtnSolt" class="TextAlginCenter">
			<el-button type="warning" @click="handlerAccept" size="medium">Accept</el-button>
			<el-button type="danger" @click="handlerCancel" size="medium">Cancel</el-button>
		</div>
	</ElementDialog>
</template>

<script>
import ElementDialog from '@/components/ElementDialog/index.vue';

export default {
	components: { ElementDialog },
	props: {
		assumptions: {
			type: Object,
			default: {}
		},
		updateAssumptionsForm: {
			type: Function,
			default: null
		}
	},
	data () {
		return {
			AssumptionsFormDialog: {
				annual_growth_rate: '',
				ror: '',
				inflation_adjusted: ''
			},
			Dialog: {
				visible: false,
				status: "Add",
			},
		};
	},
	computed: {
		// 计算收益率
		inflation_adjusted () {
			let ror = 1 + parseFloat(this.AssumptionsFormDialog.ror) / 100
			let annual = 1 + parseFloat(this.AssumptionsFormDialog.annual_growth_rate) / 100
			return ((ror / annual - 1) * 100).toFixed(10)
		}
	},
	watch: {
		inflation_adjusted (val) {
			this.AssumptionsFormDialog.inflation_adjusted = val
		},
		'Dialog.visible' (val) {
			if (val) {
				this.calculateYield()
			}
		}
	},
	methods: {
		// 计算收益率
		calculateYield () {
			let ror = 1 + parseFloat(this.AssumptionsFormDialog.ror) / 100
			let annual = 1 + parseFloat(this.AssumptionsFormDialog.annual_growth_rate) / 100
			this.AssumptionsFormDialog.inflation_adjusted = ((ror / annual - 1) * 100).toFixed(10)
		},
		// accept按钮
		handlerAccept () {
			this.Dialog.visible = false
			this.updateAssumptionsForm(this.AssumptionsFormDialog)
		},
		// cancel按钮
		handlerCancel () {
			this.Dialog.visible = false
			this.updateAssumptionsForm('close')
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.AssumptionsFormDialog = JSON.parse(JSON.stringify(this.assumptions))
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
}
</script>
<style lang='css' scoped>
</style>