import axios from 'utils/request.js'
export const checkUserPassWord = (data) => {
    return axios.request({
        url: "/api/dms/advisorPortalInterfaceCheck/checkUserPassWord",
        method: "post",
        title: "",
        data
    })
}
export const loginOut = (data) => {
    return axios.request({
        url: "/api/dms/advisorPortalInterfaceCheck/loginOut",
        method: "post",
        title: "",
        data
    })
}
export const updatePassword = (data) => {
    return axios.request({
        url: "/api/dms/advisorPortalInterfaceCheck/updatePassword",
        method: "post",
        title: "",
        data
    })
}
export const sendVerification = (data) => {
    return axios.request({
        url: "/api/dms/advisorPortalInterfaceCheck/sendVerification",
        method: "post",
        title: "",
        data
    })
}
export const checkVerificationCode = (data) => {
    return axios.request({
        url: "/api/dms/advisorPortalInterfaceCheck/checkVerificationCode",
        method: "post",
        title: "",
        data
    })
}