/*
 *@Author: 段彩翔
 *@Date: 2022-04-13 11:24:59
 *@Description: 标题组件
*/
<template>
   <div class=''>
       <p class="firstTitle" v-if="titleIndex === 1">{{Title}}</p>
       <div class="secondTitle" v-if="titleIndex === 2">{{Title}}</div>
       <div class="thirdTitle" v-if="titleIndex === 3">{{Title}}</div>
   </div>
</template>

<script>

export default {
  components: {},
  props: {
      Title: {
          type: String,
          default: '1123'
      },
      //区分firstTitle、secondtitle
      titleIndex: {
          type: Number,
          default: 1
      }
  },
  data() {
    return {
  
    };
  },
  computed: {},
  watch: {},
  methods: {
  
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  
  },
  }
</script>
<style lang='css' scoped>
.firstTitle {
	color: #f36a10;
	font-size: 26px;
}
/* 带下划线的黑色title */
.secondTitle {
    font-size: 18px;
    width: 95%;
    color: #3C3C3C;
    border-bottom: 1px solid #8B8B8B;
}
/* 浅灰色title */
.thirdTitle {
    font-size: 26px;
    color: #707070;
}
</style>