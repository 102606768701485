<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Retirement Fund</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="Retirement Fund - Annual Retirement income required at retirement age" />
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<el-input v-if="scope.row.type === 'disabled'" v-model="scope.row.AmountShow" disabled></el-input>
						<el-input v-else-if="scope.row.type === 'num'" type="number" v-model="scope.row.Amount"></el-input>
						<div v-else-if="scope.row.type === 'cal'" class="displayFlex flexAlignCenter">
							<span>{{scope.row.AmountShow}}</span>
							<img src="@/assets/img/calculation.png" @click="openAssumptions" alt="" class="ml20px">
						</div>
						<span v-else-if="scope.row.type === 'none'"></span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :ifAmount="scope.row.ifAmount" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr">
						</AmountInput>
					</template>
				</el-table-column>
			</el-table>
			<!-- total_retirement -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total(surplus/shortfall) S$ <span style="color: black;">{{$formatMoney(total_retirement)}}</span></div>
		</div>
		<AssumptionsDialog ref="AssumptionsDialog" :assumptions="AssumptionsForm" :updateAssumptionsForm="updateAssumptionsForm" :key="timer"></AssumptionsDialog>
	</div>
</template>

<script>
import Navbar from '../../../../components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';
import AssumptionsDialog from './AssumptionsDialog.vue';
import utils from './index'

export default {
	components: { Navbar, AmountInput, AssumptionsDialog },
	data () {
		return {
			AssumptionsForm: {
				annual_growth_rate: '',
				ror: '',
				inflation_adjusted: ''
			},
			assetsTableData: [
				{
					Amount: '0',
					name: 'Years to retirement',
					value: 'year_retirement',
					type: 'num'
				},
				{
					Amount: '0',
					name: 'Annual income required (PV)',
					value: 'annual_income_required_pv',
				}, {
					Amount: '0',
					name: 'Average inflation rate',
					value: 'average_inflation_rate',
					ifAmount: false
				}, {
					Amount: '0',
					name: 'Annual income required (FV)',
					value: 'fiannual_income_required_fveld',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Less: Existing regular income (annualised)',
					value: 'less_existing_regular_income'
				}, {
					Amount: '0',
					name: 'Total retirement income required for retirement (at retirement age)',
					value: 'total_retirement_income_required',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'First year income required at retirement age (PMT)',
					value: 'first_year_income',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Duration of retirement',
					value: 'duration_of_retirement',
					type: 'num'
				}, {
					Amount: '0',
					name: 'Inflation adjusted rate return',
					value: 'adjusted_rate_return',
					type: 'cal'
				}, {
					Amount: '0',
					name: 'Total amount required at start of retirement',
					value: 'total_amount_required',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Available funds to meet this need',
					value: 'available_funds_meet_need'
				}, {
					Amount: '0',
					name: 'Less: Maturity value of insurance plan(s) at retirement',
					value: 'less_maturity_value'
				}, {
					Amount: '0',
					name: 'Less: Total asset to be used or liquidated for this need',
					value: 'less_total_asset'
				}
			],
			kycAllData: {},
			timer: ''
		};
	},
	computed: {
		total () {
			let num1, num2, num3, num4, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'less_total_asset') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'less_maturity_value') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'available_funds_meet_need') num3 = parseFloat(res.Amount || 0)
				if (res.value === 'total_amount_required') num4 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 + num2 + num3 - num4).toFixed(10)
			return sum
		},
		total_retirement () {
			return parseFloat(this.total).toFixed(2)
		},
		// fiannual_income_required_fveld
		fiannualIncomeRequiredFveld () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'year_retirement') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'annual_income_required_pv') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'average_inflation_rate') num3 = parseFloat(res.Amount || 0)
			})
			sum = (Math.pow(1 + num3 / 100, num1) * num2).toFixed(10)
			return sum
		},
		// total_retirement_income_required
		totalRetirementIncomeRequired () {
			let num1, num2, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'fiannual_income_required_fveld') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'less_existing_regular_income') num2 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 - num2).toFixed(10)
			return sum
		},
		// total_amount_required rate, nper, pmt
		totalAmountRequired () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'adjusted_rate_return') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'duration_of_retirement') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'first_year_income') num3 = parseFloat(res.Amount || 0)
			})
			sum = utils.PV(num1, num2, num3)
			return sum
		},
	},
	watch: {
		fiannualIncomeRequiredFveld (val) {
			this.changeTableData(val, 'fiannual_income_required_fveld')
		},
		totalRetirementIncomeRequired (val) {
			this.changeTableData(val, 'total_retirement_income_required')
		},
		totalAmountRequired (val) {
			this.changeTableData(val, 'total_amount_required')
		}
	},
	methods: {
		// 更新rate
		updateAssumptionsForm (val) {
			// 更新子组件
			this.timer = new Date().getTime()
			if (val !== 'close') {
				this.AssumptionsForm = val
				this.assetsTableData.map(res => {
					if (res.value === 'adjusted_rate_return' && !isNaN(val.inflation_adjusted)) {
						res.Amount = val.inflation_adjusted
						res.AmountShow = parseFloat(val.inflation_adjusted).toFixed(2)
					}
				})
				this.$forceUpdate();
				this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.annual_growth_rate_retirement = this.AssumptionsForm.annual_growth_rate
				this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.ror_retirement = this.AssumptionsForm.ror
			} else {
				this.setAssumptionsForm()
			}
		},
		setAssumptionsForm () {
			let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
			// 1.先取needs_analysis中的值 2.如果没有则取financial_objectives中的值
			if (dataValue0.needs_analysis.annual_growth_rate_retirement) {
				this.AssumptionsForm.annual_growth_rate = dataValue0.needs_analysis.annual_growth_rate_retirement
			} else {
				this.AssumptionsForm.annual_growth_rate = dataValue0.financial_objectives.annual_growth_rate ? dataValue0.financial_objectives.annual_growth_rate : '0'
			}
			if (dataValue0.needs_analysis.ror_retirement) {
				this.AssumptionsForm.ror = dataValue0.needs_analysis.ror_retirement
			} else {
				this.AssumptionsForm.ror = dataValue0.financial_objectives.ror ? dataValue0.financial_objectives.ror : 0
			}
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.assetsTableData.map(res => { this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis[res.value] = res.Amount })
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.total = this.total
			this.$nextTick(() => {
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			})
		},
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		openAssumptions () {
			this.$refs.AssumptionsDialog.Dialog.visible = true
			this.$refs.AssumptionsDialog.AssumptionsFormDialog = this.AssumptionsForm
		},
		// 修改表格中的计算数据
		changeTableData (val, field) {
			this.assetsTableData.map(res => {
				if (res.value === field && !isNaN(val)) {
					res.Amount = val
					res.AmountShow = 'S$ ' + this.$formatMoney(parseFloat(val).toFixed(2))
				}
				// 若total_retirement_income_required的值更新，则first_year_income值也更新，且值相同
				if (field === 'total_retirement_income_required' && res.value === 'first_year_income') {
					res.Amount = val
					res.AmountShow = 'S$ ' + this.$formatMoney(parseFloat(val).toFixed(2))
				}
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		// 若有值 则赋值
		this.assetsTableData.map((key, i) => {
			key.Amount = dataValue0.needs_analysis[key.value]
			let show = this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			if (key.ifAmount === false) {
				key.AmountShow = show + ' %'
			} else if (key.type === 'cal') {
				key.AmountShow = show
			} else {
				key.AmountShow = 'S$ ' + show
			}
			key.index = i
		})
		this.setAssumptionsForm()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='scss' scoped>
</style>