import axios from 'axios'
import { Message, Loading } from 'element-ui';
import store from '@/store'
import router from 'router'
import _ from 'lodash';
import { GET_username } from '@/utils/localStorage';

// 中断请求配置
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// 定义数据
let config = null

// 检测当前环境
const baseUrl = process.env.VUE_APP_URL
// 项目配置信息
let header = {
	version: window.version,
	msg_id: randomString(),
	send_time: new Date()
}

function randomString(len) {
	len = len || 32;
	const $chars =
	  'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	const maxPos = $chars.length;
	let pwd = '';
	for (let i = 0; i < len; i++) {
	  pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd;
  }

//loading对象
let loading = null;

//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading(target, title = '') {
	// 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
	// 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
	if (needLoadingRequestCount === 0 && !loading) {
		loading = Loading.service({
			lock: true,
			text: title + 'Loading. Please wait.',
			background: target == undefined ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
			target: target || "body"
		});
	}
	needLoadingRequestCount++;
}

//隐藏loading
function hideLoading() {
	needLoadingRequestCount--;
	needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
	if (needLoadingRequestCount === 0) {
		//关闭loading
		toHideLoading();
	}
}

//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = _.debounce(() => {
	if (loading != null) {
		loading.close();
		loading = null;
	}
}, 300);

class HttpRequest {
	constructor(baseUrl) {
		this.baseURL = baseUrl
	}
	// 公共数据部分
	gerInsideConfig() {
		const config = {
			baseURL: this.baseURL
		}
		return config
	}
	// 拦截器
	interceptors(instance) {
		header.msg_id = randomString()
		header.send_time = new Date()
		// 添加请求拦截器
		instance.interceptors.request.use(function (config) {
			if (!GET_username() && config.url !== '/api/dms/advisorPortalInterfaceCheck/sendVerification' && config.url !== '/api/dms/advisorPortalInterfaceCheck/updatePassword') {
				Message.closeAll()
				Message.error('Your transaction is unsuccessful, please log in again and retry it.');
				router.push('/login')
				return Promise.reject()
			}
			// 添加pos对应参数设置
			config.data = {header:header,bodys:config.data}
			// 看是否需要加载，默认需要
			if (config.showLoading !== false) {
				if (config.url !== '/api/common/portal/common/kyc/edit') {
					showLoading(config.headers.loadingTarget, config.title);
				}
			}
			
			// 在发送请求之前做些什么
			switch (config.method) {
				case 'get':
					config.headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8', ...config.headers }
					break;
				case 'post':
					config.headers = { 'Content-Type': 'application/json;charset=utf-8', ...config.headers }
					break;
				case 'put':
					config.headers = { 'Content-Type': 'application/json;charset=utf-8', ...config.headers }
					break;
				case 'delete':
					config.headers = { 'Content-Type': 'application/json;charset=utf-8', ...config.headers }
					break;
				default:
					config.headers
			}
			
			// 设置请求认证
			if (store.state.User.Token) {
				if(config.type != 'notoken'){
					config.headers = {...config.headers, 'usertoken': store.state.User.Token, 'source':'web'}
				}
			}
			return config;
		}, function (error) {
			// 对请求错误做些什么
			//判断当前请求是否设置了不显示Loading
			if (config.headers.showLoading !== false) {
				hideLoading();
			}
			Message.error('Request timed out!');
			return Promise.reject(error);
		});

		// 添加响应拦截器
		instance.interceptors.response.use(function (response) {
			// 对响应数据做点什么
			if (response.config.headers.showLoading !== false) {
				hideLoading();
			}
			if (response.status == 200) {
				if (response.data.rtn_code === '403') {
					router.push('/login')
					Message.error('Please Login！');
					return Promise.reject(response.data.rtn_mesg);
				}
				// Blob无需判断
				if (response.data.rtn_code !== '101' && !(response.data instanceof Blob)) {
					Message.error(response.data.rtn_mesg);
					return Promise.reject(response.data.rtn_mesg);
				}
				return response.data;
			} else {
				Message.error('Service abnormality, please contact the deployment staff!');
			}
		}, function (error) {
			// 对响应错误做点什么
			hideLoading();
			// 无权限返回登录页
			if (error.response && error.response.status === 401) {
				router.push('/login')
				Message.error('Please Login！');
			}
			if (error.response && error.response.data && error.response.data.message) {
				var jsonObj = JSON.parse(error.response.data.message);
				Message.error(jsonObj.message);
			} else {
				Message.error('Network request failed, please contact the project operation and maintenance personnel!')
			}
			return Promise.reject(error);
		});
	}
	request(options) {
		options = { ...(this.gerInsideConfig()), ...options }
		const instance = axios.create({
			baseUrl: baseUrl,
			timeout: 60000,
			...options
		})
		this.interceptors(instance)
		return instance(options)
	}
}
export default new HttpRequest(baseUrl)