/**
    金额类输入框
    获得焦点 23.1234
    失去焦点 S$ 23.12
 */
<template>
	<el-input v-model="inputValueShow" @keyup.native="proving($event)" @focus="focus($event)" @blur="inputMoney($event)"></el-input>
</template>

<script>

export default {
    props: {
        // input值
        inputValue: {
			type: Number | String,
			default: ''
		},
		// 修改的字段名称
		fieldName: {
			type: String,
			default: ''
		},
        // 需要值修改的对象
        currentObj: {
            type: Object,
			default: {}
        }
    },
	components: {},
	data () {
		return {
            inputValueShow: '',
            currentObjShow: []
        };
	},
	computed: {},
	watch: {},
	methods: {
        //限制只能输入一个小数点
		proving (e) {
			var val = e.target.value
			if (val.indexOf(".") != -1) {
				var str = val.substr(val.indexOf(".") + 1)
				if (str.indexOf(".") != -1) {
					val = val.substr(0, val.indexOf(".") + str.indexOf(".") + 1)
				}
			}
			e.target.value = val.replace(/[^\d^\.]+/g, '')
		},
		// 获取焦点 展示赋值
		focus (el) {
			console.log(el.target.value);
			if (el.target.value) {
				let num = Number(el.target.value.slice(3).replace(/,/g, ''))
				this.inputValueShow = num === 0 ? '' : num
			}
		},
        // 失焦 格式化数值，四舍五入，保留两位小数
		inputMoney (el) {
			this.inputValueShow = 'S$ ' + this.$formatMoney(el.target.value)
			this.currentObjShow[this.fieldName] = el.target.value
            this.$emit('updateArr', this.currentObjShow)

		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
        // 不允许直接修改prop中的值，故重新定义并赋值
        this.inputValueShow = 'S$ '+ this.$formatMoney(this.inputValue)
        this.currentObjShow = this.currentObj
	},
}
</script>