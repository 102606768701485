<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Rider</Navbar>
		<div class="h90 displayFlex flexColumn boxSizing p30px OverflowYAuto">
			<!-- 上  rider -->
			<div class="mb50px">
				<p style="color: #3C3C3C;font-size: 26px;">{{rider.product_name}}</p>
				<div class="mt20px ml20">
					<!-- 图片 -->
					<!-- <img :src="rider.product_img" alt="" width="200px" height="200px"> -->
					<!-- company -->
					<div class="companyStyle ml100px">{{rider.company}}</div>
					<!-- 描述 -->
					<div class="mt20px" style="color: #5D5D5D;line-height: 24px;white-space: pre-wrap;">{{rider.product_description}}</div>
				</div>
			</div>
			<!-- 中  form -->
			<div class="">
				<el-form :model="rider" class="my-form">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Benefit Amount" prop="benefit_amount">
								<amount-input-form :currentObj="rider" :inputValue="rider.benefit_amount" fieldName="benefit_amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
                        <el-col :span="12">
                            <el-form-item label="Policy Term" prop="policy_term">
								<el-input v-model="rider.policy_term" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
                        <el-col :span="12">
							<el-form-item label="Premium Amount" prop="premium_amount">
								<amount-input-form :currentObj="rider" :inputValue="rider.premium_amount" fieldName="premium_amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Premium Mode" prop="premium_mode">
								<el-select v-model="rider.premium_mode" placeholder="">
									<el-option v-for="item in premiumMode" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<p style="border-bottom: 6px solid #C1C1C1;" class="mb50px"></p>
			</div>
			<div class="displayFlex flexJCCenter">
				<el-button type="danger" @click="deleteRider" style="background-color: #F74323;">Remove Rider</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import AmountInputForm from '../../components/amountInputForm.vue';

export default {
	components: { Navbar, AmountInputForm },
	data () {
		return {
			kycAllData: {},
			dataValue: {},
			rider: {},
			premiumMode: global.premiumMode
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.rider = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			// 1.form表单赋值
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 删除
		deleteRider () {
            this.rider_list.splice(this.$router.currentRoute.query.rider, 1)
            this.updataAllData()
            this.$router.go(-1)
        },
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.dataValue = this.kycAllData.kyc.client[this.kycClient]
		// 若有值 则赋值  proposalIndex第几个tab页  productIndex第几条数据 fundIndex第几条fund
		let proposalIndex = this.$router.currentRoute.query.proposal
		let productIndex = this.$router.currentRoute.query.product
        let fundIndex = this.$router.currentRoute.query.rider
        this.rider_list = this.dataValue.basis_recommendations.product_recommendations_option[proposalIndex].product_list[productIndex].rider_list
		this.rider = this.dataValue.basis_recommendations.product_recommendations_option[proposalIndex].product_list[productIndex].rider_list[fundIndex]
		console.log(this.rider, 'rider');
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
}
</script>
<style lang='css' scoped>
.companyStyle {
	border: 2px solid #2680eb;
	height: 30px;
	font-size: 14px;
	color: #2680eb;
	text-align: center;
	line-height: 30px;
	width: fit-content;
	width: -webkit-fit-content;
	width: -moz-fit-content;
}
</style>