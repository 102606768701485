export default {
    /**
     * 
     * @param {Amount} value 
     * 金额千分位格式化
     * @returns 
     */
    formatMoney (value) {
        let text = value
        try {
          if (typeof value == 'string') {
            text = parseFloat(value)
          }
          // Null if undefined
          if (typeof value == 'undefined') {
            text = '0';
          }
          text = text.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
          if (text == 'NaN') {
            text = '0.00'
          }
        } catch (e) {
          text = '0.00'
        }
        return text
      }
}