<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Other Protection: {{navigationName}}</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :show-header="false">
				<el-table-column prop="name" label="" />
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<el-input v-if="scope.row.type === 'num'" type="number" v-model="scope.row.Amount"></el-input>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr" />
					</template>
				</el-table-column>
			</el-table>
			<!-- total_show -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total(surplus/shortfall) S$ <span style="color: black;">{{$formatMoney(total_show)}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from '../../../../components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';

export default {
	components: { Navbar, AmountInput },
	data () {
		return {
			navigationName: '',
			assetsTableData: [
				{
					Amount: '0',
					name: 'You have indicated that you would like to plan for (A)',
					value: 'indicated_plan_for',
					type: 'num'
				},
				{
					Amount: '0',
					name: 'Lump sum required (PV) ($)',
					value: 'lump_sum_required',
				}, {
					Amount: '0',
					name: 'Less: Existing insurance ($)',
					value: 'less_existing_insurance',
				}
			],
			kycAllData: {}
		};
	},
	computed: {
		total () {
			let num1, num2, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'less_existing_insurance') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'lump_sum_required') num2 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 - num2).toFixed(10)
			return sum
		},
		total_show () {
			return parseFloat(this.total).toFixed(2)
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.protection_goals_others.map(n => {
				if (n.index === this.$router.currentRoute.query.id) {
					this.assetsTableData.map(res => { n[res.value] = res.Amount })
					n.total = this.total
				}
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 取kyc中的值
		let current_protection_goals_others = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.protection_goals_others.filter(n => n.index === this.$router.currentRoute.query.id)[0]
		this.assetsTableData.map((key, i) => {
			key.Amount = current_protection_goals_others[key.value] || 0
			key.AmountShow = 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.index = i
		})
		this.navigationName = current_protection_goals_others.other_name
	},
}
</script>
<style lang='scss' scoped>
</style>