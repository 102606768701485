<template>
	<!-- 公共组件：供2.1、search行点击展示  共同使用 -->
	<div class='displayFlex-1' style="overflow-y: scroll;">
		<!-- user -->
		<User :headerDate="headerDate"></User>
		<!-- Trusted Individual -->
		<div class="displayFlex flexAlignCenter pr40px" style="height: 54px;border-bottom: 3px solid #c1c1c1;">
			<span class="pl20px">Trusted Individual</span>
			<i class="el-icon-right pointer iStyle" @click="$router.push('/TrustedIndividuals')"></i>
		</div>
		<!-- content----------------------------------------------------------------------------- -->
		<div v-if="FinancialTableData.length > 0 || ProtectionTableData.length > 0" class="p40px">
			<MyTitle Title="Financial goals" class="h30px" />
			<el-table :data="FinancialTableData" style="width: 100%">
				<el-table-column prop="protection_name" label="Protection for myself">
					<template slot-scope="scope">
						<p>{{scope.row.protection_name}}</p>
						<div v-if="parseFloat(scope.row.value) < 0" class="tableProgress" :style="{'backgroundColor': scope.row.color, 'width': scope.row.progress + '%'}" />
					</template>
				</el-table-column>
				<el-table-column prop="value" label="Shortfall/surplus" width="200">
					<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
						S$ {{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop="years" label="Time horizon" width="200">
					<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver' && scope.row.years">
						{{scope.row.years}} year(s)
					</template>
				</el-table-column>
			</el-table>
			<el-table :data="ProtectionTableData" style="width: 100%;">
				<el-table-column prop="protection_name" label="Protection for dependants">
					<template slot-scope="scope">
						<p>{{scope.row.protection_name}}</p>
						<div v-if="parseFloat(scope.row.value) < 0" class="tableProgress" :style="{'backgroundColor': scope.row.color, 'width': scope.row.progress + '%'}" />
					</template>
				</el-table-column>
				<el-table-column prop="value" label="Shortfall/surplus" width="200">
					<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
						S${{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop="years" label="Time horizon" width="200">
					<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver' && scope.row.years">
						{{scope.row.years}} year(s)
					</template>
				</el-table-column>
			</el-table>
		</div>
		<p style="border: 2px solid #e8e8e8;"></p>

		<div class="p40px">
			<NavbarVue :backgroundColor="parseFloat(calcNet) < 0 ? '#FF4040' : 'green'" fontSize="24px" className="flexBox" color="#fff" height="80px">
				<div class="displayFlex flexColumn">
					<span>Net assets position</span>
					<span style="fontSize: 16px;">total assets - total liabilities</span>
				</div>
				<span style="fontSize: 24px;margin-left: auto;">S$ {{$formatMoney(calcNet)}}</span>
			</NavbarVue>
			<!-- Assets-------------------------------------------------- -->
			<MyTitle Title="Assets" class="mt20px mb20px" />
			<!-- 表格 -->
			<el-table :data="AssetsTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Personal Assets">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S${{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop='' label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total Assets <span :style="{'color': AssetsTotal < 0 ? '#FF4040' : 'green'}">S$ {{$formatMoney(AssetsTotal)}}</span></div>
			<!-- Liabilities----------------------------------------------------------------------------- -->
			<MyTitle Title="Liabilities" class="mt20px mb20px" />
			<!-- 表格 -->
			<el-table :data="LiabilitiesTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Personal Assets">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S${{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop='' label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total Liabilities <span :style="{'color': LiabilitiesTotal < 0 ? '#FF4040' : 'green'}">S$ {{$formatMoney(LiabilitiesTotal)}}</span></div>
		</div>
		<p style="border: 2px solid #e8e8e8;"></p>

		<div class="p40px">
			<!-- Cash----------------------------------------------------------------------------- -->
			<MyTitle Title="Cash" class="mt20px mb20px" />
			<!-- 表格 -->
			<el-table :data="CashTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Monthly Cash Inflow">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S${{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="ml60 mt20px mb20px" style="color: #606266;">Surplus <span :style="{'color': CashTotal < 0 ? '#FF4040' : 'green'}">S$ {{$formatMoney(CashTotal)}}</span></div>
			<!-- CPF----------------------------------------------------------------------------- -->
			<MyTitle Title="CPF" class="mt20px mb20px" />
			<!-- 表格 -->
			<el-table :data="CPFTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Monthly CPF Inflow">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S${{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="ml60 mt20px mb20px" style="color: #606266;">Surplus <span :style="{'color': CPFTotal < 0 ? '#FF4040' : 'green'}">S$ {{$formatMoney(CPFTotal)}}</span></div>
		</div>
		<p style="border: 2px solid #e8e8e8;"></p>

		<div class="p40px">
			<!-- Existing investment & Insurances----------------------------------------------------------------------------- -->
			<MyTitle Title="Existing investment & Insurances" class="mt20px mb20px" />
			<!-- 表格 -->
			<el-table :data="ExistingTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Investments">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						{{scope.row.value}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<p style="border: 2px solid #e8e8e8;"></p>

		<div class="p40px">
			<!-- Budget------------------>
			<MyTitle Title="Budget" class="mt20px mb20px" />
			<!-- 表格 -->
			<el-table :data="BudgetTableData" style="width: 100%">
				<el-table-column prop="title" label="Source of funds">
				</el-table-column>
				<el-table-column prop="Annual" label="Annual Amount" width="300">
					<template slot-scope="scope">
						<span>S$ {{$formatMoney(scope.row.Annual)}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="Single" label="Lump Sum" width="300">
					<template slot-scope="scope">
						<span>S$ {{$formatMoney(scope.row.Single)}}</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import MyTitle from "../../components/MyTitle.vue";
import NavbarVue from "@/components/Navbar.vue";
import User from "../components/User.vue";
import { analysisNeeds } from "@/utils/kyc"

export default {
	components: { MyTitle, NavbarVue, User },
	data () {
		return {
			// user传值
			headerDate: {},
			// table值
			needs: [],
			FinancialTableData: [],
			ProtectionTableData: [],
			// 最大值
			maxValue: null,
			assets_liabilities: {},
			cashflow: {},
			existing_investments_insurance: {},
			budget: {},
			kycAllData: {}
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		},
		calcNet () {
			return parseFloat(this.assets_liabilities.net_assets_position || 0).toFixed(2)
		},
		AssetsTableData () {
			return [{
				title: 'Personal Assets',
				value: parseFloat(this.assets_liabilities.total_personal_assets || 0).toFixed(2),
				path: '/PersonalAssets?View'
			}, {
				title: 'Invested/CPF Assets',
				value: parseFloat(this.assets_liabilities.total_invested_assets || 0).toFixed(2),
				path: '/InvestedAssets?View'
			}]
		},
		AssetsTotal () {
			return parseFloat(this.assets_liabilities.total_assets || 0).toFixed(2)
		},
		LiabilitiesTableData () {
			return [{
				title: 'Short Term',
				value: parseFloat(this.assets_liabilities.total_short_term_liabilities || 0).toFixed(2),
				path: '/ShortTerm?View'
			}, {
				title: 'Long Term',
				value: parseFloat(this.assets_liabilities.total_long_term_liabilities || 0).toFixed(2),
				path: '/LongTerm?View'
			}]
		},
		LiabilitiesTotal () {
			return parseFloat(this.assets_liabilities.total_liabilities || 0).toFixed(2)
		},

		CashTableData () {
			return [{
				title: 'Monthly Cash Inflow',
				value: parseFloat(this.cashflow.total_monthly_income || 0).toFixed(2),
				path: '/MonthlyCashInflow?View'
			}, {
				title: 'Monthly Cash Outflow',
				value: parseFloat(this.cashflow.monthly_cash_outflow_total || 0).toFixed(2),
				path: '/MonthlyCashOutflow?View'
			}]
		},
		CashTotal () {
			return parseFloat(this.cashflow.monthly_cash_shortfall_surplus || 0).toFixed(2)
		},
		CPFTableData () {
			return [{
				title: 'Monthly CPF Inflow',
				value: parseFloat(this.cashflow.cpf_total || 0).toFixed(2),
				path: '/MonthlyCPFInflow?View',
			}, {
				title: 'Monthly CPF Ouflow',
				value: parseFloat(this.cashflow.cpf_outflow_total || 0).toFixed(2),
				path: '/MonthlyCPFOutflow?View',
			}]
		},
		CPFTotal () {
			return parseFloat(this.cashflow.cpf_shortfall_surplus || 0).toFixed(2)
		},

		ExistingTableData () {
			return [{
				title: 'Investments',
				value: this.existing_investments_insurance.existing_investments.length || 0,
				path: '/ExistingInvestmentsList?View'
			}, {
				title: 'Life/Health Polices',
				value: this.existing_investments_insurance.existing_policies.length || 0,
				path: '/ExistingPoliciesList?View'
			}]
		},

		BudgetTableData () {
			return [
				{
					title: 'Cash',
					Annual: parseFloat(this.budget.cash_annual || 0).toFixed(2),
					Single: parseFloat(this.budget.cash_single || 0).toFixed(2),
				}, {
					title: 'CPF Ordinary Account',
					Annual: parseFloat(this.budget.cpf_ordinary_account_annual || 0).toFixed(2),
					Single: parseFloat(this.budget.cpf_ordinary_account_single || 0).toFixed(2)
				}, {
					title: 'CPF Special Account',
					Annual: parseFloat(this.budget.cpf_special_account_annual || 0).toFixed(2),
					Single: parseFloat(this.budget.cpf_special_account_single || 0).toFixed(2)
				}, {
					title: 'CPF Medisave Account',
					Annual: parseFloat(this.budget.cpf_medisave_account_annual || 0).toFixed(2),
					Single: parseFloat(this.budget.cpf_medisave_account_single || 0).toFixed(2)
				}, {
					title: 'Supplementary Retirement Scheme (SRS)',
					Annual: parseFloat(this.budget.retirement_account_annual || 0).toFixed(2),
					Single: parseFloat(this.budget.retirement_account_single || 0).toFixed(2)
				}, {
					title: 'Total',
					Annual: parseFloat(this.budget.total_annual || 0).toFixed(2),
					Single: parseFloat(this.budget.total_single || 0).toFixed(2)
				},
			]
		}
	},
	watch: {
		kycClient () {
			this.getData()
			setTimeout(() => {
				this.setTableData()
			}, 500);
		}
	},
	methods: {
		getData () {
			this.kycAllData = this.$store.state.KYC.kycAllData
			let dataValue = this.kycAllData.kyc.client[this.kycClient]
			// user值
			this.headerDate = {
				gender: dataValue.personal_information.gender,
				title: dataValue.personal_information.title,
				name: dataValue.personal_information.full_name,
				cka: dataValue.customer_knowledge_assessment.assessment_outcome,
				ai: dataValue.accredited_investor_declaration.is_ai,
				grade: dataValue.risk_profile_questionnaire.is_agree_profile === 'N' ? dataValue.risk_profile_questionnaire.own_choice : dataValue.risk_profile_questionnaire.grade
			}
			this.needs = analysisNeeds(dataValue)
			this.assets_liabilities = dataValue.assets_liabilities
			this.cashflow = dataValue.cashflow
			this.existing_investments_insurance = dataValue.existing_investments_insurance
			this.budget = dataValue.budget
		},
		setTableData () {
			this.FinancialTableData = []
			this.ProtectionTableData = []
			let numArr = []
			this.needs.map(need => { numArr.push(Math.abs(parseFloat(need.value || 0))) })
			this.maxValue = Math.max(...numArr)
			// 给各个table赋值
			this.needs.map(need => {
				need.value = parseFloat(need.value || 0).toFixed(2)
				if (parseFloat(need.value) < 0) {
					need.progress = parseFloat(Math.abs(need.value) / this.maxValue * 100).toFixed(2)
				}
				if (need.is_dependant == 'N' && !(need.protection_name.indexOf('Planning for dependant') > -1)) {
					this.FinancialTableData.push(need)
				}
				if (need.is_dependant && need.is_dependant === 'Y') {
					this.ProtectionTableData.push(need)
				}
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.getData()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.setTableData()
	},
}
</script>
<style lang='css' scoped>
i {
	line-height: 26px;
	font-size: 22px;
	color: #707070;
	margin-left: auto;
}
.tableProgress {
	width: 500px;
	height: 24px;
	border-radius: 2px;
	margin-top: 10px;
}
</style>