<template>
	<div class=''>
		<Navbar :showBack="true" backgroundColor="#FF9941" color="#fff">Trusted Individual</Navbar>
		<p style="margin: 30px 0 10px 30px;">It is recommended for you to be accompanied by a Trusted Individual if you belong to any two of the following profiles.</p>
		<CheckBoxVue checkBoxType="list" :checkedData="checkedData" :content="checkBoxContent" :disabled="true" class="p30px">
		</CheckBoxVue>
		<!-- 展示更多 -->
		<div v-if="isSelectClient()" class="p30px">
			<p class="mb20px">Would you like to be accompanied by a Trusted Individual?</p>
			<span v-if="formData.trusted_individual.is_accompanied === 'Y'">Yes</span>
			<span v-if="formData.trusted_individual.is_accompanied === 'N'">No</span>
			<!-- yes时展示 -->
			<div v-if="formData.trusted_individual.is_accompanied === 'Y'">
				<MyTitle Title="Trusted Individual Details" class="mb20px" />
				<el-form label-position="left" label-width="400px" :model="formData">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Name">
								<span>{{formData.trusted_individual.trusted_individual}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="NRIC Number">
								<span>{{formData.trusted_individual.trusted_individual_nricnumber}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Relationship to client">
								<span>{{formData.trusted_individual.trusted_individual_relationship}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Contact No">
								<span>{{formData.trusted_individual.trusted_individual_area + ' ' + formData.trusted_individual.trusted_individual_content_number}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from '../../../../../components/Navbar.vue';
import MyTitle from '../../../components/MyTitle.vue';
import CheckBoxVue from '@/views/KYC/components/checkBox.vue';

export default {
	components: { Navbar, MyTitle, CheckBoxVue },
	data () {
		return {
			formData: {},
			kycAllData: {},
			checkedData: [],
			checkBoxContent: ['62 years of age or older', 'Below GCE ’N’ or ‘O’ level certification or equivalent academicqualification',
				'Not proficient in spoken or written English'],
			isOld: '',
			isGceNo: '',
			isNotProficientEnglish: ''
		};
	},
	computed: {},
	watch: {},
	methods: {
		isSelectClient () {
			const isSelectNo =
				(this.isOld === 'Y' ? 1 : 0) +
				(this.isGceNo === 'Y' ? 1 : 0) +
				(this.isNotProficientEnglish === 'Y' ? 1 : 0)
			return isSelectNo > 1
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.formData = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		this.isOld = this.formData.trusted_individual.is_old
		this.isGceNo = this.formData.trusted_individual.is_gce_no
		this.isNotProficientEnglish = this.formData.trusted_individual.is_not_proficient_english
		if (this.isOld === 'Y') this.checkedData.push('62 years of age or older')
		if (this.isGceNo === 'Y') this.checkedData.push('Below GCE ’N’ or ‘O’ level certification or equivalent academicqualification')
		if (this.isNotProficientEnglish === 'Y') this.checkedData.push('Not proficient in spoken or written English')

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
p {
	color: #6c6c6c;
}
</style>