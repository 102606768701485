import axios from 'utils/request.js'
export const productCommonInfoQuery = (data) => {
    return axios.request({
        url: "/api/product/portal/api/productapi/productCommonInfoQuery",
        method: "post",
        title: "",
        data
    })
}
export const productListQuery = (data) => {
    return axios.request({
        url: "/api/product/portal/api/productapi/productListQuery",
        method: "post",
        title: "",
        data
    })
}
export const productDetailQuery = (data) => {
    return axios.request({
        url: "/api/product/portal/api/productapi/productDetailQuery",
        method: "post",
        title: "",
        data
    })
}