import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

// 数据持久化
const dataPersistence = new VuexPersistence({
  storage: window.localStorage
})
import KYC from './module/KYC'
import User from './module/User'
import Submission from './module/Submission'
export default new Vuex.Store({
  plugins: [dataPersistence.plugin],
  modules: {
    KYC,
    User,
    Submission
  }
})
