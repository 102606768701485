import IncomeProtectionUponDeath from './IncomeProtectionUponDeath';
import TotalPermanentDisability from './TotalPermanentDisability'
import CriticalIllness from './CriticalIllness'
import MedicalAndHospitalisationCosts from './MedicalAndHospitalisationCosts';
import DentalExpensesProtection from './DentalExpensesProtection';
import OutpatientMedicalExpensesProtection from './OutpatientMedicalExpensesProtection';
import PersonalAccident from './PersonalAccident';
import LongTermCareProtection from './LongTermCareProtection';
import PlanningForDependantProtection from './PlanningForDependantProtection';
import ProtectionGoalsOthers from './ProtectionGoalsOthers';
import EducationFund from './EducationFund';
import EducationList from './EducationList';
import RetirementFund from './RetirementFund';
import SavingAccumulationGoalsOthers from './SavingAccumulationGoalsOthers'
export default {
  IncomeProtectionUponDeath,
  TotalPermanentDisability,
  CriticalIllness,
  MedicalAndHospitalisationCosts,
  DentalExpensesProtection,
  OutpatientMedicalExpensesProtection,
  PersonalAccident,
  LongTermCareProtection,
  PlanningForDependantProtection,
  ProtectionGoalsOthers,

  EducationFund,
  EducationList,
  RetirementFund,
  SavingAccumulationGoalsOthers,

  // 利率、支付总期数、定期支付额 1.96 10 2
  PV (rate, nper, pmt) {
    try {
      let value = 0;
      if (rate == 0) {
        value = pmt * nper
      } else {
        value = (pmt / (rate / 100)) * (1 - Math.pow(1 + rate / 100, -nper) )
      }
      if (isNaN(value)) {
        return '0';
      }
      // Return number retains two decimal places
      return value.toFixed(10);
    } catch (e) {
      return '0';
    }
  }
};
