<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Income protection upon total permanent disability</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :row-class-name="$tableRowClassName">
				<el-table-column prop="name" label="Total permanent disability">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<!-- disabled:禁止编辑  num:纯数字  cal:计算利率-->
						<el-input v-if="scope.row.type === 'disabled'" v-model="scope.row.AmountShow" disabled></el-input>
						<el-input v-else-if="scope.row.type === 'num'" type="number" v-model="scope.row.Amount"></el-input>
						<div v-else-if="scope.row.type === 'cal'" class="displayFlex flexAlignCenter">
							<span>{{scope.row.AmountShow}}</span>
							<img src="@/assets/img/calculation.png" @click="openAssumptions" alt="" class="ml20px">
						</div>
						<span v-else-if="scope.row.type === 'none'"></span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr">
						</AmountInput>
					</template>
				</el-table-column>
			</el-table>
			<!-- total -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total(surplus/shortfall) S$ <span style="color: black;">{{$formatMoney(totalDisability)}}</span></div>
		</div>
		<AssumptionsDialog ref="AssumptionsDialog" :assumptions="AssumptionsForm" :updateAssumptionsForm="updateAssumptionsForm" :key="timer"></AssumptionsDialog>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';
import AssumptionsDialog from './AssumptionsDialog.vue';
import utils from './index'

export default {
	components: { Navbar, AmountInput, AssumptionsDialog },
	data () {
		return {
			AssumptionsForm: {
				annual_growth_rate: '',
				ror: '',
				inflation_adjusted: ''
			},
			assetsTableData: [
				// {
				// 	Amount: '0',
				// 	name: 'Amount required by your dependants',
				// 	value: 'dependants_required_disability',
				// 	type: 'none'
				// },
				{
					Amount: '0',
					name: 'Desired annual income to sustain lifestyle (today’s value -PMT)',
					value: 'sustain_ifestyle_required_disability',
				}, {
					Amount: '0',
					name: 'Number of years required',
					value: 'required_years_disability',
					type: 'num'
				}, {
					Amount: '0',
					name: 'Inflation Adjusted Rate Of Return',
					value: 'inflation_adjusted_return_rate_disability',
					type: 'cal'
				}, {
					Amount: '0',
					name: 'Lump sum required to maintain lifestyle (PV)',
					value: 'maintain_lifestyle_sum_disability',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Add: Lump sum required upon occurrence',
					value: 'add_lump_sum_required',
				}, {
					Amount: '0',
					name: 'Total disability needs',
					value: 'total_need_disability',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Less: Existing disability benefit',
					value: 'less_existing_disability_benefit',
				}, {
					Amount: '0',
					name: 'Other funds available (e.g rental income etc.)',
					value: 'other_funds_available_disability',
				}
			],
			kycAllData: {},
			timer: ''
		};
	},
	computed: {
		total_disability () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'less_existing_disability_benefit') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'other_funds_available_disability') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'total_need_disability') num3 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 + num2 - num3).toFixed(10)
			return sum

		},
		totalDisability () {
			return parseFloat(this.total_disability).toFixed(2)
		},
		total_need_disability () {
			let num1, num2, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'maintain_lifestyle_sum_disability') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'add_lump_sum_required') num2 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 + num2).toFixed(10)
			return sum
		},
		maintain_lifestyle_sum_disability () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'inflation_adjusted_return_rate_disability') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'required_years_disability') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'sustain_ifestyle_required_disability') num3 = parseFloat(res.Amount || 0)
			})
			sum = utils.PV(num1, num2, num3)
			return sum
		},
	},
	watch: {
		total_need_disability (val) {
			this.changeTableData(val, 'total_need_disability')
		},
		maintain_lifestyle_sum_disability (val) {
			this.changeTableData(val, 'maintain_lifestyle_sum_disability')
		}
	},
	methods: {
		openAssumptions () {
			this.$refs.AssumptionsDialog.Dialog.visible = true
			this.$refs.AssumptionsDialog.AssumptionsFormDialog = this.AssumptionsForm
		},
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.assetsTableData.map(res => { this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis[res.value] = res.Amount })
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.total_disability = this.total_disability
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 修改表格中的计算数据
		changeTableData (val, field) {
			this.assetsTableData.map(res => {
				if (res.value === field && !isNaN(val)) {
					res.Amount = val
					res.AmountShow = 'S$ ' + this.$formatMoney(parseFloat(val).toFixed(2))
				}
			})
		},
		// 更新rate
		updateAssumptionsForm (val) {
			// 更新子组件
			this.timer = new Date().getTime()
			if (val !== 'close') {
				this.AssumptionsForm = val
				this.assetsTableData.map(res => {
					if (res.value === 'inflation_adjusted_return_rate_disability' && !isNaN(val.inflation_adjusted)) {
						res.Amount = val.inflation_adjusted
						res.AmountShow = parseFloat(val.inflation_adjusted).toFixed(2)
					}
				})
				this.$forceUpdate();
				this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.annual_growth_rate_disability = this.AssumptionsForm.annual_growth_rate
				this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.ror_disability = this.AssumptionsForm.ror
			} else {
				this.setAssumptionsForm()
			}
		},
		setAssumptionsForm () {
			let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
			// 1.先取needs_analysis中的值 2.如果没有则取financial_objectives中的值
			if (dataValue0.needs_analysis.annual_growth_rate_disability) {
				this.AssumptionsForm.annual_growth_rate = dataValue0.needs_analysis.annual_growth_rate_disability
			} else {
				this.AssumptionsForm.annual_growth_rate = dataValue0.financial_objectives.annual_growth_rate ? dataValue0.financial_objectives.annual_growth_rate : '0'
			}
			if (dataValue0.needs_analysis.ror_disability) {
				this.AssumptionsForm.ror = dataValue0.needs_analysis.ror_disability
			} else {
				this.AssumptionsForm.ror = dataValue0.financial_objectives.ror ? dataValue0.financial_objectives.ror : 0
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		// 若有值 则赋值
		this.assetsTableData.map((key, i) => {
			key.Amount = dataValue0.needs_analysis[key.value]
			key.AmountShow = key.type === 'cal' ? (Math.round(key.Amount * 100) / 100).toFixed(2) : 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.index = i
		})
		this.setAssumptionsForm()
	},
}
</script>
<style lang='css' scoped>
</style>