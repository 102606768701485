import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// 常用样式
import './common/style/custom.css'
import './common/style/MyForm.scss'
console.log(`联调环境:${process.env.VUE_APP_TITLE},联调地址:${process.env.VUE_APP_URL}`);
// 静态数据、公共方法
import './common/js/code'
import method from './common/js/index'
Object.keys(method).forEach(key => { Vue.prototype['$' + key] = method[key] })
import { kycUpdate } from '@/api/KYC'
Vue.prototype.kycUpdate = kycUpdate

// 引入RSA 加密，并配置全局变量
import JSEncrypt from 'jsencrypt'
Vue.prototype.$jsEncrypt = JSEncrypt
Vue.config.productionTip = false
// 自定义指令
import '@/directive'
// 过滤器
import '@/filter'
// element 样式
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// input正则全局方法
import util from '@/utils/reg'
Vue.prototype.$util = util
import enLocale from 'element-ui/lib/locale/lang/en'
// Vue.use(Element, locale, { size: 'small', zIndex: 3000 });

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  en: {
    message: 'hello',
    ...enLocale // 或者用 Object.assign({ message: 'hello' }, enLocale)
  }
}
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

// 清除浏览器缓存
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
    localStorage.clear()
    window.localStorage.setItem("appVersion", VUE_APP_VERSION);
    location.reload()
}


new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
