<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Fund</Navbar>
		<div class="h90 displayFlex flexColumn boxSizing p30px OverflowYAuto">
			<!-- 上  fund -->
			<div class="mb50px">
				<p style="color: #3C3C3C;font-size: 26px;">{{fund.fund_name}}</p>
				<div class="mt20px ml20">
					<!-- 图片 -->
					<!-- <img :src="fund.product_img" alt="" width="200px" height="200px"> -->
					<!-- company -->
					<div class="companyStyle ml100px">{{fund.company}}</div>
					<!-- 描述 -->
					<div class="mt20px" style="color: #5D5D5D;line-height: 24px;white-space: pre-wrap;">{{fund.product_description}}</div>
				</div>
			</div>
			<!-- 中  form -->
			<div class="">
				<!-- CIS -->
				<el-form :model="fund" v-if="product.product_line_code === 'CI'" class="my-form addWidth">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Lump Sum Amount" prop="amount">
								<amount-input-form :currentObj="fund" :inputValue="fund.amount" fieldName="amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Allocation of Premium (%)" prop="percentage_allocation">
								<el-input v-model="fund.percentage_allocation" disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Regular Investment Scheme (RIS)" prop="type_account">
								<amount-input-form :currentObj="fund" :inputValue="fund.type_account" fieldName="type_account" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Allocation of Premium (%)" prop="ris_allocation">
								<el-input v-model="fund.ris_allocation" disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Remarks" prop="remarks">
								<el-input v-model="fund.remarks" type="textarea" style="margin-top: 35px;" placeholder="Remarks" @input="fund.remarks = $util.inputReg(fund.remarks)"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- ILP -->
				<el-form :model="fund" v-else-if="product.product_type_name === 'ILP'" class="my-form addWidth">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Lump Sum Amount/ RSP Amount" prop="amount">
								<amount-input-form :currentObj="fund" :inputValue="fund.amount" fieldName="amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Percentage Allocation for RSP (%)" prop="percentage_allocation">
								<el-input v-model="fund.percentage_allocation" disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Type Account" prop="type_account">
								<el-input v-model="fund.type_account"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Remarks" prop="remarks">
								<el-input v-model="fund.remarks" type="textarea" style="margin-top: 35px;" placeholder="Remarks" @input="fund.remarks = $util.inputReg(fund.remarks)"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
                <!-- 除了CI\ILP之外的 -->
				<el-form :model="fund" v-else class="my-form addWidth">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Lump Sum Amount" prop="amount">
								<amount-input-form :currentObj="fund" :inputValue="fund.amount" fieldName="amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
                        <el-col :span="12">
							<el-form-item label="Type Account" prop="type_account">
								<el-input v-model="fund.type_account"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Remarks" prop="remarks">
								<el-input v-model="fund.remarks" type="textarea" style="margin-top: 35px;" placeholder="Remarks" @input="fund.remarks = $util.inputReg(fund.remarks)"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<p style="border-bottom: 6px solid #C1C1C1;" class="mb50px"></p>
			</div>
			<div class="displayFlex flexJCCenter">
				<el-button type="danger" @click="deleteFund" style="background-color: #F74323;">Remove Fund</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import AmountInputForm from '../../components/amountInputForm.vue';

export default {
	components: { Navbar, AmountInputForm },
	data () {
		return {
			kycAllData: {},
			dataValue: {},
            product: {},
			fund: {},
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.fund = arr
			this.setPercentage(arr)
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			// 1.form表单赋值
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 删除
		deleteFund () {
			this.product.fund_list.splice(this.$router.currentRoute.query.fund, 1)
            this.updataAllData()
            this.$router.go(-1)
		},
		setPercentage (arr) {
			if (this.product.product_line_code === 'CI') {
				this.fund.percentage_allocation = parseFloat(arr.amount) / parseFloat(this.product.benefit_amount) * 100
				this.fund.ris_allocation = parseFloat(arr.type_account) / parseFloat(this.product.premium_amount) * 100
			} else {
				this.fund.percentage_allocation = parseFloat(arr.amount) / parseFloat(this.product.premium_amount) * 100
				console.log(this.fund.percentage_allocation);
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.dataValue = this.kycAllData.kyc.client[this.kycClient]
		// 若有值 则赋值  proposalIndex第几个tab页  productIndex第几条数据 fundIndex第几条fund
		let proposalIndex = this.$router.currentRoute.query.proposal
		let productIndex = this.$router.currentRoute.query.product
        let fundIndex = this.$router.currentRoute.query.fund
		localStorage.setItem('systemPrompts', JSON.stringify(this.$router.currentRoute.query))
        this.product = this.dataValue.basis_recommendations.product_recommendations_option[proposalIndex].product_list[productIndex]
		this.fund = this.dataValue.basis_recommendations.product_recommendations_option[proposalIndex].product_list[productIndex].fund_list[fundIndex]
		this.setPercentage(this.fund)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
}
</script>
<style lang='css' scoped>
.companyStyle {
	border: 2px solid #2680eb;
	height: 30px;
	font-size: 14px;
	color: #2680eb;
	text-align: center;
	line-height: 30px;
	width: fit-content;
	width: -webkit-fit-content;
	width: -moz-fit-content;
}
.addWidth >>> .el-input__inner {
	padding-left: 260px;
}
.addWidth >>> .el-input--prefix .el-input__inner {
	padding-left: 260px;
}
</style>