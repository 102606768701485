<template>
	<div :class="fullscreen ? '' : 'dialogBox'">
		<el-dialog class="dialog" :title="Title" :close-on-click-modal="false" destroy-on-close ref="dialog" :visible.sync="Vis" :width="Width + '%'" @close="close" :fullscreen="fullscreen" :show-close="showClose">
			<slot name="content"></slot>
			<span slot="footer">
				<slot name="BtnSolt"></slot>
			</span>
		</el-dialog>
	</div>
</template>

<script>

export default {
	props: {
		// 是否启用
		Visible: {
			type: Boolean,
			default: false
		},
		// 标题
		Title: {
			type: String,
			default: ''
		},
		// 宽度
		Width: {
			type: String,
			default: '60'
		},
		// 使用Btn插槽
		BtnSolt: {
			type: Boolean,
			default: false
		},
		// 是否全屏
		fullscreen: {
			type: Boolean,
			default: false
		},
		showClose: {
			type: Boolean,
			default: true
		},
	},
	computed: {
		Vis: {
			get () {
				return this.Visible
			},
			set () { }
		}
	},
	methods: {
		close () {
			if (this.showClose) {
				this.$parent.Dialog.visible = false
			}
		}
	},
}
</script>
<style lang='scss' scoped>
.dialogBox /deep/.el-dialog {
	margin-top: 7vh !important;
}
</style>