const name = {
    state: {
        // kyc流程页面标识
        kycPageItem: null,
        // kyc流程底部按钮是否展示
        bottomBntShow: true,
        // 若有两个Client，用于区分当前展示的是哪个
        kycClient: 0,
        // 客户知识评估状态
        CKA: -1, //-1 未评估  0 状态1   1：状态2    3：状态3
        // 风险评估问卷
        Grade: false,
        // kyc参数
        kycAllData: {},
        // 1.5 自定义内容存储 单独存储原因：同时适应修改、新增、删除等，若在原有基础上，逻辑较为复杂
        Assets_liabilities: {
            person: [],
            property: [],
            invested: [],
            short: [],
            mortgage: [],
            long: []
        },
        // 2.2needs存储
        protectionNeeds: [],
        // 记录当前proposal，用于preview展示
        activeName: ''
    },

    getters: {
    },

    mutations: {
        SET_KYCPAGEITEM (state, data) {
            state.kycPageItem = data
        },
        SET_CKA (state, data) {
            state.CKA = data
        },
        SET_GRADE (state, data) {
            state.Grade = data
        },
        SET_KYC_ALL_DATA (state, data) {
            state.kycAllData = data
        },
        // data格式：{person: []}
        SET_ASSETS_LIABILITIES (state, data) {
            if (Array.isArray(data)) {
                state.Assets_liabilities.person = data.filter(n => n.dynamic_field_type === 'person')
                state.Assets_liabilities.property = data.filter(n => n.dynamic_field_type === 'property')
                state.Assets_liabilities.invested = data.filter(n => n.dynamic_field_type === 'invested')
                state.Assets_liabilities.short = data.filter(n => n.dynamic_field_type === 'short')
                state.Assets_liabilities.mortgage = data.filter(n => n.dynamic_field_type === 'mortgage')
                state.Assets_liabilities.long = data.filter(n => n.dynamic_field_type === 'long')
            } else {
                let key = Object.keys(data)[0]
                // 删除field_value_show和index
                data[key].map(n => {
                    if (n.field_value_show) {
                        delete n.field_value_show
                    }
                    if (n.index) {
                        delete n.index
                    }
                })
                state.Assets_liabilities[key] = data[key]
            }
        },
        SET_BOTTOMBTN (state, data) {
            state.bottomBntShow = data
        },
        SET_PROTECTION_NEEDS (state, data) {
            state.protectionNeeds[state.kycClient] = data
        },
        SET_KYCCLIENT (state, data) {
            state.kycClient = data
        },
        SET_ACTIVENAME (state, data) {
            state.activeName = data
        },
    },
    actions: {
    },
}
export default name