<template>
	<div class="displayFlex flexColumn h100">
		<!-- 顶部 -->
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Basis of Recommendation</Navbar>
		<div class="p40px displayFlex-1 OverflowAuto">
			<!-- 标题 -->
			<MyTitle :Title="'Basis of recommendation of Proposal ' + ProposalIndex + ' for ' + name" :titleIndex="3" style="color: #707070;" />
			<!-- i) Reason(s) for recommendation --------------------------------------------------------------------->
			<MyTitle Title="i) Reason(s) for Recommendation" class="mt30px" />
			<!-- 段落 -->
			<p>{{paragraph1}}</p>
			<p>{{paragraph2}}</p>
			<p>{{paragraph3}}</p>
			<p>{{paragraph4}}</p>
			<!-- 动态内容 -->
			<div v-for="(item, i) in product_recommendations_option.product_list" :key="i">
				<p class="productItemText mb10px">{{getName(item)}}</p>
				<el-input v-model="item.recommendation_reason" type="textarea" :autosize="{minRows:1}" :disabled="onlyView" placeholder="State reasons for recommendation"  @input="item.recommendation_reason = $util.inputReg(item.recommendation_reason)"></el-input>
			</div>
			<!-- ii) Product Description ------------------------------------------------------------------------------>
			<MyTitle Title="ii) Product Description" class="mt30px" />
			<!-- 动态内容 -->
			<div v-for="(item, index) in product_recommendations_option.product_list" :key="index.product_name">
				<p class="productItemText mb10px">{{getName(item)}}</p>
				<p>{{item.product_description}}</p>
				<template v-if="item.rider_list.length > 0">
					<div v-for="(rider, ir) in item.rider_list" :key="index + ir">
						<p>{{rider.product_description}}</p>
					</div>
				</template>
				<template v-if="item.fund_list.length > 0">
					<div v-for="(fund, ifund) in item.fund_list" :key="index + ifund">
						<p>{{fund.product_description}}</p>
					</div>
				</template>
			</div>
			<!-- iii) Investment Product Risk Rating ------------------------------------------------------------------>
			<MyTitle Title="iii) Investment Product Risk Rating" class="mt30px" />
			<!-- 动态内容 -->
			<div v-for="(item, index) in product_recommendations_option.product_list" :key="index.product_name" class="w70">
				<div class="displayFlex flexJCspaceBetween">
					<p class="productItemText mb10px">{{item.product_name}}</p>
					<p style="color: #FF8800">{{item.product_risk_rating ? item.product_risk_rating : 'NA'}}</p>
				</div>
				<template v-if="item.rider_list.length > 0">
					<div v-for="(rider, ir) in item.rider_list" :key="item.product_name + ir" class="displayFlex flexJCspaceBetween">
						<p class="productItemText ml30px">{{rider.product_name}}</p>
						<p style="color: #FF8800">{{rider.product_risk_rating ? rider.product_risk_rating : 'NA'}}</p>
					</div>
				</template>
				<template v-if="item.fund_list.length > 0">
					<div v-for="(fund, ifund) in item.fund_list" :key="item.product_name + ifund" class="displayFlex flexJCspaceBetween">
						<p class="productItemText ml30px">{{fund.fund_name}}</p>
						<p style="color: #FF8800">{{fund.product_risk_rating ? fund.product_risk_rating : 'NA'}}</p>
					</div>
				</template>
			</div>
			<p>{{paragraph5}}</p>
			<!-- iv) Risk and limitation of plans --------------------------------------------------------------------->
			<MyTitle Title="iv) Risk and Limitation Of Plans" class="mt30px" />
			<!-- 动态内容 -->
			<div v-for="(item, index) in product_recommendations_option.product_list" :key="index.product_name">
				<p class="productItemText mb10px">{{getName(item)}}</p>
				<p v-for="(limitations) in item.possible_limitations" :key="limitations">{{limitations}}</p>
				<template v-if="item.rider_list.length > 0">
					<div v-for="(rider, ir) in item.rider_list" :key="item.product_name + ir">
						<p v-for="riderLimitations in rider.possible_limitations" :key="riderLimitations">{{riderLimitations}}</p>
					</div>
				</template>
				<template v-if="item.fund_list.length > 0">
					<div v-for="(fund, ifund) in item.fund_list" :key="item.product_name + ifund">
						<p v-for="fundLimitations in fund.possible_limitations" :key="fundLimitations">{{fundLimitations}}</p>
					</div>
				</template>
			</div>
			<!-- v) Fees and Charges ---------------------------------------------------------------------------------->
			<MyTitle Title="v) Fees and Charges" class="mt30px" />
			<!-- 段落 -->
			<div class="productItemText">Fees and charges to be borne by customers:</div>
			<p>{{paragraph6}}</p>
			<p>{{paragraph7}}</p>
			<p style="margin-left: 10px;"> a) Application form</p>
			<p style="margin-left: 10px;"> b) Product Highlights sheet</p>
			<p style="margin-left: 10px;"> c) Prospectus</p>
			<div class="productItemText">Remuneration of the Financial Adviser:</div>
			<div class="productItemText">For CIS, Infinitum Financial Advisory Pte Ltd and its representatives will be remunerated:</div>
			<p>a) Up to 5.0% of the upfront sales charges.</p>
			<p>{{paragraph8}}</p>
			<p>c) Up to 2.0% of the asset under advisory as the annual wrap fee (if applicable).</p>
			<!-- vi) Deviation(s) --------------------------------------------------------------------->
			<MyTitle Title="vi) Deviation(s)" class="mt30px" />
			<p class="mb20px">{{paragraph9}}</p>
			<SwitchBox width="200px" :status.sync="is_deviation" class="mb20px" :isDisabled='onlyView'></SwitchBox>
			<el-input v-if="is_deviation === 'Y'" v-model="deviation_reason" :disabled="onlyView" type="textarea" :autosize="{ minRows: 5, maxRows: 10}" placeholder="State if there is deviation in recommendation" size="large"  @input="deviation_reason = $util.inputReg(deviation_reason)"/>
			<el-button @click="handlerOK" type="warning" size="medium">OK</el-button>
		</div>
	</div>
</template>

<script>
import Navbar from "components/Navbar.vue";
import MyTitle from '../../components/MyTitle.vue';
import SwitchBox from "../../../../components/switchBox.vue";

export default {
	components: { Navbar, MyTitle, SwitchBox },
	data () {
		return {
			name: 'Caroline D',
			ProposalIndex: 1,
			is_deviation: '',
			deviation_reason: '',
			paragraph1: "- Address Client’s concern, investment, objectives, shortfall amount ($), time horizon, investment, risk profile, where applicable.",
			paragraph2: "- State how the plan meets client’s need(s).",
			paragraph3: "- State and explain features and benefits relating to the product sold.",
			paragraph4: "- Comparison of minimum of two plans for client’s consideration (if applicable).",
			paragraph5: "To help our client to better identify the riskiness of an investment, Infinitum risk ranked investment products ranging from " +
				"P0 to P10. An investment product with risk rating of P0 reflect the lowest tier of risk, while P10 reflects the highest tier of risk.",
			paragraph6: "1) For insurance products, please refer to the “distribution cost” item under the policy illustration and product summary for details on the fees and charges.",
			paragraph7: "2) For CIS, please refer to the following documents (where applicable) for the fees and charges:",
			paragraph8: "b) Up to 50% of the annual management fee charged by the CIS Fund (Please refer to the PHS/Prospectus/Fund Fact Sheet for CIS fund annual management fee rate) as trailer fee.",
			paragraph9: "If ‘yes’, explain reasons for deviation from client’s profile, objectives (E.g. premiums more than client’s budget, client choice of products/fund(s) differs from representative’s recommendation(s).",
			kycAllData: {},
			product_recommendations_option: {},
			onlyView: false
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		// 返回上一级
		updataAllData () {
			this.kycAllData.kyc.client[this.kycClient].basis_recommendations.product_recommendations_option[this.$router.currentRoute.query.proposal] = this.product_recommendations_option
			this.kycAllData.kyc.client[this.kycClient].basis_recommendations.is_deviation = this.is_deviation
			this.kycAllData.kyc.client[this.kycClient].basis_recommendations.deviation_reason = this.deviation_reason
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		handlerOK () {
			if (!this.onlyView) this.updataAllData()
			this.$router.go(-1)
		},
		// 获取名称
		getName (val) {
			let name = val.product_name
			if (val.rider_list.length > 0) {
				val.rider_list.map(fund => {
					name = name + ',' + fund.product_name
				})
			}
			if (val.fund_list.length > 0) {
				val.fund_list.map(fund => {
					name = name + ',' + fund.fund_name
				})
			}
			return name
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.onlyView = typeof(this.$router.currentRoute.query.view) == 'object'
		this.ProposalIndex = parseInt(this.$router.currentRoute.query.proposal) + 1
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.product_recommendations_option = this.kycAllData.kyc.client[this.kycClient].basis_recommendations.product_recommendations_option[this.$router.currentRoute.query.proposal]
		// this.is_deviation = this.kycAllData.kyc.client[this.kycClient].basis_recommendations.is_deviation
		this.is_deviation = this.$router.currentRoute.query.Annualised=='true' || this.$router.currentRoute.query.Single=='true' ? 'Y' :'N'
		this.deviation_reason = this.kycAllData.kyc.client[this.kycClient].basis_recommendations.deviation_reason
		this.name = this.kycAllData.kyc.client[this.kycClient].personal_information.title + '.' + this.kycAllData.kyc.client[this.kycClient].personal_information.surname
	},
	destroyed () { },
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	}
}
</script>
<style lang='css' scoped>
.productItemText {
	color: #696666;
	font-size: 20px;
	font-weight: bold;
	margin-top: 20px;
}
p {
	margin-top: 20px;
	font-size: 16px;
	color: #3c3c3c;
	line-height: 24px;
	letter-spacing: 0px;
}
.el-button--medium {
	margin-left: 80%;
	margin-top: 20px;
	padding: 10px 90px;
	font-size: 18px;
}
.el-button--warning {
	background-color: #fdb551;
	border-color: #fdb551;
}
</style>