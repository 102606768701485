/**
    金额类输入框
    获得焦点 23.1234
    失去焦点 S$ 23.12
 */
<template>
	<el-input v-model="inputValueShow" @keyup.native="proving($event)" @blur="inputBlur(currentRow)" @focus="inputFocus(currentRow)" @input="val => handleInput(val, currentRow.index)" />
</template>

<script>

export default {
    props: {
        // input值
        inputValue: {
            type: String,
			default: ''
        },
        // 当前行的值
        currentRow: {
            type: Object,
			default: {}
        },
        // 需要值修改的数组
        currentArr: {
            type: Array,
			default: []
        },
		// inputValueShow 展示S$ or %,默认展示S$
		ifAmount: {
			type: Boolean,
			default: true
		}
    },
	components: {},
	data () {
		return {
            inputValueShow: '',
            currentArrShow: []
        };
	},
	computed: {},
	watch: {
		inputValue(val) {
			this.inputValueShow = val
		},
		currentArr(val) {
			this.currentArrShow = val
		}
	},
	methods: {
        //限制只能输入一个小数点
		proving (e) {
			var val = e.target.value
			if (val.indexOf(".") != -1) {
				var str = val.substr(val.indexOf(".") + 1)
				if (str.indexOf(".") != -1) {
					val = val.substr(0, val.indexOf(".") + str.indexOf(".") + 1)
				}
			}
			e.target.value = val.replace(/[^\d^\.]+/g, '')
		},
        // 失焦 格式化数值，四舍五入，保留两位小数
		inputBlur (e) {
			if (this.currentArrShow[e.index].Amount == '') this.currentArrShow[e.index].Amount = '0'
			this.currentArrShow[e.index].AmountShow = this.ifAmount ? 'S$ ' + (Math.round(this.currentArrShow[e.index].Amount * 100) / 100).toFixed(2) : 
				(Math.round(this.currentArrShow[e.index].Amount * 100) / 100).toFixed(2) + ' %'
			let dataShow = this.$formatMoney((Math.round(this.currentArrShow[e.index].Amount * 100) / 100).toFixed(2))
			this.inputValueShow = this.ifAmount ? 'S$ ' + dataShow : dataShow + ' %'
			this.setData()
		},
		// 获取焦点 展示赋值
		inputFocus (e) {
			if (e.Amount == 0) e.Amount = ''
			this.currentArrShow[e.index].AmountShow = e.Amount
			this.inputValueShow = e.Amount
		},
        // 实际赋值
		handleInput (e, i) {
			let data =
				("" + e) // 第一步：转成字符串
				.replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
				.replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
				.replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
				.match(/^\d*(\.?\d{0,10})/g)[0] || "0"; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
			this.currentArrShow[i].Amount = data
		},
		setData () {
			console.log(this.currentArrShow);
			this.$emit('updateArr', this.currentArrShow)
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
        // 不允许直接修改prop中的值，故重新定义并赋值
        this.inputValueShow = this.inputValue
        this.currentArrShow = this.currentArr
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>