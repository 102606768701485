<!-- 橙色导航条 -->
<template>
	<div :class="className === '' ? 'navver' : 'navver navverFlex'" :style="{background: backgroundColor, color: color, fontSize: fontSize, height: height, padding: padding}">
        <i v-if="showBack" class="el-icon-arrow-left pointer" style="font-size: 34px;margin-right: 15px;" @click="backPage"></i>
		<slot></slot>
	</div>
</template>

<script>

export default {
	props: {
		NavShow: false,
		backgroundColor: {
			type: String,
			default: '#F9D155'
		},
		color: {
			type: String,
			default: '#6C6C6C'
		},
		fontSize: {
			type: String,
			default: '28px'
		},
		height: {
			type: String,
			default: '60px'
		},
		// 是否返回上一页
		showBack: {
			type: Boolean,
			debugger: false
		},
		// 添加样式类
		className: {
			type: String,
			default: ''
		},
		padding: {
			type: String,
			default: '5px 10px'
		},
		// 是否调用父组件赋值方法------------金额模块，返回时，需要将页面值赋给all_data
		ifSetData: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		backPage () {
			if (this.ifSetData) this.$emit('updataAllData')
			this.$router.go(-1)
		}
	}
}
</script>
<style lang='css' scoped>
.navver {
    box-sizing: border-box;
    line-height: 50px;
}
.navverFlex {
	display: flex;
    justify-content: space-between;
	align-items: center;
}
</style>