import SummaryOfKYC from './SummaryOfKYCView';
import ProductRecommendations from './ProductRecommendationsView';
import BasisOfRecommendation from './detail/BasisOfRecommendation';
import NeedsView from './detail/NeedsView.vue'
import PersonalInformation from './SummayOfKYCDetail/components/PersonalInformation'
import TrustedIndividuals from './SummayOfKYCDetail/components/TrustedIndividuals'
import MainPlanView from './detail/MainPlanView.vue'
import Presentation from './detail/Presentation.vue'
import FundView from './detail/FundView.vue'
import RiderView from './detail/RiderView.vue'

export {
  SummaryOfKYC,
  ProductRecommendations
}
export default {
  BasisOfRecommendation,
  PersonalInformation,
  TrustedIndividuals,
  NeedsView,
  MainPlanView,
  Presentation,
  FundView,
  RiderView,
}

