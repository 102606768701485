<template>
	<div class="switchBox" :style="{width: width}">
		<span class="switchBox_yes TextAlginCenter" @click="yesBox($event)" :style='{backgroundColor: yesbgColor, color: yesfontColor}'>YES</span>
		<span class="switchBox_no TextAlginCenter" @click="noBox($event)" :style='{backgroundColor: nobgColor, color: nofontColor}'>NO</span>
	</div>
</template>

<script>

export default {
	components: {},
	props: {
		status: {
			default: 0
		},
		// switch选择框宽度
		width: {
			type: String,
			default: '150px'
		},
		// 区分 YES是 Y||0   NO是 N||1
		returnType: {
			default: true
		},
		// 行内容
		row: {},
		// 是否禁用
		isDisabled: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			yesbgColor: 'white',
			yesfontColor: '#639EEC',
			nobgColor: 'white',
			nofontColor: '#D25759',
		};
	},
	computed: {},
	watch: {
		status (val) {
			if (val === 1 || val === '1' || val === 'N') {
				this.noBox()
			} else if (val === 0 || val === '0' || val === 'Y') {
				this.yesBox()
			} else {
				this.blankBox()
			}
		}
	},
	methods: {
		yesBox (e) {
			if (this.isDisabled) return
			this.yesbgColor = '#639EEC'
			this.nobgColor = 'white'
			this.yesfontColor = 'white'
			this.nofontColor = '#D25759'
			if (this.returnType) {
				this.$emit('update:status', 'Y')
			} else {
				this.$emit('update:status', '0')
				this.$emit('valueChange', this.row, e)
			}
		},
		noBox (e) {
			if (this.isDisabled) return
			this.yesbgColor = 'white'
			this.nobgColor = '#D25759'
			this.yesfontColor = '#639EEC'
			this.nofontColor = 'white'
			if (this.returnType) {
				this.$emit('update:status', 'N')
			} else {
				this.$emit('update:status', '1')
				this.$emit('valueChange', this.row, e)
			}
		},
		blankBox () {
			this.yesbgColor = 'white'
			this.yesfontColor = '#639EEC'
			this.nobgColor = 'white'
			this.nofontColor = '#D25759'
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		if (this.status === 1 || this.status === '1' || this.status === 'N') {
			this.noBox()
		} else if (this.status === 0 || this.status === '0' || this.status === 'Y') {
			this.yesBox()
		} else {
			// 初始化状态
			this.blankBox()
		}
	},
}
</script>
<style lang='css' scoped>
.switchBox {
	height: 40px;
	border: 1px solid #909399;
	font-weight: 600;
	border-radius: 5px;
	display: flex;
	cursor: pointer;
}
.switchBox_yes {
	line-height: 40px;
	flex: 1;
}
.switchBox_no {
	line-height: 40px;
	flex: 1;
}
</style>