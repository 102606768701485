<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Monthly Cash Outflow</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- Household表格 -->
			<el-table :data="householdTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="Household">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.AmountShow}}</span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="householdTableData" @updateArr="updateArr"></AmountInput>
					</template>
				</el-table-column>
			</el-table>
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total S$ <span style="color: black;">{{householdTotal}}</span></div>
			<!-- Dependants表格 -->
			<el-table :data="dependantsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="Dependants">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.AmountShow}}</span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="dependantsTableData" @updateArr="updateArr2"></AmountInput>
					</template>
				</el-table-column>
			</el-table>
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total S$ <span style="color: black;">{{dependantsTotal}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import AmountInput from "../../components/amountInput.vue";

export default {
	components: { Navbar, AmountInput },
	data () {
		return {
			householdTableData: [
				{
					Amount: '0',
					name: 'Utilities',
					value: 'utilities',
				}, {
					Amount: '0',
					name: 'Groceries',
					value: 'groceries',
				}, {
					Amount: '0',
					name: 'Property Tax',
					value: 'property_tax',
				}, {
					Amount: '0',
					name: 'Maid Expenses',
					value: 'maid_expenses',
				}, {
					Amount: '0',
					name: 'Cash Mortgage',
					value: 'cash_mortgage',
				}, {
					Amount: '0',
					name: 'Others',
					value: 'household_others',
				}
			],
			dependantsTableData: [
				{
					Amount: '0',
					name: 'Allowance - Parents',
					value: 'allowance_parents',
				}, {
					Amount: '0',
					name: 'Allowance - Children',
					value: 'allowance_children',
				}, {
					Amount: '0',
					name: 'School Fees',
					value: 'school_fees',
				}, {
					Amount: '0',
					name: 'Medical Expenses',
					value: 'medical_mxpenses',
				}, {
					Amount: '0',
					name: 'Others',
					value: 'dependants_others',
				}
			],
			disabled: false,
			kycAllData: {}
		};
	},
	computed: {
		household_total () {
			let total = 0
			this.householdTableData.map(res => {
				total += parseFloat(res.Amount || 0)
			})
			if (isNaN(total)) total = ''
			return (Math.round(total * 100) / 100).toFixed(10).replace(/(0+)$/g, '')
		},
		householdTotal () {
			return this.$formatMoney((Math.round(this.household_total * 100) / 100).toFixed(2))
		},
		dependants_total () {
			let total = 0
			this.dependantsTableData.map(res => {
				total += parseFloat(res.Amount || 0)
			})
			if (isNaN(total)) total = ''
			return (Math.round(total * 100) / 100).toFixed(10).replace(/(0+)$/g, '')
		},
		dependantsTotal () {
			return this.$formatMoney((Math.round(this.dependants_total * 100) / 100).toFixed(2))
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.$forceUpdate()
			this.householdTableData = arr
		},
		updateArr2 (arr) {
			this.$forceUpdate()
			this.dependantsTableData = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			if (!this.disabled) {
				this.householdTableData.map(res => { this.kycAllData.kyc.client[this.kycClient].cashflow[res.value] = res.Amount })
				this.dependantsTableData.map(res => { this.kycAllData.kyc.client[this.kycClient].cashflow[res.value] = res.Amount })
				this.kycAllData.kyc.client[this.kycClient].cashflow.household_total = this.household_total
				this.kycAllData.kyc.client[this.kycClient].cashflow.dependants_total = this.dependants_total
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.householdTableData.map((key, i) => {
			key.Amount = this.kycAllData.kyc.client[this.kycClient].cashflow[key.value]
			key.AmountShow = 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.index = i
		})
		this.dependantsTableData.map((key, i) => {
			key.Amount = this.kycAllData.kyc.client[this.kycClient].cashflow[key.value]
			key.AmountShow = 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.index = i
		})
		this.disabled = this.$router.currentRoute.fullPath.includes('View')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
span {
	line-height: 24px;
}
</style>