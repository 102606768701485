<template>
	<div>
		<ElementDialog Title="Choose Product" :Visible.sync="Dialog.visible" :fullscreen="true" class="my-dialog">
			<div slot="content">
				<!-- 查询条件 -->
				<NavbarVue padding="5px 60px">
					<el-form :inline="true" :model="searchParam" class="demo-form-inline" size="medium">
						<el-form-item>
							<el-select v-model="searchParam.needs" @change="selectChange" placeholder="Needs" multiple collapse-tags>
								<el-option label="All" value="selectAll" v-if="productLineOptions.length > 0"></el-option>
								<el-option v-for="item in productLineOptions" :key="item.code" :value="item.code" :label="item.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchParam.provider" placeholder="Provider" filterable>
								<el-option v-for="item in providerOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-input v-model="searchParam.productName" placeholder="Product Name">
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchParam.DIY" placeholder="Filter">
								<el-option v-for="item in FilterOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-button type="info" @click="getTableData('search-button')" size="medium" style="height: 50px;color: black;padding: 10px 30px;font-size: 18px;">Search</el-button>
					</el-form>
				</NavbarVue>
				<!-- product内容 -->
				<div class="content" id="content">
					<div style="font-size: 20px;color: #5D5D5D;">{{pages.total}} Product(s)</div>
					<!-- 产品列表盒子 -->
					<div class="mt20px displayFlex flexWrap flexJCspaceBetween overFlow" style="height: 650px;">
						<!-- 各个产品 -->
						<div class="box displayFlex flexColumn" style="height: 150px;" v-for="(item, index) in boxContent" @click="AddInfomation(item)" :key="index">
							<div class="box_title">{{item.product_name}}</div>
							<div class="box_text">
								<div class="IncomeStyle h40px" :title="item.provider_short_name">{{item.provider_short_name}}</div>
								<!-- <div class="ellipsis" v-ellipsis="{ 'line': 2, 'showTitle': true}">{{item.product_description}}</div> -->
								<div class="ellipsis1" :title="item.product_description">{{item.product_description}}</div>
							</div>
						</div>
					</div>
					<Pagination :pages="pages" listKey="getTableData" pageKey="pages" :Multilevel="true" @SetPagination="$SetPagination('dialog')">
					</Pagination>
				</div>
			</div>
		</ElementDialog>
		<ElementDialog :Title="DialogDetail.title" :Visible.sync="DialogDetail.visible" :showClose="false" class="my-dialog-title">
			<el-form slot="content" :model="detailsForm" v-if="DialogDetail.visible" class="my-form">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Benefit Amount" prop="benefit_amount">
							<amount-input-form :currentObj="detailsForm" :inputValue="detailsForm.benefit_amount" fieldName="benefit_amount" @updateArr="updateArr"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Policy Term" prop="policy_term">
							<el-input v-model="detailsForm.policy_term" type="number"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Premium Amount" prop="premium_amount">
							<amount-input-form :currentObj="detailsForm" :inputValue="detailsForm.premium_amount" fieldName="premium_amount" @updateArr="updateArr"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Premium Mode" prop="premium_mode">
							<el-select v-model="detailsForm.premium_mode" placeholder="">
								<el-option v-for="item in premiumMode" :key="item.code" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Need(s) for" prop="product_for">
							<el-select v-model="detailsForm.product_for" placeholder="">
								<el-option v-for="item in needsFor" :key="item.code" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="SaveAdd">Confirm</el-button>
				<el-button @click="CloseDetail">Cancel</el-button>
			</div>
		</ElementDialog>
		<!-- CI -->
		<ElementDialog :Title="CIDialogDetail.title" :Visible.sync="CIDialogDetail.visible" :showClose="false" class="my-dialog-title">
			<el-form slot="content" :model="detailsCIForm" v-if="CIDialogDetail.visible" class="my-form addWidth">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Lump Sum Amount" prop="benefit_amount">
							<amount-input-form :currentObj="detailsCIForm" :inputValue="detailsCIForm.benefit_amount" fieldName="benefit_amount" @updateCIArr="updateCIArr"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Term / Duration" prop="policy_term">
							<el-input v-model="detailsCIForm.policy_term" type="number"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Regular Investment Scheme (RIS)" prop="premium_amount">
							<amount-input-form :currentObj="detailsCIForm" :inputValue="detailsCIForm.premium_amount" fieldName="premium_amount" @updateCIArr="updateCIArr"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Premium Mode" prop="premium_mode">
							<el-select v-model="detailsCIForm.premium_mode" placeholder="">
								<el-option v-for="item in CISPremiumMode" :key="item.code" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Need(s) for" prop="product_for">
							<el-select v-model="detailsCIForm.product_for" placeholder="">
								<el-option v-for="item in needsFor" :key="item.code" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Model of Payment" prop="model_of_payment">
							<el-select v-model="detailsCIForm.model_of_payment" placeholder="">
								<el-option v-for="item in payment" :key="item.code" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="SaveAdd('CI')">Confirm</el-button>
				<el-button @click="CloseCIDetail">Cancel</el-button>
			</div>
		</ElementDialog>
	</div>
</template>

<script>
import ElementDialog from "@/components/ElementDialog/index.vue";
import NavbarVue from "@/components/Navbar.vue";
import Pagination from "@/components/Pagination.vue";
import { productCommonInfoQuery } from '@/api/Product'
import { recommendationProducts } from '@/api/KYC/index'
import { GET_username } from '@/utils/localStorage';
import { GET_providerOption } from "../../../../utils/localStorage";
import AmountInputForm from '../../components/amountInputForm.vue';
export default {
	components: { ElementDialog, NavbarVue, Pagination, AmountInputForm },
	data () {
		return {
			// product--------------------------------------
			Dialog: {
				visible: false,
				status: "Add",
			},
			searchParam: {
				needs: [],
				product_name: '',
				provider_code: '',
				DIY: ''
			},
			boxContent: [],
			productLineOptions: [],
			FilterOptions: [
				{
					value: 'N',
					label: 'OFF'
				}, {
					value: 'Y',
					label: 'ON'
				}
			],
			providerOptions: [
				{
					value: '',
					label: 'All'
				}
			],
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			currentProduct: {},
			// 产品详情页-----------------------------
			DialogDetail: {
				visible: false,
				status: "Add",
				title: ''
			},
			CIDialogDetail: {
				visible: false,
				status: "Add",
				title: ''
			},
			premiumMode: global.premiumMode,
			CISPremiumMode: global.CISPremiumMode,
			payment: global.payment,
			needsFor: [
				{ code: 'myself', name: 'Myself' },
			],
			detailsForm: {
				benefit_amount: '',
				policy_term: '',
				premium_amount: '',
				premium_mode: 'Monthly',
				product_for: 'myself'
			},
			detailsCIForm: {
				benefit_amount: '',
				policy_term: '',
				premium_amount: '',
				premium_mode: 'Monthly',
				product_for: 'myself',
				model_of_payment: ''
			},
			kycAllData: {},
			dataValue: {},
			proposalIndex: 0
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		'Dialog.visible' (val) {
			if (val) {
				this.setProductLineOptions(this.dataValue)
			}
		},
		kycClient () {
			this.getData()
		},
	},
	methods: {
		// 获取提供者
		async getCommonInfo () {
			if (GET_providerOption()) {
				this.providerOptions = JSON.parse(GET_providerOption())
			} else {
				let res = await productCommonInfoQuery({ advisor_code: GET_username() })
				res.content.provider.map(provider => {
					this.providerOptions.push({ value: provider.provider_code, label: provider.provider_name })
				})
			}
		},
		getTableData (val) {
			if (val === 'search-button') this.pages.pageNum = 1
			let grade = this.dataValue.risk_profile_questionnaire.is_agree_profile === 'N' ? this.dataValue.risk_profile_questionnaire.own_choice : this.dataValue.risk_profile_questionnaire.grade
			let obj = {
				financial_goals: this.searchParam.needs,
				kyc_number: this.$store.state.KYC.kycAllData.kyc.kyc_no,
				client_no: GET_username(),
				ai: this.dataValue.accredited_investor_declaration.is_ai,
				grade: this.searchParam.DIY == 'N' ? '' : grade,
				provider_code: this.searchParam.provider,
				product_name: this.searchParam.productName,
				page_number: String(this.pages.pageNum),
				page_size: String(this.pages.pageSize)
			}
			recommendationProducts(obj).then(res => {
				this.pages.total = parseInt(res.content.total)
				this.boxContent = res.content.product_list
			})
		},
		// 打开详细信息
		AddInfomation (row) {
			if (row.product_line_code === 'CI') {
				this.detailsCIForm = this.$ResetFrom(this.detailsCIForm)
				this.detailsCIForm.premium_mode = 'Monthly'
				this.detailsCIForm.product_for = 'myself'
				this.CIDialogDetail.title = row.product_name
				this.CIDialogDetail.visible = true
			} else {
				this.detailsForm = this.$ResetFrom(this.detailsForm)
				this.detailsForm.premium_mode = 'Monthly'
				this.detailsForm.product_for = 'myself'
				this.DialogDetail.title = row.product_name
				this.DialogDetail.visible = true
			}
			this.currentProduct = row
		},
		// 保存详细信息
		SaveAdd (temp) {
			let obj = {
				product_id: this.currentProduct.infinitum_product_code,
				needs_analysis_list: this.currentProduct.financial_goals.split('|'),
				financial_goals: this.searchParam.needs,
				product_name: this.currentProduct.product_name,
				product_line_code: this.currentProduct.product_line_code,
				company: this.currentProduct.provider_name,
				provider_code: this.currentProduct.provider_code,
				product_description: this.currentProduct.product_description,
				provider_logo_url: this.currentProduct.provider_logo_url,
				recommendation_reason: "",
				product_risk_rating: this.currentProduct.product_risk_rating,
				possible_limitations: this.currentProduct.possible_limitations,
				is_diy: this.searchParam.DIY,
				is_select_product: this.$router.currentRoute.name === 'Presentation' ? "Y" : "N",
				is_recommendation: this.$router.currentRoute.name === 'Presentation' ? "N" : "Y",
				rider_list: [],
				fund_list: [],
				unique_id: Date.now().toString(),
				product_type_name: this.currentProduct.product_type_name,
				product_type_code: this.currentProduct.product_type_code,
			}
			if (temp === 'CI') {
				Object.assign(obj, this.detailsCIForm)
			} else {
				Object.assign(obj, this.detailsForm)
			}
			this.$emit('add_product_list', obj)
			this.CloseDetail()
			this.CloseCIDetail()
			this.Dialog.visible = false
		},
		CloseDetail () {
			this.DialogDetail.visible = false
		},
		CloseCIDetail () {
			this.CIDialogDetail.visible = false
		},
		// 选择产品页面-查询条件下拉选赋值
		setProductLineOptions (dataValue) {
			let neesArr = []
			if (dataValue.basis_recommendations.product_recommendations_option.length > 0) {
				dataValue.basis_recommendations.product_recommendations_option[this.proposalIndex].protection_needs_setup.map(res => {
					if (res.is_select_type === 'Y') neesArr.push(res.protection_needs)
				})
			}
			neesArr = Array.from(new Set(neesArr))
			this.productLineOptions = dataValue.accredited_investor_declaration.is_ai === 'Y' ? global.needs : global.needs.filter(code => neesArr.includes(code.code))
			if (this.productLineOptions.length > 0) this.searchParam.needs.push(this.productLineOptions[0].code)
			// 查询数据
			setTimeout(() => {
				this.getTableData('search-button')
			}, 200)
		},
		// 选择产品页面-查询条件-needs全选
		selectChange (val) {
			if (val.includes('selectAll')) {
				let arr = []
				this.productLineOptions.map(res => { arr.push(res.code) })
				this.searchParam.needs = Object.assign([], arr)
			}
		},
		updateCIArr (arr) {
			this.detailsCIForm = arr
		},
		updateArr (arr) {
			this.detailsForm = arr
		},
		setNeedsfor () {
			this.needsFor = [{ code: 'myself', name: 'Myself' }]
			// 根据1.3节点 判断是否有依赖人
			if (this.dataValue.dependants_list.is_dependants === 'Y') {
				this.dataValue.dependants_list.dependants_details.map(res => {
					this.needsFor.push({ code: res.dependant_id, name: res.name })
				})
				console.log(this.needsFor, 'needsfor');
			}
		},
		getData () {
			this.kycAllData = this.$store.state.KYC.kycAllData
			this.dataValue = this.kycAllData.kyc.client[this.kycClient]
			this.getCommonInfo()
			// 设置needs for 下拉选值
			this.setNeedsfor()
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.getData()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
.box {
	align-items: flex-end;
	width: 45%;
	margin-top: 20px;
	cursor: pointer;
}
.box_title {
	margin-right: auto;
	font-size: 22px;
	color: #5a5b5e;
}
.box_text {
	width: 90%;
	font: menu;
	font-size: 16px;
	color: #5d5d5d;
}
.IncomeStyle {
	width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
	text-align: center;
	border: 1px solid #409eff;
    color: #409eff;
    margin-top: 10px;
    border-radius: 3px;
    line-height: 40px;
    white-space: nowrap;
    float: right;
	padding: 0 10px;
	cursor: pointer;
}
.ellipsis {
	margin-top: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.ellipsis1 {
	margin-top: 60px;
	overflow:hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: -webkit-box;
}

.content {
	flex: 1;
	overflow-y: scroll;
	padding: 15px 60px 10px 60px;
}
.overFlow {
	overflow: scroll;
}

.el-form >>> .el-input__inner {
	height: 50px !important;
}
/* form表单样式 */
.demo-form-inline {
	display: flex;
}
.addWidth >>> .el-input__inner {
	padding-left: 260px;
}
.addWidth >>> .el-input--prefix .el-input__inner {
	padding-left: 260px;
}
</style>