/*
 * @Description: KYC数据操作
 * @Date: 2022-8-30
 * @FilePath: \zkr-ins-pc\src\utils\kyc.js
 */
export function analysisNeeds (data) {
  const needs = [];
  // Access to investment target information
  const financial_objectives = data.financial_objectives;
  const needs_analysis = data.needs_analysis;
  const dependants_needs_list = data.needs_analysis.plan_dependant_protection.dependants_needs_list;
  global.needs.forEach(code => {
    const keyValue = financial_objectives[code.key];
    // If the value in the investment target is not Na or is not empty, other will not be displayed, only the subset under other will be displayed
    if (keyValue && keyValue != 'NA') {
      needs.push({
        protection_needs: code.code,
        protection_name: code.name,
        value: needs_analysis[code.valueKey] || 0,
        years: needs_analysis[code.yearKey],
        color: code.color,
        is_dependant: 'N',
      });
    }
  });
  // 添加多依赖人need 
  // add dependants data 
  dependants_needs_list.forEach(item1 => {
    item1.needs_list.forEach(item2 => {
      let dependant_fund = global.needs.find(el => el.code == item2.needs);
      if (!dependant_fund) {
        dependant_fund = {
          name: '',
        }
      }
      needs.push({
        protection_needs: item2.needs,
        protection_name: `${dependant_fund.name}: ${item1.dependant_name}`,
        value: item2.short_fall,
        color: (dependant_fund && dependant_fund.color) || '#dde9f3',
        is_dependant: 'Y',
      });
    });
  });
  // get others data
  const others = global.needs.find(item => item.code == '457');
  // add others data
  needs_analysis.protection_goals_others.forEach(item => {
    needs.push({
      protection_needs: '457',
      protection_name: `Other Protection: ${item.other_name}`,
      value: item.total || 0,
      color: (others && others.color) || '#dde9f3',
      is_dependant: 'N',
    });
  });
  // get others data
  const otherWealth = global.needs.find(item => item.code == '464');
  needs_analysis.saving_accumulation_goals_others.forEach(item => {
    needs.push({
      protection_needs: '464',
      protection_name: `Other Wealth Accumulation & Preservation Goals: ${item.other_name}`,
      value: item.total || 0,
      color: (otherWealth && otherWealth.color) || '#dde9f3',
      years: item[otherWealth.yearKey],
      is_dependant: 'N',
    });
  });
  const education_fund = global.needs.find(item => item.code == '463');

  // add Education Fund Planning data
  needs_analysis.education_fund.forEach(item => {
    needs.push({
      protection_needs: '463',
      protection_name: `Saving For Education Fund: ${item.dependant_name}`,
      value: item.total,
      color: (education_fund && education_fund.color) || '#dde9f3',
      years: item[education_fund.yearKey],
      is_dependant: item.dependant_id != 'myself' ? 'Y' : 'N',
    });
  });
  return needs
}


/**
 * 1.3 赋值 1.4
 */
export function getDataFromDep (dataValue) {
  // 从1.3节点获取，并格式话数据-----------------------------------------------------------------------
  let protectionTableData = []
  // 若1.3选择yes， 则从1.3节点dependants_details获取
  if (dataValue.dependants_list.is_dependants === 'Y') {
    let arr = dataValue.dependants_list.dependants_details
    arr.map(res => {
      let obj = {
        dependant_id: res.dependant_id,
        dependant_name: res.name,
        dependant_financial_objectives_value: ''
      }
      // 判断数据之前是否存在，若存在则将value值带过来
      dataValue.financial_objectives.dependants_financial_objectives.map(old => {
        if (res.dependant_id === old.dependant_id) {
          obj.dependant_financial_objectives_value = old.dependant_financial_objectives_value
        }
      })
      protectionTableData.push(obj)
    })
  }
  dataValue.financial_objectives.dependants_financial_objectives = protectionTableData
}

/**
 * 1.4 赋值 1.12
 * ----------------------------------------------education_fund-----------------------------------------------------------------
 */
export function getDataFromFin (dataValue) {
  //1.4数据
  let dependants_financial_objectives = dataValue.financial_objectives.dependants_financial_objectives
  //1.12数据
  let education_fund = dataValue.needs_analysis.education_fund
  let newArr = []
  // 第一条固定为myself
  if (dataValue.financial_objectives.myself_education_fund && dataValue.financial_objectives.myself_education_fund !== 'NA') {
    newArr.push({
      dependant_id: 'myself',
      dependant_name: 'Myself',
      available_funds: '0',
      average_education_inflation_rate: '0',
      current_age: '0',
      dependant_index: '',
      education_cost_today: '0',
      less_maturity_value: '0',
      less_used_value: '0',
      save_years_number: '0',
      total: '0',
      total_cost: '0',
      total_cost_education: '0',
    })
  }
  dependants_financial_objectives.map((res, index) => {
    if (res.dependant_financial_objectives_value && res.dependant_financial_objectives_value !== 'NA') {
      newArr.push({
        dependant_name: res.dependant_name,
        dependant_id: res.dependant_id,
        available_funds: '0',
        average_education_inflation_rate: '0',
        current_age: '0',
        dependant_index: res.dependant_id === 'myself' ? '' : index.toString(),
        education_cost_today: '0',
        less_maturity_value: '0',
        less_used_value: '0',
        save_years_number: '0',
        total: '0',
        total_cost: '0',
        total_cost_education: '0',
      })
    }
  })
  // 若原来有值，则带过来
  newArr.map(dataA => {
    education_fund.map(dataB => {
      if (dataA.dependant_id === dataB.dependant_id) {
        dataA.available_funds = dataB.available_funds
        dataA.average_education_inflation_rate = dataB.average_education_inflation_rate
        dataA.current_age = dataB.current_age
        // dataA.dependant_index = dataB.dependant_index
        dataA.education_cost_today = dataB.education_cost_today
        dataA.less_maturity_value = dataB.less_maturity_value
        dataA.less_used_value = dataB.less_used_value
        dataA.save_years_number = dataB.save_years_number
        dataA.total = dataB.total
        dataA.total_cost = dataB.total_cost
        dataA.total_cost_education = dataB.total_cost_education
      }
    })
  })
  dataValue.needs_analysis.education_fund = newArr
}

/**
 * 1.4 赋值 1.12
 * -----------------------------------------------protection_goals_others\saving_accumulation_goals_others----------------------
 */
export function getOtherDataFromFin (dataValue) {
  let temp_protection = []
  let temp_saving = []
  //1.12数据
  let protection_goals_others = dataValue.needs_analysis.protection_goals_others
  let saving_accumulation_goals_others = dataValue.needs_analysis.saving_accumulation_goals_others
  dataValue.financial_objectives.other_financial_objectives.map(res => {
    // other commonData
    if (res.dynamic_field_type === 'protection' && res.field_value && res.field_value !== 'NA') {
      temp_protection.push({
        index: res.dynamic_field_order,
        indicated_plan_for: '',
        less_existing_insurance: '',
        lump_sum_required: '',
        other_name: res.field_key,
        total: ''
      })
      // other SavingsGoalsTableData
    } else if (res.dynamic_field_type === 'savings' && res.field_value && res.field_value !== 'NA') {
      temp_saving.push({
        lump_sum_needed_today: '',
        when_fund_needed: '',
        inflation_rate: '',
        total_fund_needed: '',
        current_resources: '',
        expected_rate_return: '',
        less_expected_furture: '',
        less_expected_cash: '',
        index: res.dynamic_field_order,
        other_name: res.field_key,
        total: ''
      })
    }
  })
  // 若原来有值，则带过来
  temp_protection.map(dataA => {
    protection_goals_others.map(dataB => {
      if (dataA.index === dataB.index) {
        dataA.ndicated_plan_for = dataB.ndicated_plan_for
        dataA.less_existing_insurance = dataB.less_existing_insurance
        dataA.lump_sum_required = dataB.lump_sum_required
        dataA.total = dataB.total
      }
    })
  })
  temp_saving.map(dataA => {
    saving_accumulation_goals_others.map(dataB => {
      if (dataA.index === dataB.index) {
        dataA.lump_sum_needed_today = dataB.lump_sum_needed_today
        dataA.when_fund_needed = dataB.when_fund_needed
        dataA.inflation_rate = dataB.inflation_rate
        dataA.total_fund_needed = dataB.total_fund_needed
        dataA.current_resources = dataB.current_resources
        dataA.expected_rate_return = dataB.expected_rate_return
        dataA.less_expected_furture = dataB.less_expected_furture
        dataA.less_expected_cash = dataB.less_expected_cash
        dataA.total = dataB.total
      }
    })
  })
  dataValue.needs_analysis.protection_goals_others = temp_protection
  dataValue.needs_analysis.saving_accumulation_goals_others = temp_saving
}

/**
 * 1.4 赋值 1.12
 * -----------------------------------------------protection_goals_others\saving_accumulation_goals_others----------------------
 */
export function getPlanningFromFin (dataValue) {
  let tempObj = {
    dependants_needs_list: [],
    dependants_total_all: 0
  }
  if (dataValue.financial_objectives.dependant_protection_planning && dataValue.financial_objectives.dependant_protection_planning !== 'NA') {
    dataValue.financial_objectives.dependants_financial_objectives.map((res, i) => {
      let obj = {
        dependant_id: res.dependant_id,
        dependant_name: res.dependant_name,
        dependant_total: '',
        dependant_index: i,
        needs_list: []
      }
      tempObj.dependants_needs_list.push(obj)
    })
  }
  // 若原来有值，则带过来
  tempObj.dependants_needs_list.map(dataA => {
    dataValue.needs_analysis.plan_dependant_protection.dependants_needs_list.map(dataB => {
      if (dataA.dependant_id === dataB.dependant_id) {
        dataA.needs_list = dataB.needs_list
        dataA.dependant_total = dataB.dependant_total
      }
    })
  })
  tempObj.dependants_needs_list.map(res => {
    tempObj.dependants_total_all += parseFloat(res.dependant_total || 0)
  })
  dataValue.needs_analysis.plan_dependant_protection = tempObj
}

export function setProtectionNeeds (dataValue, commonData, store) {
  let protectionNeeds = []
  commonData.map(data => {
    // 数值小于0  不包含planning、other  在1.4节点对应不为NA
    if (parseFloat(data.Amount) < 0 && !data.type.includes('Planning') && !data.type.includes('Other Protection') &&
      dataValue.financial_objectives[data.fin_key] !== '' && dataValue.financial_objectives[data.fin_key] != 'NA') {
      let needs = global.needs.filter(n => n.key === data.fin_key)[0]
      protectionNeeds.push({
        select_products_number: '0',
        protection_needs: needs.code,
        protection_name: data.type,
        is_select_type: 'Y',
        time_horizon: dataValue.needs_analysis[needs.yearKey],
        total: parseFloat(data.Amount).toFixed(2),
        is_dependant: ''
      })
    }
    // Medical\Premium只判断在1.4节点是否为NA
    if (data.fin_key === 'medical_hospitalisation_costs') {
      if (dataValue.financial_objectives[data.fin_key] !== '' && dataValue.financial_objectives[data.fin_key] != 'NA') {
        protectionNeeds.push({
          select_products_number: '0',
          protection_needs: '624',
          protection_name: data.type,
          is_select_type: 'Y',
          time_horizon: '',
          total: '',
          is_dependant: 'N'
        })
      }
      if (dataValue.financial_objectives.premium_waiver !== '' && dataValue.financial_objectives.premium_waiver != 'NA') {
        protectionNeeds.push({
          select_products_number: '0',
          protection_needs: 'Premium Waiver',
          protection_name: 'Premium Waiver',
          is_select_type: 'Y',
          time_horizon: '',
          total: '',
          is_dependant: 'N'
        })
      }
    }
  })
  // protection_goals_others
  dataValue.needs_analysis.protection_goals_others.map(item => {
    if (parseFloat(item.total) < 0) {
      protectionNeeds.push({
        select_products_number: '0',
        protection_needs: '457',
        protection_name: `Other Protection: ${item.other_name}`,
        is_select_type: 'Y',
        total: parseFloat(item.total).toFixed(2),
      })
    }
  })
  // Add data of education fund planning
  dataValue.needs_analysis.education_fund.map(item => {
    if (parseFloat(item.total) < 0) {
      protectionNeeds.push({
        select_products_number: '0',
        protection_name: `Saving for education fund: ${item.dependant_name}`,
        protection_needs: '463',
        is_select_type: 'Y',
        time_horizon: item.save_years_number,
        total: parseFloat(item.total).toFixed(2),
        is_dependant: item.dependant_id != 'myself' ? 'Y' : 'N'
      })
    }
  })
  // saving_accumulation_goals_others
  dataValue.needs_analysis.saving_accumulation_goals_others.map(item => {
    if (parseFloat(item.total) < 0) {
      protectionNeeds.push({
        select_products_number: '0',
        protection_name: `Other Wealth Accumulation & Preservation Goals: ${item.other_name}`,
        protection_needs: '464',
        is_select_type: 'Y',
        time_horizon: item.when_fund_needed,
        total: parseFloat(item.total).toFixed(2),
        is_dependant: 'N'
      })
    }
  })
  // Add planning for dependant
  dataValue.needs_analysis.plan_dependant_protection.dependants_needs_list.map(dependant => {
    let name = dependant.dependant_name
    dependant.needs_list.map(need => {
      if (!need.needs) {
        return false;
      }
      if (parseFloat(need.short_fall) < 0) {
        protectionNeeds.push({
          select_products_number: '0',
          protection_name: global.priority.filter(n => n.code == need.needs)[0].name,
          protection_needs: need.needs,
          is_select_type: 'Y',
          is_dependant: 'Y',
          dependant_name: dependant.dependant_name,
          time_horizon: dataValue.needs_analysis[global.needs.filter(n => n.code == need.needs)[0].yearKey],
          total: need.short_fall,
        })
      }
    })
  })
  // 判断kycAllData中protection_needs_setup是否存在，如果存在 则要更新数据
  if (dataValue.basis_recommendations.product_recommendations_option.length > 0 && dataValue.basis_recommendations.product_recommendations_option[0].protection_needs_setup.length > 0) {
    dataValue.basis_recommendations.product_recommendations_option.map(res => {
      // protection_needs_setup中存在is_select_type为N，需要替换具体内容；否则直接取protectionNeeds
      let typeNoArr = res.protection_needs_setup.filter(n => n.is_select_type === 'N')
      if (typeNoArr.length > 0) {
        let newProtectionNeeds = JSON.parse(JSON.stringify(protectionNeeds))
        typeNoArr.map(typeNo => {
          newProtectionNeeds.map(newNeed => {
            if (typeNo.protection_name === newNeed.protection_name) newNeed.is_select_type = 'N'
          })
        })
        res.protection_needs_setup = newProtectionNeeds
      } else {
        res.protection_needs_setup = protectionNeeds
      }
    })
  }
  store.commit('SET_PROTECTION_NEEDS', protectionNeeds)
}

export function setRealNeeds (dataValue, store) {
  // 构造commonData，调用setProtectionNeeds
  let goalsTableData = [
    {
      type: 'Death',
      Amount: '',
      key: 'total_death',
      fin_key: 'income_protection_death'
    }, {
      type: 'Total permanent disability',
      Amount: '',
      key: 'total_disability',
      fin_key: 'income_protection_disability'
    }, {
      type: 'Critical Illness',
      Amount: '',
      key: 'total_ilness',
      fin_key: 'critical_illness'
    }, {
      type: 'Medical and hospitalisation costs',
      Amount: '',
      text: 'text',
      fin_key: 'medical_hospitalisation_costs'
    }, {
      type: 'Outpatient medical expenses protection',
      Amount: '',
      key: 'plan_total_amount_outpatient',
      fin_key: 'outpatient_medical_expenses_protection'
    }, {
      type: 'Dental expenses protection',
      Amount: '',
      key: 'total_amount_plan_dental',
      fin_key: 'dental_expenses_protection'
    }, {
      type: 'Personal accident',
      Amount: '',
      key: 'total_accident',
      fin_key: 'personal_accident'
    }, {
      type: 'Long term care protection',
      Amount: '',
      key: 'total_amount_ltc',
      fin_key: 'long_term_care'
    }, {
      type: "Planning for dependant's protection",
      Amount: '',
      key: 'dependants_total_all',
      fin_key: 'dependant_protection_planning'
    }
  ]
  let SavingsGoalsTableData = [
    {
      type: 'Retirement fund',
      Amount: '',
      path: '/RetirementFund',
      key: 'total',
      ifShow: true,
      fin_key: 'retirement_needs'
    }
  ]
  goalsTableData.map(res => {
    if (res.key === 'dependants_total_all') {
      res.Amount = parseFloat(dataValue.needs_analysis.plan_dependant_protection.dependants_total_all || 0)
    } else if (res.key) {
      res.Amount = parseFloat(dataValue.needs_analysis[res.key] || 0)
    }
  })
  SavingsGoalsTableData.map(res => {
    if (res.key) {
      res.Amount = parseFloat(dataValue.needs_analysis[res.key] || 0)
    }
  })
  let finalArr = goalsTableData.concat(SavingsGoalsTableData)
  setProtectionNeeds(dataValue, finalArr, store)
}

/**
 * 1.4 赋值 1.12
 * Assumptions
 */
export function setRateFromFin (dataValue, commonData) { 
  // Death inflation_adjusted_return_rate_death
  // CI inflation_adjusted_return_rate_ilness
  // TPD inflation_adjusted_return_rate_disability
  // Retirement adjusted_rate_return
  if (commonData) {
    let ror = 1 + parseFloat(dataValue.financial_objectives.ror) / 100
    let annual = 1 + parseFloat(dataValue.financial_objectives.annual_growth_rate) / 100
    let adjusted = ((ror / annual - 1) * 100).toFixed(10)
    setTotalDeath(dataValue, adjusted)
    setTotalCI(dataValue, adjusted)
    setTotalTPD(dataValue, adjusted)
    setTotalRetirement(dataValue, adjusted)
  }
}

// 利率、支付总期数、定期支付额 1.96 10 2
function PV (rate, nper, pmt) {
  try {
    rate = parseFloat(rate || 0)
    nper = parseFloat(nper || 0)
    pmt = parseFloat(pmt || 0)
    let value = 0;
    if (rate == 0) {
      value = pmt * nper
    } else {
      value = (pmt / (rate / 100)) * (1 - Math.pow(1 + rate / 100, -nper) )
    }
    if (isNaN(value)) {
      return '0';
    }
    // Return number retains two decimal places
    return value.toFixed(10);
  } catch (e) {
    return '0';
  }
}

function setTotalDeath (dataValue, adjusted) {
  dataValue.needs_analysis.inflation_adjusted_return_rate_death = adjusted
  dataValue.needs_analysis.annual_growth_rate_death = dataValue.financial_objectives.annual_growth_rate
  dataValue.needs_analysis.ror_death = dataValue.financial_objectives.ror
  dataValue.needs_analysis.funds_required_death = PV(dataValue.needs_analysis.inflation_adjusted_return_rate_death, dataValue.needs_analysis.required_years_death, dataValue.needs_analysis.family_required_death)
  dataValue.needs_analysis.total_required_death = parseFloat(parseFloat(dataValue.needs_analysis.funds_required_death || 0) + parseFloat(dataValue.needs_analysis.add_total_liabilities || 0) + parseFloat(dataValue.needs_analysis.add_final_expenses || 0) + parseFloat(dataValue.needs_analysis.add_other_fund || 0)).toFixed(10)
  dataValue.needs_analysis.total_death = parseFloat(parseFloat(dataValue.needs_analysis.less_existing_life_insurance || 0) + parseFloat(dataValue.needs_analysis.less_other_funds_available || 0) - parseFloat(dataValue.needs_analysis.total_required_death || 0)).toFixed(10)
}

function setTotalCI (dataValue, adjusted) {
  dataValue.needs_analysis.inflation_adjusted_return_rate_ilness = adjusted
  dataValue.needs_analysis.annual_growth_rate_ilness = dataValue.financial_objectives.annual_growth_rate
  dataValue.needs_analysis.ror_ilness = dataValue.financial_objectives.ror
  dataValue.needs_analysis.maintain_lifestyle_sum_ilness = PV(dataValue.needs_analysis.inflation_adjusted_return_rate_ilness, dataValue.needs_analysis.required_years_ilness, dataValue.needs_analysis.sustain_ifestyle_required_ilness)
  dataValue.needs_analysis.estimated_amount_required_illness = parseFloat(parseFloat(dataValue.needs_analysis.maintain_lifestyle_sum_ilness || 0) + parseFloat(dataValue.needs_analysis.add_medical_cost || 0) + parseFloat(dataValue.needs_analysis.add_other_funding_needs || 0)).toFixed(10)
  dataValue.needs_analysis.total_ilness = parseFloat(parseFloat(dataValue.needs_analysis.less_existing_critical_illness_insurance || 0) - parseFloat(dataValue.needs_analysis.estimated_amount_required_illness || 0)).toFixed(10)
}

function setTotalTPD (dataValue, adjusted) {
  dataValue.needs_analysis.inflation_adjusted_return_rate_disability = adjusted
  dataValue.needs_analysis.annual_growth_rate_disability = dataValue.financial_objectives.annual_growth_rate
  dataValue.needs_analysis.ror_disability = dataValue.financial_objectives.ror
  dataValue.needs_analysis.maintain_lifestyle_sum_disability = PV(dataValue.needs_analysis.inflation_adjusted_return_rate_disability, dataValue.needs_analysis.required_years_disability, dataValue.needs_analysis.sustain_ifestyle_required_disability)
  dataValue.needs_analysis.total_need_disability = parseFloat(parseFloat(dataValue.needs_analysis.maintain_lifestyle_sum_disability || 0) + parseFloat(dataValue.needs_analysis.add_lump_sum_required || 0)).toFixed(10)
  dataValue.needs_analysis.total_disability = parseFloat(parseFloat(dataValue.needs_analysis.less_existing_disability_benefit || 0) + parseFloat(dataValue.needs_analysis.other_funds_available_disability || 0) - parseFloat(dataValue.needs_analysis.total_need_disability || 0)).toFixed(10)
}

function setTotalRetirement (dataValue, adjusted) {
  dataValue.needs_analysis.adjusted_rate_return = adjusted
  dataValue.needs_analysis.annual_growth_rate_retirement = dataValue.financial_objectives.annual_growth_rate
  dataValue.needs_analysis.ror_retirement = dataValue.financial_objectives.ror
  dataValue.needs_analysis.total_amount_required = PV(dataValue.needs_analysis.adjusted_rate_return, dataValue.needs_analysis.duration_of_retirement, dataValue.needs_analysis.first_year_income)
  dataValue.needs_analysis.total = parseFloat(parseFloat(dataValue.needs_analysis.less_total_asset || 0) + parseFloat(dataValue.needs_analysis.less_maturity_value || 0) + parseFloat(dataValue.needs_analysis.available_funds_meet_need || 0) - parseFloat(dataValue.needs_analysis.total_amount_required || 0)).toFixed(10)
}

/**
 * 所有节点依次赋值  .state.KYC.kycAllData
 * 主
 */
export function setAllKYC (store, kycPageItem, commonData) {
  let kycAllData = store.state.KYC.kycAllData;
  let dataValue = kycAllData.kyc.client[store.state.KYC.kycClient]
  switch (kycPageItem) {
    case 2:
      // 1.3 赋值 1.4
      getDataFromDep(dataValue)
      // 1.4 赋值 1.12
      getDataFromFin(dataValue)
      getOtherDataFromFin(dataValue)
      getPlanningFromFin(dataValue)
      break;
    case 3:
      // 1.4 赋值 1.12
      getDataFromFin(dataValue)
      getOtherDataFromFin(dataValue)
      getPlanningFromFin(dataValue)
      // 1.4  rate 赋值 2.2
      // commonData 值为true或false
      setRateFromFin(dataValue, commonData)
      break;
    case 11:
      // 1.12 赋值 2.2
      setProtectionNeeds(dataValue, commonData, store)
    case 13:
      // 2.2进入页面时赋值needs
      setRealNeeds(dataValue, store)
    default:
      break;
  }
  store.commit('SET_KYC_ALL_DATA', kycAllData)
}

/**
 * @Description: KYC节点判断是否填写完成
 * @Date: 2022-9-22
 * kycAllData与kycRequired比较，key相同且kycAllData值不为空，则填写完成
 * 输出：NotCompletedData： [
 *        {
            node: 'Personal Information',
            data: ['mailing adress', 'country', 'Zip code'] //未完成节点
          }
 *      ]
 * 主
 */
export function WhetherCompleted (store) {
  let kycAllData = store.state.KYC.kycAllData;
  let dataValue = kycAllData.kyc.client[store.state.KYC.kycClient]
  let NotCompletedData = []
  Object.keys(global.kycRequired).map(key => {
    let obj = {
      node: '',
      data: []
    }
    Object.entries(global.kycRequired[key]).map(requireKey => {
      switch (key) {
        case 'personal_information':
          checkpersonal(obj, dataValue.personal_information, requireKey)
          break;
        case 'trusted_individual':
          checktrusted(obj, dataValue.trusted_individual, requireKey)
          break;
        case 'dependants_list':
          checkdependants(obj, dataValue.dependants_list, requireKey)
          break;
        case 'financial_objectives':
          checkfinancial(obj, dataValue.financial_objectives, requireKey)
          break;
        case 'assets_liabilities':
          checkassets(obj, dataValue.assets_liabilities, requireKey)
          break;
        case 'cashflow':
          checkCashflow(obj, dataValue.cashflow, requireKey)
          break;
        case 'existing_investments_insurance':
          checkExistingInvestments(obj, dataValue.existing_investments_insurance, requireKey)
          break;
        case 'budget':
          checkBudget(obj, dataValue.budget, requireKey)
          break;
        case 'customer_knowledge_assessment':
          checkCustomerKnowledge(obj, dataValue.customer_knowledge_assessment, requireKey)
          break;
        case 'risk_profile_questionnaire':
          checkRiskProfile(obj, dataValue.risk_profile_questionnaire, requireKey)
          break;
        case 'accredited_investor_declaration':
          checkAccredited(obj, dataValue.accredited_investor_declaration, requireKey)
          break;
        case 'needs_analysis':
          checkneeds(obj, dataValue.needs_analysis, requireKey, dataValue.financial_objectives)
          break;
        case 'basis_recommendations':
          checkBasisRecommendations(obj, dataValue.basis_recommendations, requireKey)
          break;
        case 'switching_replacement_policy':
          checkSwitchingPolicy(obj, dataValue.switching_replacement_policy, requireKey)
          break;
        case 'client_acknowledgement_declaration':
          checkClientAcknowledgement(obj, dataValue.client_acknowledgement_declaration, requireKey)
          break;
      }
    })
    NotCompletedData.push(obj)
  })
  return NotCompletedData
}

import func from '@/common/js/module/operate.js'
function checkpersonal (obj, data, requireKey) {
  obj.node = '1.1 Personal Information'
  obj.pageItem = 0
  if (requireKey[0] === 'nric_passport_fin' && data.identity_type !== 'Passport') {
    if (!func.validateIC(data.nric_passport_fin)) obj.data.push('The form of NRIC/Passport is not correct')
  } else if (requireKey[0] === 'race_content') {
    if (data.race === 'Others' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'language_spoken_content') {
    if (data.language_spoken === 'Others' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'language_writen_content') {
    if (data.language_writen === 'Others' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'employment_status_content') {
    if (data.employment_status === 'Others' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'employer' || requireKey[0] === 'employment_country' || requireKey[0] === 'employment_zip_code') {
    if (data.employment_status !== 'Retired' && data.employment_status !== 'Not employed' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'heath_condition_description') {
    if (data.heath_condition === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  }else if (requireKey[0] === 'marial_status') {
    if (data.marial_status === '' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]] && data[requireKey[0]] !== undefined) obj.data.push(requireKey[1])
  }
}
function checktrusted (obj, data, requireKey) {
  obj.node = '1.2 Trusted Individual'
  obj.pageItem = 1
  // 选中2或者3条时，需要进行以下判断
  let count = 0
  if (data.is_old === 'Y') count += 1
  if (data.is_gce_no === 'Y') count += 1
  if (data.is_not_proficient_english === 'Y') count += 1
  if (count > 1) {
    if (requireKey[0] === 'is_not_accompanied_reason') {
      if (data.is_accompanied == 'N' && !data[requireKey[0]]) obj.data.push(requireKey[1])
    } else if (requireKey[0] === 'trusted_individual_content_number' || requireKey[0] === 'trusted_individual_relationship' ||
      requireKey[0] === 'trusted_individual' || requireKey[0] === 'trusted_individual_nricnumber' || requireKey[0] === 'trusted_individual_area') {
      if (data.is_accompanied == 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
    } else if ( requireKey[0] === 'is_accompanied'){
      if (!data[requireKey[0]]) obj.data.push(requireKey[1])
    }
  }
}
function checkdependants (obj, data, requireKey) {
  obj.node = '1.3 Dependants'
  obj.pageItem = 2
  if (requireKey[0] === 'reason') {
    if (data.is_dependants == 'N' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'dependants_details') {
    if (data.is_dependants == 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}
function checkfinancial (obj, data, requireKey) {
  obj.node = '1.4 Financial Objectives'
  obj.pageItem = 3
  if (requireKey[0] === 'dependants_financial_objectives') {
    // 1.3 dependants
    data.dependants_financial_objectives.map(res => {
      if (!res.dependant_financial_objectives_value) obj.data.push(res.dependant_name)
    })
  } else if (requireKey[0] === 'other_financial_objectives') {
    // 手动输入
    data.other_financial_objectives.map(res => {
      if (!res.field_value) obj.data.push(res.field_key)
    })
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}
function checkassets (obj, data, requireKey) {
  obj.node = '1.5 Assets & Liabilities'
  obj.pageItem = 4
  if (requireKey[0] === 'no_provide_reason') {
    if (data.is_provide_cash_assets_liabilities === 'N' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'is_assets_change_dramatically') {
    if (data.is_provide_cash_assets_liabilities === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'assets_change_dramatically_detail') {
    if (data.is_assets_change_dramatically === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

function checkCashflow (obj, data, requireKey) {
  obj.node = '1.6 Cashflow'
  obj.pageItem = 5
  if (requireKey[0] === 'no_provide_reason') {
    if (data.is_provide_cash_flow === 'N' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'is_income_expenditure_change_dramatically') {
    if (data.is_provide_cash_flow === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'income_expenditure_change_details') {
    if (data.is_income_expenditure_change_dramatically === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

function checkExistingInvestments (obj, data, requireKey) {
  obj.node = '1.7 Existing Investments & Insurance'
  obj.pageItem = 6
  if (requireKey[0] === 'no_consideration_reson') {
    if (data.is_consideration_exist_portfolio === 'N' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'existing_investments' || requireKey[0] === 'existing_policies') {
    // 不可以都为空
    if (data.is_consideration_exist_portfolio === 'Y' && data.existing_investments == false && data.existing_policies == false && !obj.data.includes(requireKey[1])) obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

function checkBudget (obj, data, requireKey) {
  obj.node = '1.8 Budget'
  obj.pageItem = 7
  if (requireKey[0] === 'total_annual' || requireKey[0] === 'total_single') {
    // 不可以都为空
    if ((isNaN(parseFloat(data.total_annual)) || parseFloat(data.total_annual) == 0) && (isNaN(parseFloat(data.total_single)) || parseFloat(data.total_single) == 0) && !obj.data.includes(requireKey[1])) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'deviation_reason') {
    if (!data[requireKey[0]] && data.is_reserve_half_assets === 'Y') obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

function checkCustomerKnowledge (obj, data, requireKey) {
  obj.node = '1.9 Customer Knowledge Assessment'
  obj.pageItem = 8
  if (requireKey[0] === 'assessment_outcome' && (!data[requireKey[0]])) {
    obj.data.push(requireKey[1])
  }
}

function checkRiskProfile (obj, data, requireKey) {
  obj.node = '1.10 Risk Profile Questionnaire'
  obj.pageItem = 9
  if (requireKey[0] === 'grade' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  if (requireKey[0] === 'own_choice' && data.is_agree_profile === 'N' && !data[requireKey[0]]) obj.data.push(requireKey[1])
}

function checkAccredited (obj, data, requireKey) {
  obj.node = '1.11 Accredited Investor Declaration'
  obj.pageItem = 10
  if (requireKey[0] === 'net_personal_assets' || requireKey[0] === 'financial_assets' || requireKey[0] === 'income') {
    if (data.is_accredited_investor === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

function checkneeds (obj, data, requireKey, financialObj) {
  obj.node = '1.12 Need Analysis'
  obj.pageItem = 11
  if (obj.data.length == 0) {
    if (requireKey[0] == 'total_death' && data[requireKey[0]] == 0 && financialObj.income_protection_death == 'H' ||
      requireKey[0] == 'total_disability' && data[requireKey[0]] == 0 && financialObj.income_protection_disability == 'H' ||
      requireKey[0] == 'total_ilness' && data[requireKey[0]] == 0 && financialObj.critical_illness == 'H' ||
      requireKey[0] == 'plan_total_amount_outpatient' && data[requireKey[0]] == 0 && financialObj.outpatient_medical_expenses_protection == 'H' ||
      requireKey[0] == 'total_amount_plan_dental' && data[requireKey[0]] == 0 && financialObj.dental_expenses_protection == 'H' ||
      requireKey[0] == 'total_accident' && data[requireKey[0]] == 0 && financialObj.personal_accident == 'H' ||
      requireKey[0] == 'total_amount_ltc' && data[requireKey[0]] == 0 && financialObj.long_term_care == 'H' ||
      requireKey[0] === 'plan_dependant_protection' && data.plan_dependant_protection.dependants_total_all == 0 && financialObj.dependant_protection_planning == 'H' ||
      requireKey[0] == 'total' && data[requireKey[0]] == 0 && financialObj.retirement_needs == 'H'
    ) {
      if(!data.additional_notice){
        obj.data.push('Please enter Notes')
      }
    }
    if (requireKey[0] == 'Medical' && financialObj.medical_hospitalisation_costs == 'H') {
      Object.keys(requireKey[1]).map(Medical => {
        let index = 0
        // 全部字段中只要有不是空值的就递增index，当index=0，就代表全都是空，就判定没有进行分析
        if (data[Medical]) {
          index++
          if (index == 0  && obj.data.length == 0) {
            if(!data.additional_notice){
              obj.data.push('Please enter Notes')
            }
          }
        }
      })
    }
    if (requireKey[0] === 'protection_goals_others' || requireKey[0] === 'saving_accumulation_goals_others') {
      data[requireKey[0]].map(otherProtectionORWealth => {
        financialObj.other_financial_objectives.map(res => {
          if (res.dynamic_field_order == otherProtectionORWealth.index && res.field_value == 'H' && otherProtectionORWealth.total == 0) {
            if(!data.additional_notice){
              obj.data.push('Please enter Notes')
            }
          }
        })
      })
    }
    if (requireKey[0] == 'education_fund' && data[requireKey[0]]) {
      console.log(data[requireKey[0]], 'data[requireKey[0]]');
      data[requireKey[0]].map(education => {
        if (education.dependant_id == 'myself' && financialObj.myself_education_fund == 'H' && education.total == 0) {
          if(!data.additional_notice){
            obj.data.push('Please enter Notes')
          }
        } else {
          financialObj.dependants_financial_objectives.map(res => {
            if (res.dependant_id == education.dependant_id && res.dependant_financial_objectives_value == 'H' && education.total == 0) {
              if(!data.additional_notice){
                obj.data.push('Please enter Notes')
              }
            }
          })
        }
      })
    }
  }
}

function checkBasisRecommendations (obj, data, requireKey) {
  obj.node = '2.2 Product Recommendations'
  obj.pageItem = 13
  if (requireKey[0] === 'deviation_reason') {
    if (data.is_deviation === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'product_recommendations_option') { 
    if (data[requireKey[0]].length === 0) {
      obj.data.push(requireKey[1])
    } else {
      // 至少选中一个产品
      let temp = false
      data[requireKey[0]].map(res => {
        res.product_list.map(product => {
          if (product.is_select_product === 'Y') temp = true
          if (!product.recommendation_reason && !obj.data.includes('Please fill in the reasons for product recommendation')) obj.data.push('Please fill in the reasons for product recommendation')
        })
      })
      if (!temp) obj.data.push(requireKey[1])
    }
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

function checkSwitchingPolicy (obj, data, requireKey) {
  obj.node = '3.1 Switching / Replacement Policy'
  obj.pageItem = 14
  if (requireKey[0] === 'is_advise_replace_policy' || requireKey[0] === 'is_explained' || requireKey[0] === 'replacement_declaration') {
    if (data.is_purchase === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'products') {
    data.products.map(pro => {
      if (data.is_purchase === 'Y' && pro.checked === 'Y' && !pro.reason) {
        obj.data.push(pro.product_name)
      }
    })
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

function checkClientAcknowledgement (obj, data, requireKey) {
  obj.node = '3.2 Client Acknowledgement and Declaration'
  obj.pageItem = 15
  if (requireKey[0] === 'wish_amendment') {
    if (data.is_agree === 'N' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'is_experience') {
    if (data.is_investment_linked_plan === 'Y' && !data[requireKey[0]]) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'is_aware_ilp_suitability') {
    if (data.is_investment_linked_plan === 'Y' && (!data.is_aware_ilp_suitability || !data.is_understand_contacting_income || !data.is_understand_approval)) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'is_recommended_plan_nature') {
    // 只选择一项即可
    if (!data.is_recommended_plan_nature && !data.is_my_profile && !data.is_contractual_rights && !data.is_recommended_plan_price
      && !data.is_commitment_required && !data.is_free_look_provision && !data.is_fees_charges && !data.is_warnings_exclusion_disclaimers
      && !data.is_surrender_claim_procedures && !data.is_recommended_plan_fbrl && !data.is_plan_provider_details && !data.is_insurance_fuide
      && !data.is_available_policy_document) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'beneficial_owner_name') {
    // 7 需要全部有值
    if (data.is_beneficial_ownership_arrangement === 'Y' && (!data.beneficial_owner_name || !data.owner_nric_passportno_fin || !data.owner_birth || !data.owner_gender
      || !data.owner_proposer_relationship || !data.owner_nationality_type)) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'pep_name') {
    // 8 需要全部有值
    if (data.is_pep === 'Y' && (!data.pep_name || !data.pep_title || !data.pep_related_person_name || !data.pep_relationship)) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'payor_name') {
    // 9 需要全部有值
    if (data.is_proposer_payor === 'N' && (!data.payor_name || !data.payor_nric_passportno_fin || !data.payor_occupation || !data.pay_reason
      || !data.payor_proposer_relationship || (data.payor_proposer_relationship == 'Others' && !data.payor_others_name)
    )) obj.data.push(requireKey[1])
  } else if (requireKey[0] === 'fund_source_inheritance') {
    // 10只选择一项即可
    if (!data.fund_source_inheritance && !data.fund_source_policy_proceeds && !data.fund_source_sale_asset && !data.fund_source_others
      && !data.fund_source_salary_commission && !data.fund_source_personal_savings) obj.data.push(requireKey[1])
  } else {
    if (!data[requireKey[0]]) obj.data.push(requireKey[1])
  }
}

/**
 * @Description: KYC2.2节点判断是否基金总和是否等于main plan 获取提示--判断金额/百分比
 * @Date: 2022-11-17
 */
export function getSystemPrompts (val, store, confirm) {
  let result = true
  let proposal = val.proposal
  let product = val.product
  let dataValue = store.state.KYC.kycAllData.kyc.client[store.state.KYC.kycClient]
  let productobj = dataValue.basis_recommendations.product_recommendations_option[proposal].product_list[product]
  if (productobj.fund_list.length === 0) return result
  // CIS判断方法：子基金Lump sum amount之和=主产品 && 子基金RIS之和=主产品
  if (productobj.product_line_code === 'CI') {
    let lumpsum = 0
    let ris = 0
    productobj.fund_list.map(res => {
      // benefit_amount
      lumpsum += parseFloat(res.amount || 0)
      // premium_amount
      ris += parseFloat(res.type_account || 0)
    })

    if (lumpsum != productobj.benefit_amount || ris != productobj.premium_amount) {
      result = false
      confirm('The Amount for Lump Sum / Regular Investment Scheme (RIS) of the added fund(s) is inconsistent with the Main Product Amount. Please update.', productobj.product_name, {
        showConfirmButton: false,
        cancelButtonText: 'Confirm'
      }).then().catch(() => { })
    }
  } else if (productobj.product_type_name === 'ILP') {
    // ILP判断方法：子基金Percentage Allocation of RSP之和=100
    let RSPPer = 0
    productobj.fund_list.map(res => {
      // Percentage
      RSPPer += parseFloat(res.percentage_allocation || 0)
    })
    if (RSPPer != 100) {
      result = false
      // %不足100提示
      confirm("The total sum of selected fund products' 'Percentage Allocation for RSP' is 100%.", productobj.product_name, {
        showConfirmButton: false,
        cancelButtonText: 'Confirm'
      }).then().catch(() => { })
    }
  }
  localStorage.removeItem('systemPrompts')
  return result
}