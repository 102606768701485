<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Education fund for {{navigationName}}</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="Education Fund">
				</el-table-column>
				<el-table-column prop="Amount" label="Annual Amount" width="400">
					<template slot-scope="scope">
						<el-input v-if="scope.row.type === 'disabled'" v-model="scope.row.AmountShow" disabled></el-input>
						<el-input v-else-if="scope.row.type === 'num'" type="number" v-model="scope.row.Amount"></el-input>
						<el-input v-else-if="scope.row.type === 'str'" v-model="scope.row.Amount"></el-input>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :ifAmount="scope.row.ifAmount" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr">
						</AmountInput>
					</template>
				</el-table-column>
			</el-table>
			<!-- total -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total(surplus/shortfall) S$ <span style="color: black;">{{$formatMoney(totalAccident)}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';

export default {
	components: { Navbar, AmountInput },
	data () {
		return {
			assetsTableData: [
				{
					Amount: '0',
					name: 'Current age',
					value: 'current_age',
					type: 'num'
				}, {
					Amount: '0',
					name: 'Cost of education today (PV)',
					value: 'education_cost_today',
				}, {
					Amount: '0',
					name: 'Country of Study',
					value: 'country_study',
					type: 'str'
				}, {
					Amount: '0',
					name: 'Number of years to save',
					value: 'save_years_number',
					type: 'num'
				}, {
					Amount: '0',
					name: 'Average education inflation rate',
					value: 'average_education_inflation_rate',
					ifAmount: false
				}, {
					Amount: '0',
					name: 'Total cost of education at entry age',
					value: 'total_cost',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Less: Maturity value of insurance policies',
					value: 'less_maturity_value',
				}, {
					Amount: '0',
					name: 'Less: Value of funds to be used',
					value: 'less_used_value',
				}
			],
			navigationName: '',
			kycAllData: {}
		};
	},
	computed: {
		total () {
			let num2, num3, num4, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'less_maturity_value') num2 = parseFloat(res.Amount || '0')
				if (res.value === 'less_used_value') num3 = parseFloat(res.Amount || '0')
				if (res.value === 'total_cost') num4 = parseFloat(res.Amount || '0')
			})
			sum = parseFloat(num2 + num3 - num4).toFixed(10)
			return sum
		},
		totalAccident () {
			return parseFloat(this.total).toFixed(2)
		},
		total_cost () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'education_cost_today') num1 = parseFloat(res.Amount || '0')
				if (res.value === 'average_education_inflation_rate') num2 = parseFloat(res.Amount || '0')
				if (res.value === 'save_years_number') num3 = parseFloat(res.Amount || '0')
			})
			sum = (num1 * Math.pow(1 + num2 / 100, num3)).toFixed(10)
			return sum
		}
	},
	watch: {
		total_cost (val) {
			this.assetsTableData.map(res => {
				if (res.value === 'total_cost' && !isNaN(val)) {
					res.Amount = val
					res.AmountShow = 'S$ ' + parseFloat(val).toFixed(2)
				}
			})
		},
	},
	methods: {
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.education_fund.map(n => {
				if (n.dependant_id === this.$router.currentRoute.query.id) {
					this.assetsTableData.map(res => { n[res.value] = res.Amount })
					n.total = this.total
				}
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.education_fund.filter(n => n.dependant_id === this.$router.currentRoute.query.id)[0]
		this.navigationName = dataValue0.dependant_name
		// 若有值 则赋值
		this.assetsTableData.map((key, i) => {
			key.Amount = key.type === 'str' ? dataValue0[key.value] : dataValue0[key.value] || 0
			let show = this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.AmountShow = key.ifAmount === false ? show + ' %' : 'S$ ' + show
			key.index = i
		})
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
</style>