import Vue from 'vue'
import VueRouter from 'vue-router'
import KYC from './kcy'
import Search from './search'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/index.vue')
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login')
  }, {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: () => import('../views/Login/ResetPassword.vue')
  }, {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('../views/Product')
  }, {
    path: '/myClient',
    name: 'myClient',
    component: () => import('../views/My')
  }, {
    path: '/MyDataDetail',
    name: 'MyDataDetail',
    component: () => import('../views/My/MyDataDetail.vue')
  }, {
    path: '/Notifications',
    name: 'Notifications',
    component: () => import('../views/Notifications')
  }, {
    path: '/PendingTasks',
    name: 'PendingTasks',
    component: () => import('../views/PendingTasks')
  }, {
    path: '/PendingTasksDetail',
    name: 'pendingTasksDetail',
    component: () => import('../views/PendingTasks/pendingTasksDetail.vue')
  }, {
    path: '/Preview',
    name: 'Preview',
    component: () => import('../views/KYC/components/PreviewPdf.vue')
  },
  // 挂载媒介
  ...KYC,
  ...Search,
]

const router = new VueRouter({
  routes,
  // mode: 'history',
  // base: '/infp/',
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
