<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Medical and hospitalisation costs</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="Medical and hospitalisation costs">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<el-select v-model="scope.row.Amount" @change="handlerChange(scope.row)" v-if="scope.row.type && scope.row.type === 'select'" :disabled="scope.row.disabled" placeholder="" no-data-text="No Data">
							<el-option v-for="item in scope.row.options" :key="item.code" :value="item.code" :label="item.name"></el-option>
						</el-select>
						<el-input v-else v-model="scope.row.Amount" />
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import Navbar from '../../../../components/Navbar.vue';

export default {
	components: { Navbar },
	data () {
		return {
            assetsTableData: [
				{
					Amount: '',
					name: 'Amount required by your dependants (today’s value)',
					value: 'dependants_required_medical',
				}, {
					Amount: '',
					name: 'Existing Hospital and Surgical Coverage',
					value: 'hospital_surgical_coverage',
				}, {
					Amount: '',
					name: 'Daily room and board (R&B) charges',
					value: 'daily_room_board',
				}, {
					Amount: '',
					name: 'Estimated surgical expenses',
					value: 'surgical_expenses',
				}, {
					Amount: '',
					name: 'Hospital income required',
					value: 'hospital_income_required',
				}, {
					Amount: '',
					name: 'Preferred hospital type',
					value: 'preferred_hospital_type',
					type: 'select',
					options: global.hospital
				}, {
					Amount: '',
					name: 'Preferred ward type',
					value: 'preferred_ward_type',
					type: 'select',
					options: global.governmentOtherBenefit,
					disabled: true
				}, {
					Amount: '',
					name: 'Other benefit',
					value: 'other_benefit',
				}, {
					Amount: '',
					name: 'Rider for deductible & Co-Insurance',
					value: 'ride',
					type: 'select',
					options: global.yesOrNo

				}
			],
			kycAllData: {}
		};
	},
	computed: {},
	watch: {},
	methods: {
		handlerChange (val) {
			if (val.value === 'preferred_hospital_type') {
				this.assetsTableData[6].disabled = val.Amount !== 'Government'
			}
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.assetsTableData.map(res => { this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis[res.value] = res.Amount })
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		// 若有值 则赋值
		this.assetsTableData.map(key =>  key.Amount = dataValue0.needs_analysis[key.value] )
		this.assetsTableData[6].disabled = this.assetsTableData[5].Amount !== 'Government'
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
</style>