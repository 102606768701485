<template>
	<!-- 2.2节点 preview proposal页面、search 行点击事件 detail展示 -->
	<div class='h100'>
		<div class="p30px h80" :style="isDisabled ? '' : {overflow: 'scroll'}">
			<el-tabs v-model="activeName" :stretch=true class="my-tab">
				<el-tab-pane v-for="(item, index) in product" :key="index" :label="'Proposal' + (index + 1)" :name="'Proposal' + (index + 1)">
				</el-tab-pane>
			</el-tabs>
			<!-- 各tab页对应的具体页面 -->
			<div class="p10px">
				<template v-if="isDisabled">
					<el-table :data="product[currentTab].protection_needs_setup" style="width: 100%">
						<el-table-column prop="protection_name" label="Protection for myself">
							<template slot-scope="scope">
								<span>{{scope.row.protection_name}}</span>
								<span v-if="scope.row.dependant_name">: {{scope.row.dependant_name}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="value" label="Shortfall/surplus" width="200">
							<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
								S$ {{$formatMoney(scope.row.total)}}
							</template>
						</el-table-column>
						<el-table-column prop="years" label="Time horizon" width="200">
							<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
								{{scope.row.time_horizon}} year(s)
							</template>
						</el-table-column>
						<el-table-column prop="" label="Filter" width="200" align="center">
							<template slot-scope="scope">
								<CheckBox :disabled="isDisabled" :label="scope.$index" :singleChecked.sync="scope.row.is_select_type" :max="scope.$index"></CheckBox>
							</template>
						</el-table-column>
					</el-table>
				</template>
				<template v-else>
					<MyTitle Title="Financial goals" class="mt20px mb20px" />
					<el-table :data="FinancialTableData.product_for_myself" style="width: 100%">
						<el-table-column prop="protection_name" label="Protection for myself">
							<template slot-scope="scope">
								<p>{{scope.row.protection_name}}</p>
							</template>
						</el-table-column>
						<el-table-column prop="value" label="Shortfall/surplus" width="200">
							<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
								S$ {{$formatMoney(scope.row.total)}}
							</template>
						</el-table-column>
						<el-table-column prop="years" label="Time horizon" width="200">
							<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
								{{scope.row.time_horizon}} year(s)
							</template>
						</el-table-column>
					</el-table>
					<!-- Protection for dependants -->
					<el-table :data="FinancialTableData.product_for_dependants" style="width: 100%">
						<el-table-column prop="protection_name" label="Protection for dependants">
							<template slot-scope="scope">
								<span>{{scope.row.protection_name}}</span>
								<span v-if="scope.row.dependant_name">: {{scope.row.dependant_name}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="value" label="Shortfall/surplus" width="200">
							<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
								S$ {{$formatMoney(scope.row.total)}}
							</template>
						</el-table-column>
						<el-table-column prop="years" label="Time horizon" width="200">
							<template slot-scope="scope" v-if="scope.row.protection_needs !== '624' && scope.row.protection_needs !== 'Premium Waiver'">
								{{scope.row.time_horizon}} year(s)
							</template>
						</el-table-column>
					</el-table>
				</template>
				<!-- Plan/Rider -->
				<el-table :data="[]" style="width: 100%">
					<el-table-column prop="product_name" label="Plan/Rider" />
					<el-table-column prop="product_for" label="Insured" width="150" />
					<el-table-column prop="benefit_amount" label="Sum Assured/ Benefit" width="200" />
					<el-table-column prop="policy_term" label="Policy Term" width="150" />
					<el-table-column prop="premium_amount" label="Premium/Investment" width="150" />
					<!-- 选择 -->
					<el-table-column prop="" label="Client's selection" width="200" align="center" />
				</el-table>
				<div v-for="(product, i) in FinancialTableData.product_list" :key="'product' + i">
					<div class="displayFlex" :style="isDisabled ? {padding: '20px 0 20px 10px', borderBottom: '1px solid'} :{ padding: '20px 0 20px 10px'}">
						<div class="displayFlex-1" @click="routerPush('mainplan', i)">
							<p class="company" style="margin: 5px 0;">{{product.company}}</p>
							<p style="color: #3C3C3C">{{product.product_name}}</p>
							<!-- 颜色 -->
							<span v-for="need in product.need" :key="need">
								<div class="circle_small" :style="{ background: getColor(need)}"></div>
							</span>
						</div>
						<p class="tableContent" style="width: 150px;text-align: left;">{{getNameByCode(product.product_for)}}</p>
						<p class="tableContent" style="width: 200px;">{{product.benefit_amount}}</p>
						<p class="tableContent" style="width: 150px;">{{product.policy_term}}</p>
						<div class="displayFlex flexColumn flexAlignCenter">
							<p class="tableContent company" style="width: 150px;line-height: 24px;">{{product.premium_mode}}</p>
							<p>{{product.premium_amount}}</p>
						</div>
						<CheckBox :label="'product' + i" :disabled="isDisabled" :singleChecked.sync="product.is_select_product" style="width: 200px;text-align: center;" v-if="product.is_recommendation === 'Y'" :key="activeName + 'product'"></CheckBox>
						<i @click="deleteProduct(i)" class="el-icon-delete" v-else style="color: #f3a246;width: 200px;text-align: center;"></i>
					</div>
					<!-- Rider -->
					<div v-for="(rider, index) in product.rider_list" :key="'rider' + index">
						<div class="displayFlex" :style="isDisabled ? {padding: '20px 0 20px 10px', borderBottom: '1px solid'} :{ padding: '20px 0 20px 10px'}">
							<div class="displayFlex-1" @click="routerPush('rider', i, index)">
								<p class="company" style="margin: 5px 0;">{{rider.company}}</p>
								<p style="color: #3C3C3C">{{rider.product_name}}</p>
								<!-- 颜色 -->
								<span v-for="need in rider.need" :key="need">
									<div class="circle_small" :style="{ background: getColor(need)}"></div>
								</span>
							</div>
							<p class="tableContent" style="width: 150px;"></p>
							<p class="tableContent" style="width: 200px;">{{rider.benefit_amount}}</p>
							<p class="tableContent company" style="width: 150px;">{{rider.policy_term}}</p>
							<div class="displayFlex flexColumn flexAlignCenter">
								<p class="tableContent company" style="width: 150px;line-height: 24px;">{{rider.premium_mode}}</p>
								<p>{{rider.premium_amount}}</p>
							</div>
							<CheckBox :label="'rider' + index" :disabled="isDisabled" :singleChecked.sync="rider.is_select_product" style="width: 200px;text-align: center;" v-if="rider.is_recommendation === 'Y'" :key="activeName + 'rider'"></CheckBox>
							<i @click="deleteRider(i, index)" class="el-icon-delete" v-else style="color: #f3a246"></i>
						</div>
						<!-- 灰色分割线 -->
						<p style="border: 1px solid #DBD8DD;"></p>
					</div>
				</div>

				<!-- Fund -->
				<el-table :data="[]" style="width: 100%">
					<el-table-column prop="product_name" label="Funds" />
					<el-table-column prop="product_for" label="Type of Account" width="160" />
					<el-table-column prop="benefit_amount" label="Lump Sum Amount/Lump Sum Amount/RSP Amount" width="200" />
					<el-table-column prop="policy_term" label="Percentage Allocation for RSP/Allocation of Premium (%)" width="150" />
					<el-table-column prop="premium_amount" label="Product Risk Classification" width="150" />
					<!-- 选择 -->
					<el-table-column prop="" label="Client's selection" width="200" align="center" />
				</el-table>
				<!-- 循环展示fund,上fund 下备注 -->
				<div v-for="(product, i) in FinancialTableData.product_list" :key="'product2' + i">
					<div v-for="(fund, index) in product.fund_list" :key="'fund' + index">
						<div class="displayFlex" :style="isDisabled ? {padding: '20px 0 20px 10px', borderBottom: '1px solid'} :{ padding: '20px 0 20px 10px'}">
							<div class="displayFlex-1" @click="routerPush('fund', i, index)">
								<p class="company" style="margin: 5px 0;">{{fund.plan_name}}</p>
								<p style="color: #3C3C3C">{{fund.fund_name}}</p>
								<!-- 颜色 -->
								<span v-for="need in fund.need" :key="need">
									<div class="circle_small" :style="{ background: getColor(need)}"></div>
								</span>
							</div>
							<p class="tableContent">{{fund.type_account}}</p>
							<p class="tableContent" style="width: 200px;">{{fund.amount}}</p>
							<p class="tableContent" style="width: 150px;">{{fund.percentage_allocation}}</p>
							<p class="tableContent" style="width: 150px;">{{fund.product_risk_rating}}</p>
							<CheckBox :label="'fund' + index" :disabled="isDisabled" :singleChecked.sync="fund.is_select" style="width: 200px;text-align: center;" v-if="fund.is_recommendation === 'Y'" :key="activeName + 'fund'"></CheckBox>
							<i @click="deleteFund(i, index)" class="el-icon-delete" v-else style="color: #f3a246"></i>
						</div>
						<el-input v-model="fund.remarks" placeholder="Remarks" v-if="!isDisabled" @input="fund.remarks = $util.inputReg(fund.remarks)"></el-input>
					</div>
				</div>
				<!-- Add client selected product -->
				<div class="TextAlginCenter p20px" @click="addProduct" :style="isDisabled ? {display: 'none'} : ''">
					<i class="el-icon-plus mr10px" style="color: #FF9941;font-weight: bold;font-size: 24px;"></i>
					<span>Add client selected product</span>
				</div>
				<!-- Basis of recommendation -->
				<div class="displayFlex flexAlignCenter h60px" :style="isDisabled ? {padding: '0px 40px 0 20px'} : {display: 'none'}">
					<MyTitle Title="Basis of recommendation" />
					<i @click="$router.push('/BasisOfRecommendation?proposal=' + currentTab + '&view')" class="el-icon-right pointer iStyle mlAuto"></i>
				</div>
			</div>
		</div>
		<choose-product ref="chooseProduct" @add_product_list="add_product_list"></choose-product>
	</div>
</template>

<script>
import CheckBox from "../../components/checkBox.vue";
import MyTitle from "../../components/MyTitle.vue";
import ChooseProduct from '../detail/ChooseProduct';

export default {
	components: { CheckBox, MyTitle, ChooseProduct },
	props: {
		isDisabled: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			activeName: 'Proposal1',
			currentTab: 0,
			FinancialTableData: {
				product_for_myself: [],
				product_for_dependants: [],
				product_list: []
			},
			product: {},
			kycAllData: {},
			dataValue: {},
			needsFor: []
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		activeName (val) {
			this.currentTab = parseInt(val.substr(-1)) - 1
			this.setValueForData(this.currentTab)
		},
		kycClient () {
			this.getData()
		},
	},
	methods: {
		getColor (needs) {
			return global.needs.find(item => item.code == needs).color
		},
		setValueForData (val) {
			this.FinancialTableData.product_for_dependants = []
			this.FinancialTableData.product_for_myself = []
			this.product[val].protection_needs_setup.map(needs => {
				if (needs.time_horizon == undefined) {
					needs.time_horizon = this.$getTimehorizon(needs, this.dataValue.needs_analysis)
				}
				if (needs.is_dependant && needs.is_dependant === 'Y') {
				// if (needs.dependant_name || needs.protection_name.includes(':') && !needs.protection_name.split(':')[1].includes('myself')) {
					this.FinancialTableData.product_for_dependants.push(needs)
				} else {
					this.FinancialTableData.product_for_myself.push(needs)
				}
			})
			this.FinancialTableData.product_list = this.product[val].product_list
		},
		// 是否选中回调事件
		changeStatus (val, i) {
			this.FinancialTableData.product_list.map((res, index) => {
				if (index === i) {
					res.is_select_product = val ? 'Y' : 'N'
				}
			})
		},
		// 添加产品
		addProduct () {
			this.$refs.proposalIndex = this.currentTab
			this.$refs.chooseProduct.Dialog.visible = true
		},
		// 添加产品 更新产品信息
		add_product_list (obj) {
			this.product[this.currentTab].product_list.push(obj)
			this.setValueForData(this.currentTab)
		},
		// 删除产品
		deleteProduct (val) {
			if (!this.isDisabled) this.FinancialTableData.product_list.splice(val, 1)
		},
		deleteFund (productIndex, fundIndex) {
			if (!this.isDisabled) this.FinancialTableData.product_list[productIndex].fund_list.splice(fundIndex, 1)
		},
		deleteRider (productIndex, riderIndex) {
			if (!this.isDisabled) this.FinancialTableData.product_list[productIndex].rider_list.splice(riderIndex, 1)
		},
		// index-产品下表 i-fund或rider下标
		routerPush (val, prodIndex, index) {
			if (!this.isDisabled) {
				if (val === 'fund') {
					this.$router.push('/FundView?proposal=' + this.currentTab + '&product=' + prodIndex + '&fund=' + index)
				} else if (val === 'mainplan') {
					this.$router.push('/MainPlanView?proposal=' + this.currentTab + '&product=' + prodIndex)
				} else if (val === 'rider') {
					this.$router.push('/RiderView?proposal=' + this.currentTab + '&product=' + prodIndex + '&rider=' + index)
				}
			}
		},
		getNameByCode (code) {
			return this.needsFor.filter(n => n.code == code)[0] ? this.needsFor.filter(n => n.code == code)[0].name : ''
		},
		getData () {
			this.dataValue = this.kycAllData.kyc.client[this.kycClient]
			this.product = this.dataValue.basis_recommendations.product_recommendations_option
			this.setValueForData(this.currentTab)
			this.needsFor = [{ code: 'myself', name: 'Myself' }]
			// 根据1.3节点 判断是否有依赖人
			if (this.dataValue.dependants_list.is_dependants === 'Y') {
				this.dataValue.dependants_list.dependants_details.map(res => {
					this.needsFor.push({ code: res.dependant_id, name: res.name })
				})
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getData()
		if (this.$router.currentRoute.name === 'Presentation' && this.$store.state.KYC.activeName) this.activeName = this.$store.state.KYC.activeName
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
}
</script>
<style lang='scss' scoped>
.company {
	font-size: 14px;
	color: #8b8b8b;
}
.circle_small {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin: 5px;
	display: inline-block;
}
.tableContent {
	box-sizing: border-box;
	width: 150px;
	padding-left: 10px;
	color: #606266;
	line-height: 40px;
	text-align: center;
}
.el-input /deep/ .el-input__inner {
	background-color: #fdf8e5;
}
</style>