
global.gender = [
    { code: 'Male', name: 'Male' },
    { code: 'Female', name: 'Female' }
],

    global.relationship = [
        { code: 'Parent', name: 'Parent ' },
        { code: 'Spouse', name: 'Spouse' },
        { code: 'Child', name: 'Child' },
        { code: 'Others', name: 'Others' }
    ],

    global.title = [
        { code: 'Dr', name: 'Dr ' },
        { code: 'Mr', name: 'Mr' },
        { code: 'Child', name: 'Child' },
        { code: 'Others', name: 'Others' },
        { code: 'Others', name: 'Others' }
    ],

    global.identityType = [
        { code: 'NRIC', name: 'NRIC' },
        { code: 'Passport', name: 'Passport' },
        { code: 'FIN', name: 'FIN' }
    ],

    global.nationality = [
        { code: 'Singaporean', name: 'Singaporean' },
        { code: 'Singaporean PR', name: 'Singaporean PR' },
        { code: 'Others', name: 'Others' }
    ],

    global.race = [
        { code: 'Chinese', name: 'Chinese' },
        { code: 'Malay', name: 'Malay' },
        { code: 'Indian', name: 'Indian' },
        { code: 'Others', name: 'Others' }
    ],

    global.spokenLanguage = [
        { code: 'English', name: 'English' },
        { code: 'Mandarin', name: 'Mandarin' },
        { code: 'Malay', name: 'Malay' },
        { code: 'Tamil', name: 'Tamil' },
        { code: 'English & Mandarin', name: 'English & Mandarin' },
        { code: 'English & Malay', name: 'English & Malay' },
        { code: 'English & Tamil', name: 'English & Tamil' },
        { code: 'Others', name: 'Others' }
    ],

    global.writtenLanguage = [
        { code: 'English', name: 'English' },
        { code: 'Chinese', name: 'Chinese' },
        { code: 'Malay', name: 'Malay' },
        { code: 'Tamil', name: 'Tamil' },
        { code: 'English & Chinese', name: 'English & Chinese' },
        { code: 'English & Malay', name: 'English & Malay' },
        { code: 'English & Tamil', name: 'English & Tamil' },
        { code: 'Others', name: 'Others' }
    ],

    global.cachCpfSrs = [
        { code: 'Cash', name: 'Cash' },
        { code: 'CPF', name: 'CPF' },
        { code: 'SRS', name: 'SRS' },
        { code: 'Others', name: 'Others' }
    ],

    global.payment = [
        { code: 'Cash', name: 'Cash' },
        { code: 'CPF(OA)', name: 'CPF(OA)' },
        { code: 'CPF(SA)', name: 'CPF(SA)' },
        { code: 'SRS', name: 'SRS' }
    ],

    global.writtenLanguage = [
        { code: 'English', name: 'English' },
        { code: 'Chinese', name: 'Chinese' },
        { code: 'Malay', name: 'Malay' },
        { code: 'Tamil', name: 'Tamil' },
        { code: 'English & Chinese', name: 'English & Chinese' },
        { code: 'English & Malay', name: 'English & Malay' },
        { code: 'English & Tamil', name: 'English & Tamil' },
        { code: 'Others', name: 'Others' }
    ],

    global.marial = [
        { code: 'Please Select', name: 'Please Select' },
        { code: 'Married', name: 'Married' },
        { code: 'Divorced', name: 'Divorced' },
        { code: 'Single', name: 'Single' },
        { code: 'Widowed', name: 'Widowed' }
    ],

    global.educational = [
        { code: 'Primary', name: 'Primary' },
        { code: 'Secondary', name: 'Secondary' },
        { code: "GCE'O'/'N' level", name: "GCE'O'/'N' level" },
        { code: 'Pre-U/JC', name: 'Pre-U/JC' },
        { code: 'Diploma', name: 'Diploma' },
        { code: 'Degree', name: 'Degree' },
        { code: 'Post graduate', name: 'Post graduate' }
    ],

    global.employmentStatus = [
        { code: 'Full Time', name: 'Full Time' },
        { code: 'Part Time', name: 'Part Time' },
        { code: 'Self Employed', name: 'Self Employed' },
        { code: 'Others', name: 'Others' },
        { code: 'Retired', name: 'Retired' },
        { code: 'Not employed', name: 'Not Employed' }
    ],


    global.premiumMode = [
        { code: 'Monthly', name: 'Monthly' },
        { code: 'Quarterly', name: 'Quarterly' },
        { code: 'Semi-Annually', name: 'Semi-Annually' },
        { code: 'Annually', name: 'Annually' },
        { code: 'Single Premium', name: 'Single Premium' }
    ],

    global.CISPremiumMode = [
        { code: 'Monthly', name: 'Monthly' },
        { code: 'Quarterly', name: 'Quarterly' },
        { code: 'Semi-Annually', name: 'Semi-Annually' },
        { code: 'Annually', name: 'Annually' },
        { code: 'Not Applicable', name: 'Not Applicable' }
    ],
     
    global.policyStatusPos = [
        { code: '', name: 'All'},
        { code: 'App Received', name: 'App Received'},
        { code: 'In-force', name: 'In-force'},
        { code: 'Lapsed', name: 'Lapsed'},
        { code: 'Matured', name: 'Matured'},
        { code: 'Surrendered', name: 'Surrendered'},
        { code: 'Terminated', name: 'Terminated'},
        { code: 'Expired', name: 'Expired'},
        { code: 'Free-Look Cancellation', name: 'Free-Look Cancellation'},
        { code: 'Cancel From Inception', name: 'Cancel From Inception'}
    ]

    global.typeOfInvestment = [
        { code: 'UT/ETF - Equity', name: 'UT/ETF - Equity' },
        { code: 'UT/ETF - Bond/Income', name: 'UT/ETF - Bond/Income' },
        { code: 'UT/ETF - Balanced', name: 'UT/ETF - Balanced' },
        { code: 'Equity', name: 'Equity' },
        { code: 'Bonds', name: 'Bonds' },
        { code: 'Structured products', name: 'Structured products' },
        { code: 'Others e.g PE, gold etc.', name: 'Others e.g PE, gold etc.' }
    ],

    global.typeOfPolicy = [
        { code: 'Whole Life', name: 'Whole Life' },
        { code: 'Endowment', name: 'Endowment' },
        { code: 'Term', name: 'Term' },
        { code: 'Decreasing Term', name: 'Decreasing Term' },
        { code: 'Critical Illness', name: 'Critical Illness' },
        { code: 'Health Insurance', name: 'Health Insurance' },
        { code: 'Hospital Cash', name: 'Hospital Cash' },
        { code: 'Long Term Care', name: 'Long Term Care' },
        { code: 'Disability Income', name: 'Disability Income' },
        { code: 'Personal Accident', name: 'Personal Accident' },
        { code: 'Annuity', name: 'Annuity' },
        { code: 'Universal Life', name: 'Universal Life' },
        { code: 'ILP', name: 'ILP' }
    ],

    global.needs = [
        {
            code: '453',
            name: 'Death',
            key: 'income_protection_death',
            valueKey: 'total_death',
            yearKey: 'required_years_death',
            color: '#2F4F4F',
            backgroundColor: '#dde9f3',
            order: 1
        },
        {
            code: '461',
            name: 'Total permanent disability',
            key: 'income_protection_disability',
            valueKey: 'total_disability',
            yearKey: 'required_years_disability',
            color: '#7B68EE',
            backgroundColor: '#dde9f3',
            order: 2
        },
        {
            code: '454',
            name: 'Critical Illness',
            key: 'critical_illness',
            valueKey: 'total_ilness',
            yearKey: 'required_years_ilness',
            color: '#6495ED',
            backgroundColor: '#dde9f3',
            order: 3
        },
        {
            code: '624',
            name: 'Medical and hospitalisation costs',
            key: 'medical_hospitalisation_costs',
            valueKey: 'total_medical_and_hospitalisation',
            color: '#ff9800',
            backgroundColor: '#dde9f3',
            order: 4
        },
        {
            code: 'Premium Waiver',
            name: 'Premium Waiver',
            key: 'premium_waiver',
            // valueKey: 'total_medical_and_hospitalisation',
            color: '#bb76a1',
            backgroundColor: '#dde9f3',
            order: 4.5
        },
        {
            code: '455',
            name: 'Outpatient medical expenses protection',
            key: 'outpatient_medical_expenses_protection',
            valueKey: 'plan_total_amount_outpatient',
            color: '#B0C4DE',
            backgroundColor: '#dde9f3',
            order: 5
        },
        {
            code: '459',
            name: 'Dental expenses protection',
            key: 'dental_expenses_protection',
            valueKey: 'total_amount_plan_dental',
            color: '#98FB98',
            backgroundColor: '#dde9f3',
            order: 6
        },
        {
            code: '458',
            name: 'Personal accident',
            key: 'personal_accident',
            valueKey: 'total_accident',
            color: '#ff4646',
            backgroundColor: '#dde9f3',
            order: 7
        },
        {
            code: '460',
            name: 'Long term care',
            key: 'long_term_care',
            valueKey: 'total_amount_ltc',

            color: '#009100',
            backgroundColor: '#dde9f3',
            order: 8
        },
        {
            code: '456',
            name: "Planning for dependant's protection",
            key: 'dependant_protection_planning',
            valueKey: 'total_dependants',
            color: '#6C3365',
            backgroundColor: '#dde9f3',
            order: 9
        },
        {
            code: '462',
            name: 'Retirement Planning',
            yearKey: 'year_retirement',
            key: 'retirement_needs',
            valueKey: 'total',
            color: '#2196f3',
            backgroundColor: '#dde9f3',
            order: 10
        },

        {
            code: '457',
            name: 'Other Protection',
            color: '#FF9D6F',
            backgroundColor: '#dde9f3',
            order: 11
        },
        {
            code: '463',
            name: 'Education Fund Planning',
            color: '#ffa6ff',
            backgroundColor: '#dde9f3',
            yearKey: 'save_years_number',
            order: 12
        },

        {
            code: '464',
            name: 'Other Wealth Accumulation & Preservation Goals',
            color: '#BE77FF',
            backgroundColor: '#dde9f3',
            yearKey: 'when_fund_needed',
            order: 13
        }
    ],

    global.priority = [
        {
            code: '453',
            name: 'Death',
        },
        {
            code: '461',
            name: 'Total permanent disability',
        },
        {
            code: '454',
            name: 'Critical Illness',
        },
        {
            code: '624',
            name: 'Medical and hospitalisation costs',
        },
        {
            code: 'Premium Waiver',
            name: 'Premium Waiver',
        },
        {
            code: '455',
            name: 'Outpatient medical expenses protection',
        },
        {
            code: '459',
            name: 'Dental expenses protection',
        },
        {
            code: '458',
            name: 'Personal accident',
        },
        {
            code: '460',
            name: 'Long term care',
        },
        {
            code: '457',
            name: 'Others',
        }
    ],

    global.specialNeeds = [
        {
            code: '624',
            name: 'Medical and hospitalisation costs',
            key: 'medical_hospitalisation_costs',
            valueKey: 'total_medical_and_hospitalisation',
            color: '#ff9800',
            backgroundColor: '#dde9f3',
            order: 4
        }
    ],

    global.hospital = [
        { code: 'Government', name: 'Government' },
        { code: 'Private', name: 'Private' }
    ],

    global.governmentOtherBenefit = [
        { code: 'Ward A', name: 'Ward A' },
        { code: 'Ward B1', name: 'Ward B1' },
        { code: 'Ward B2', name: 'Ward B2' },
        { code: 'Ward C', name: 'Ward C' },
        { code: 'Not Applicable', name: 'Not Applicable' }
    ],

    global.privateOtherBenefit = [
        { code: 'As Charged', name: 'As Charged' },
        { code: 'Non As Charged', name: 'Non As Charged' }
    ],

    global.yesOrNo = [
        { code: 'Yes', name: 'Yes' },
        { code: 'No', name: 'No' }
    ]

    global.gradeLevel = [
        { code: 'C1', name: 'Conservative' },
        { code: 'C2', name: 'Moderately Conservative' },
        { code: 'C3', name: 'Balanced' },
        { code: 'C4', name: 'Moderately Aggressive' },
        { code: 'C5', name: 'Aggressive' }
    ]

    global.expireOptions = [
        {
            value: '',
            label: 'All'
        }, {
            value: '10',
            label: '1 Weeks'
        }, {
            value: '20',
            label: '2 Weeks'
        }, {
            value: '30',
            label: '1 Month'
        }, {
            value: '40',
            label: '2 Month'
        }, {
            value: '50',
            label: '3 Month'
        }
    ]

    global.config = {
        '40': {
            statusColor: '#E68744',
            statusBgColor: '#FEE3E2'
        },
        '5': {
            statusColor: '#F6C83F',
            statusBgColor: '#FFF'
        },
        '10': {
            statusColor: '#509DD4',
            statusBgColor: '#DAF0FF'
        },
        '20': {
            statusColor: ' #EAA26F',
            statusBgColor: '#fff8ca'
        },
        '30': {
            statusColor: '#67C7A7',
            statusBgColor: '#F0F9F5'
        },
        '55': {
            statusColor: ' #EAA26F',
            statusBgColor: '#fff8ca'
        }
    },

    global.assetClassOptions = [
        {
            value: '',
            label: 'All'
        }, {
            value: '323',
            label: 'Equity'
        }, {
            value: '324',
            label: 'Fixed Income'
        }, {
            value: '325',
            label: 'Multi Asset'
        }, {
            value: '326',
            label: 'Others'
        }, {
            value: '327',
            label: 'Alternative'
        }, {
            value: 'Cash',
            label: 'Cash'
        }, {
            value: '701',
            label: 'P6'
        }
    ]

    global.riskRatingOptions = [
        {
            value: '',
            label: 'All'
        }, {
            value: 'P0',
            label: 'P0'
        }, {
            value: '447',
            label: 'P1'
        }, {
            value: '448',
            label: 'P2'
        }, {
            value: '449',
            label: 'P3'
        }, {
            value: '450',
            label: 'P4'
        }, {
            value: '451',
            label: 'P5'
        }, {
            value: '701',
            label: 'P6'
        }, {
            value: '702',
            label: 'P7'
        }, {
            value: '705',
            label: 'P8'
        }, {
            value: '706',
            label: 'P9'
        }, {
            value: '709',
            label: 'P10'
        }, {
            value: '452',
            label: 'NA'
        },
    ]

    global.schemeEligibilityOptions = [
        {
            value: '',
            label: 'All'
        }, {
            value: '348',
            label: 'Cash'
        }, {
            value: '349',
            label: 'CPF OA'
        }, {
            value: '350',
            label: 'CPF SA'
        }, {
            value: '351',
            label: 'SRS'
        }
    ]

    global.kycParam = {
        "kyc": {
            "is_recording": 1,
            "edit_authority": 0,
            "record_link": "",
            "kyc_link": "",
            "reason": "",
            "kyc_no": "",
            "agent_id": "",
            "notes": "",
            "additional_notice": "",
            "kyc_pdf_attachment": "",
            "client": [
                {
                    "client_id": "",
                    "personal_information": {
                        "title": "",
                        "full_name": "",
                        "surname": "",
                        "gender": "",
                        "nric_passport_fin": "",
                        "nationality": "",
                        "identity_type": "",
                        "race": "",
                        "race_content": "",
                        "language_spoken": "",
                        "language_spoken_content": "",
                        "language_writen": "",
                        "language_writen_content": "",
                        "date_of_birth": "",
                        "marial_status": "",
                        "email": "",
                        "contact_no_mobile_area": "",
                        "contact_no_mobile": "",
                        "contact_no_home_area": "",
                        "contact_no_home": "",
                        "contact_no_office_area": "",
                        "contact_no_office": "",
                        "mailing_id": "",
                        "mailing_street": "",
                        "mailing_unit_no": "",
                        "mailing_building_name": "",
                        "mailing_country": "",
                        "mailing_zip_code": "",
                        "address_same_as_residential": "",
                        "residential_street": "",
                        "residential_unit_no": "",
                        "residential_building_name": "",
                        "residential_country": "",
                        "residential_zip_code": "",
                        "education_level": "",
                        "smoker": "",
                        "client_source": "",
                        "employer": "",
                        "employment_status": "",
                        "employment_status_content": "",
                        "employment_street": "",
                        "employment_unit_no": "",
                        "employment_building_name": "",
                        "employment_country": "",
                        "employment_zip_code": "",
                        "occupation": "",
                        "heath_condition": "",
                        "heath_condition_description": "",
                        "additional_notice": ""
                    },
                    "trusted_individual": {
                        "trusted_individual": "",
                        "is_old": "",
                        "is_gce_no": "",
                        "is_not_proficient_english": "",
                        "is_accompanied": "",
                        "is_not_accompanied_reason": "",
                        "trusted_individual_langunge": "",
                        "trusted_individual_nricnumber": "",
                        "trusted_individual_relationship": "",
                        "trusted_individual_area": "",
                        "trusted_individual_content_number": "",
                        "additional_notice": "",
                        "field": ""
                    },
                    "dependants_list": {
                        "is_dependants": "",
                        "reason": "",
                        "additional_notice": "",
                        "dependants_details": []
                    },
                    "financial_objectives": {
                        "income_protection_death": "",
                        "income_protection_disability": "",
                        "critical_illness": "",
                        "medical_hospitalisation_costs": "",
                        "premium_waiver": "",
                        "outpatient_medical_expenses_protection": "",
                        "dental_expenses_protection": "",
                        "personal_accident": "",
                        "long_term_care": "",
                        "dependant_protection_planning": "",
                        "myself_education_fund": "",
                        "retirement_needs": "",
                        "annual_growth_rate": "3",
                        "ror": "5",
                        "additional_notice": "",
                        "other_financial_objectives": [],
                        "dependants_financial_objectives": []
                    },
                    "assets_liabilities": {
                        "is_provide_cash_assets_liabilities": "Y",
                        "no_provide_reason": "",
                        "personal_assets_savings": "0",
                        "fixed_deposits": "0",
                        "residential_property": "0",
                        "motor_vehicles": "0",
                        "cash_mortgage": "0",
                        "total_personal_assets": "0",
                        "shares": "0",
                        "bond": "0",
                        "unit_trust": "0",
                        "ilp": "0",
                        "insurance_cash_value": "0",
                        "cpf_ordinary_account": "0",
                        "cpf_specia_account": "0",
                        "cpf_medisave_account": "0",
                        "srs_account": "0",
                        "retirement_account": "0",
                        "business_interestabc": "0",
                        "total_invested_assets": "0",
                        "total_assets": "0",
                        "credit_cards": "0",
                        "overdrafts": "0",
                        "outstanding_taxes": "0",
                        "total_short_term_liabilities": "0",
                        "mortgage": "0",
                        "investment_property_mortage": "0",
                        "car_loan": "0",
                        "total_long_term_liabilities": "0",
                        "total_liabilities": "0",
                        "net_assets_position": "0",
                        "is_assets_change_dramatically": "",
                        "assets_change_dramatically_detail": "",
                        "no_net_asset_position_resaon": "",
                        "additional_notice": "",
                        "other_assets_liabilities": [],
                        "field": ""
                    },
                    "cashflow": {
                        "is_reserve_half_assets": "",
                        "gross_monthly_income": "0",
                        "less_cpf_contribution": "0",
                        "add_other_income": "0",
                        "total_monthly_income": "0",
                        "utilities": "0",
                        "groceries": "0",
                        "property_tax": "0",
                        "maid_expenses": "0",
                        "cash_mortgage": "0",
                        "household_others": "0",
                        "household_total": "0",
                        "allowance_parents": "0",
                        "allowance_children": "0",
                        "school_fees": "0",
                        "medical_mxpenses": "0",
                        "dependants_others": "0",
                        "dependants_total": "0",
                        "cpf_oa_inflow": "0",
                        "cpf_sa_inflow": "0",
                        "cpf_medisave_inflow": "0",
                        "cpf_total": "0",
                        "mortgage_cpf_oa": "0",
                        "investment_cpf_oa": "0",
                        "investment_cpf_sa": "0",
                        "cpf_ma_outflow": "0",
                        "cpf_outflow_total": "0",
                        "monthly_cash_outflow_total": "0",
                        "monthly_cash_shortfall_surplus": "0",
                        "cpf_shortfall_surplus": "0",
                        "is_income_expenditure_change_dramatically": "",
                        "income_expenditure_change_details": "",
                        "is_provide_cash_flow": "Y",
                        "no_provide_reason": "",
                        "additional_notice": ""
                    },
                    "existing_investments_insurance": {
                        "is_consideration_exist_portfolio": "",
                        "no_consideration_reson": "",
                        "additional_notice": "",
                        "existing_investments": [],
                        "existing_policies": []
                    },
                    "budget": {
                        "total_annual": "0",
                        "total_single": "0",
                        "cash_single": "0",
                        "cpf_ordinary_account_single": "0",
                        "cpf_special_account_single": "0",
                        "retirement_account_single": "0",
                        "cpf_medisave_account_single": "0",
                        "supplementary_retirement_scheme_single": "0",
                        "cash_annual": "0",
                        "cpf_ordinary_account_annual": "0",
                        "cpf_special_account_annual": "0",
                        "retirement_account_annual": "0",
                        "cpf_medisave_account_annual": "0",
                        "supplementary_retirement_scheme_annual": "0",
                        "is_reserve_half_assets": "",
                        "additional_notice": ""
                    },
                    "customer_knowledge_assessment": {
                        "investment_experience_a": '',
                        "your_investment_experience_a": '',
                        "investment_experience_b": '',
                        "your_investment_experience_b": '',
                        "hold_finance_diploma": "",
                        "finance_qualification": "",
                        "have_other_finance_related_qualifications": "",
                        "other_finance_qualification": "",
                        "have_six_transactions": "",
                        "your_transactions": "",
                        "least_three_year_experience": "",
                        "your_experience": "",
                        "assessment_outcome": "",
                        "additional_notice": ""
                    },
                    "risk_profile_questionnaire": {
                        "is_take_questions": "Y",
                        "no_take_reason": "",
                        "is_agree_profile": "Y",
                        "own_choice": "",
                        "score": "0",
                        "level": "",
                        "grade": "",
                        "comment": "",
                        "additional_notice": "",
                        "risk_profile_questionnaire_answer": []
                    },
                    "accredited_investor_declaration": {
                        "net_personal_assets": "",
                        "financial_assets": "",
                        "income": "",
                        "income_others": "",
                        "is_accredited_investor": "",
                        "is_ai": "N",
                        "additional_notice": ""
                    },
                    "needs_analysis": {
                        "additional_notice": "",
                        "total_protection_goals": "0",
                        "total_savings_accumulation_goals": "0",
                        "dependants_required_death": "0",
                        "family_required_death": "0",
                        "required_years_death": "0",
                        "inflation_adjusted_return_rate_death": "1.9417475728",
                        "funds_required_death": "0",
                        "add_total_liabilities": "0",
                        "add_final_expenses": "0",
                        "add_other_fund": "0",
                        "total_required_death": "0",
                        "less_existing_life_insurance": "0",
                        "less_other_funds_available": "0",
                        "total_death": "0",
                        "annual_growth_rate_death": "3",
                        "ror_death": "5",
                        "dependants_required_disability": "0",
                        "sustain_ifestyle_required_disability": "0",
                        "required_years_disability": "0",
                        "inflation_adjusted_return_rate_disability": "1.9417475728",
                        "maintain_lifestyle_sum_disability": "0",
                        "add_lump_sum_required": "0",
                        "total_need_disability": "0",
                        "less_existing_disability_benefit": "0",
                        "other_funds_available_disability": "0",
                        "total_disability": "0",
                        "annual_growth_rate_disability": "3",
                        "ror_disability": "5",
                        "dependants_required_ilness": "0",
                        "sustain_ifestyle_required_ilness": "0",
                        "required_years_ilness": "0",
                        "inflation_adjusted_return_rate_ilness": "1.9417475728",
                        "maintain_lifestyle_sum_ilness": "0",
                        "add_medical_cost": "0",
                        "add_other_funding_needs": "0",
                        "estimated_amount_required_illness": "0",
                        "less_existing_critical_illness_insurance": "0",
                        "less_other_funds_illness": "0",
                        "total_ilness": "0",
                        "annual_growth_rate_ilness": "3",
                        "ror_ilness": "5",
                        "dependants_required_medical": "",
                        "hospital_surgical_coverage": "",
                        "daily_room_board": "",
                        "surgical_expenses": "",
                        "hospital_income_required": "",
                        "preferred_hospital_type": "",
                        "preferred_ward_type": "",
                        "other_benefit": "",
                        "ride": "",
                        "total_medical_and_hospitalisation": "0",
                        "want_amount_outpatient": "0",
                        "less_existing_insurance_outpatient": "0",
                        "plan_total_amount_outpatient": "0",
                        "want_amount_dental": "0",
                        "less_existing_insurance_dental": "0",
                        "total_amount_plan_dental": "0",
                        "desired_personal_accident_benefit": "0",
                        "less_current_resources_provisions": "0",
                        "total_accident": "0",
                        "monthly_amount_need_ltc": "0",
                        "months_needed_number_ltc": "0",
                        "less_existing_insurance_ltc": "0",
                        "total_amount_ltc": "0",
                        "plan_dependant_protection": {
                            "dependants_total_all": "0",
                            "dependants_needs_list": []
                        },
                        "protection_goals_others": [],
                        "education_fund": [],
                        "total_education_fund": "0",
                        "retirement_income": "0",
                        "year_retirement": "0",
                        "annual_income_required_pv": "0",
                        "average_inflation_rate": "0",
                        "fiannual_income_required_fveld": "0",
                        "less_existing_regular_income": "0",
                        "total_retirement_income_required": "0",
                        "first_year_income": "0",
                        "duration_of_retirement": "0",
                        "adjusted_rate_return": "1.9417475728",
                        "total_amount_required": "0",
                        "available_funds_meet_need": "0",
                        "less_maturity_value": "0",
                        "less_total_asset": "0",
                        "total": "0",
                        "annual_growth_rate_retirement": "3",
                        "ror_retirement": "5",
                        "saving_accumulation_goals_others": []
                    },
                    "basis_recommendations": {
                        "total_annualised_premium": "",
                        "total_single_premium": "",
                        "total_assets_under_advice": "",
                        "recommendation_reason": "",
                        "plan_risk_limitation": "",
                        "fees_charges": "",
                        "is_deviation": "",
                        "deviation_reason": "",
                        "additional_notice": "",
                        "product_recommendations_option": []
                    },
                    "switching_replacement_policy": {
                        "is_purchase": "",
                        "no_purchase_reason": "",
                        "is_advise_replace_policy": "",
                        "is_explained": "",
                        "replacement_declaration": "",
                        "additional_notice": "",
                        "products": []
                    },
                    "client_acknowledgement_declaration": {
                        "is_agree": "",
                        "wish_amendment": "",
                        "is_investment_linked_plan": "",
                        "is_experience": "",
                        "is_aware_ilp_suitability": "",
                        "is_understand_contacting_income": "",
                        "is_understand_approval": "",
                        "is_recommended_plan_nature": "",
                        "is_my_profile": "",
                        "is_contractual_rights": "",
                        "is_recommended_plan_price": "",
                        "is_commitment_required": "",
                        "is_free_look_provision": "",
                        "is_fees_charges": "",
                        "is_warnings_exclusion_disclaimers": "",
                        "is_surrender_claim_procedures": "",
                        "is_recommended_plan_fbrl": "",
                        "is_plan_provider_details": "",
                        "is_insurance_fuide": "",
                        "is_available_policy_document": "",
                        "is_read_understood": "",
                        "option_call": "",
                        "option_email": "",
                        "option_mail": "",
                        "option_sms": "",
                        "is_beneficial_ownership_arrangement": "",
                        "beneficial_owner_name": "",
                        "owner_nric_passportno_fin": "",
                        "owner_birth": "",
                        "owner_gender": "",
                        "owner_nationality_type": "",
                        "owner_nationality_content": "",
                        "owner_proposer_relationship": "",
                        "is_proposer_payor": "",
                        "payor_name": "",
                        "payor_nric_passportno_fin": "",
                        "payor_occupation": "",
                        "payor_proposer_relationship": "",
                        "pay_reason": "",
                        "is_pep": "",
                        "pep_name": "",
                        "pep_title": "",
                        "pep_related_person_name": "",
                        "pep_relationship": "",
                        "payor_others_name": "",
                        "fund_source_policy_proceeds": "",
                        "fund_source_salary_commission": "",
                        "fund_source_sale_asset": "",
                        "fund_source_inheritance": "",
                        "fund_source_others": "",
                        "fund_source_personal_savings": "",
                        "not_employed_details": "",
                        "is_other_language": "",
                        "en_explain_people_name": "",
                        "en_explain_people_relationship": "",
                        "languages_choose": "",
                        "is_explained_by_myself": "",
                        "is_explained_by_other": "",
                        "explain_people_name": "",
                        "explain_people_relationship": "",
                        "client_signature_id": "",
                        "signature_date": "",
                        "next_review_year": "",
                        "next_review_month": "",
                        "next_review_comment": "",
                        "additional_notice": ""
                    }
                }, {
                    "client_id": "",
                    "personal_information": {
                        "title": "",
                        "full_name": "",
                        "surname": "",
                        "gender": "",
                        "nric_passport_fin": "",
                        "nationality": "",
                        "identity_type": "",
                        "race": "",
                        "race_content": "",
                        "language_spoken": "",
                        "language_spoken_content": "",
                        "language_writen": "",
                        "language_writen_content": "",
                        "date_of_birth": "",
                        "marial_status": "",
                        "email": "",
                        "contact_no_mobile_area": "",
                        "contact_no_mobile": "",
                        "contact_no_home_area": "",
                        "contact_no_home": "",
                        "contact_no_office_area": "",
                        "contact_no_office": "",
                        "mailing_id": "",
                        "mailing_street": "",
                        "mailing_unit_no": "",
                        "mailing_building_name": "",
                        "mailing_country": "",
                        "mailing_zip_code": "",
                        "address_same_as_residential": "",
                        "residential_street": "",
                        "residential_unit_no": "",
                        "residential_building_name": "",
                        "residential_country": "",
                        "residential_zip_code": "",
                        "education_level": "",
                        "smoker": "",
                        "client_source": "",
                        "employer": "",
                        "employment_status": "",
                        "employment_status_content": "",
                        "employment_street": "",
                        "employment_unit_no": "",
                        "employment_building_name": "",
                        "employment_country": "",
                        "employment_zip_code": "",
                        "occupation": "",
                        "heath_condition": "",
                        "heath_condition_description": "",
                        "additional_notice": ""
                    },
                    "trusted_individual": {
                        "trusted_individual": "",
                        "is_old": "",
                        "is_gce_no": "",
                        "is_not_proficient_english": "",
                        "is_accompanied": "",
                        "is_not_accompanied_reason": "",
                        "trusted_individual_langunge": "",
                        "trusted_individual_nricnumber": "",
                        "trusted_individual_relationship": "",
                        "trusted_individual_area": "",
                        "trusted_individual_content_number": "",
                        "additional_notice": "",
                        "field": ""
                    },
                    "dependants_list": {
                        "is_dependants": "",
                        "reason": "",
                        "additional_notice": "",
                        "dependants_details": []
                    },
                    "financial_objectives": {
                        "income_protection_death": "",
                        "income_protection_disability": "",
                        "critical_illness": "",
                        "medical_hospitalisation_costs": "",
                        "premium_waiver": "",
                        "outpatient_medical_expenses_protection": "",
                        "dental_expenses_protection": "",
                        "personal_accident": "",
                        "long_term_care": "",
                        "dependant_protection_planning": "",
                        "myself_education_fund": "",
                        "retirement_needs": "",
                        "annual_growth_rate": "3",
                        "ror": "5",
                        "additional_notice": "",
                        "other_financial_objectives": [],
                        "dependants_financial_objectives": []
                    },
                    "assets_liabilities": {
                        "is_provide_cash_assets_liabilities": "Y",
                        "no_provide_reason": "",
                        "personal_assets_savings": "0",
                        "fixed_deposits": "0",
                        "residential_property": "0",
                        "motor_vehicles": "0",
                        "cash_mortgage": "0",
                        "total_personal_assets": "0",
                        "shares": "0",
                        "bond": "0",
                        "unit_trust": "0",
                        "ilp": "0",
                        "insurance_cash_value": "0",
                        "cpf_ordinary_account": "0",
                        "cpf_specia_account": "0",
                        "cpf_medisave_account": "0",
                        "srs_account": "0",
                        "retirement_account": "0",
                        "business_interestabc": "0",
                        "total_invested_assets": "0",
                        "total_assets": "0",
                        "credit_cards": "0",
                        "overdrafts": "0",
                        "outstanding_taxes": "0",
                        "total_short_term_liabilities": "0",
                        "mortgage": "0",
                        "investment_property_mortage": "0",
                        "car_loan": "0",
                        "total_long_term_liabilities": "0",
                        "total_liabilities": "0",
                        "net_assets_position": "0",
                        "is_assets_change_dramatically": "",
                        "assets_change_dramatically_detail": "",
                        "no_net_asset_position_resaon": "",
                        "additional_notice": "",
                        "other_assets_liabilities": [],
                        "field": ""
                    },
                    "cashflow": {
                        "is_reserve_half_assets": "",
                        "gross_monthly_income": "0",
                        "less_cpf_contribution": "0",
                        "add_other_income": "0",
                        "total_monthly_income": "0",
                        "utilities": "0",
                        "groceries": "0",
                        "property_tax": "0",
                        "maid_expenses": "0",
                        "cash_mortgage": "0",
                        "household_others": "0",
                        "household_total": "0",
                        "allowance_parents": "0",
                        "allowance_children": "0",
                        "school_fees": "0",
                        "medical_mxpenses": "0",
                        "dependants_others": "0",
                        "dependants_total": "0",
                        "cpf_oa_inflow": "0",
                        "cpf_sa_inflow": "0",
                        "cpf_medisave_inflow": "0",
                        "cpf_total": "0",
                        "mortgage_cpf_oa": "0",
                        "investment_cpf_oa": "0",
                        "investment_cpf_sa": "0",
                        "cpf_ma_outflow": "0",
                        "cpf_outflow_total": "0",
                        "monthly_cash_outflow_total": "0",
                        "monthly_cash_shortfall_surplus": "0",
                        "cpf_shortfall_surplus": "0",
                        "is_income_expenditure_change_dramatically": "",
                        "income_expenditure_change_details": "",
                        "is_provide_cash_flow": "Y",
                        "no_provide_reason": "",
                        "additional_notice": ""
                    },
                    "existing_investments_insurance": {
                        "is_consideration_exist_portfolio": "",
                        "no_consideration_reson": "",
                        "additional_notice": "",
                        "existing_investments": [],
                        "existing_policies": []
                    },
                    "budget": {
                        "total_annual": "0",
                        "total_single": "0",
                        "cash_single": "0",
                        "cpf_ordinary_account_single": "0",
                        "cpf_special_account_single": "0",
                        "retirement_account_single": "0",
                        "cpf_medisave_account_single": "0",
                        "supplementary_retirement_scheme_single": "0",
                        "cash_annual": "0",
                        "cpf_ordinary_account_annual": "0",
                        "cpf_special_account_annual": "0",
                        "retirement_account_annual": "0",
                        "cpf_medisave_account_annual": "0",
                        "supplementary_retirement_scheme_annual": "0",
                        "is_reserve_half_assets": "",
                        "additional_notice": ""
                    },
                    "customer_knowledge_assessment": {
                        "investment_experience_a": '',
                        "your_investment_experience_a": '',
                        "investment_experience_b": '',
                        "your_investment_experience_b": '',
                        "hold_finance_diploma": "",
                        "finance_qualification": "",
                        "have_other_finance_related_qualifications": "",
                        "other_finance_qualification": "",
                        "have_six_transactions": "",
                        "your_transactions": "",
                        "least_three_year_experience": "",
                        "your_experience": "",
                        "assessment_outcome": "",
                        "additional_notice": ""
                    },
                    "risk_profile_questionnaire": {
                        "is_take_questions": "Y",
                        "no_take_reason": "",
                        "is_agree_profile": "Y",
                        "own_choice": "",
                        "score": "0",
                        "level": "",
                        "grade": "",
                        "comment": "",
                        "additional_notice": "",
                        "risk_profile_questionnaire_answer": []
                    },
                    "accredited_investor_declaration": {
                        "net_personal_assets": "",
                        "financial_assets": "",
                        "income": "",
                        "income_others": "",
                        "is_accredited_investor": "",
                        "is_ai": "N",
                        "additional_notice": ""
                    },
                    "needs_analysis": {
                        "additional_notice": "",
                        "total_protection_goals": "0",
                        "total_savings_accumulation_goals": "0",
                        "dependants_required_death": "0",
                        "family_required_death": "0",
                        "required_years_death": "0",
                        "inflation_adjusted_return_rate_death": "1.9417475728",
                        "funds_required_death": "0",
                        "add_total_liabilities": "0",
                        "add_final_expenses": "0",
                        "add_other_fund": "0",
                        "total_required_death": "0",
                        "less_existing_life_insurance": "0",
                        "less_other_funds_available": "0",
                        "total_death": "0",
                        "annual_growth_rate_death": "3",
                        "ror_death": "5",
                        "dependants_required_disability": "0",
                        "sustain_ifestyle_required_disability": "0",
                        "required_years_disability": "0",
                        "inflation_adjusted_return_rate_disability": "1.9417475728",
                        "maintain_lifestyle_sum_disability": "0",
                        "add_lump_sum_required": "0",
                        "total_need_disability": "0",
                        "less_existing_disability_benefit": "0",
                        "other_funds_available_disability": "0",
                        "total_disability": "0",
                        "annual_growth_rate_disability": "3",
                        "ror_disability": "5",
                        "dependants_required_ilness": "0",
                        "sustain_ifestyle_required_ilness": "0",
                        "required_years_ilness": "0",
                        "inflation_adjusted_return_rate_ilness": "1.9417475728",
                        "maintain_lifestyle_sum_ilness": "0",
                        "add_medical_cost": "0",
                        "add_other_funding_needs": "0",
                        "estimated_amount_required_illness": "0",
                        "less_existing_critical_illness_insurance": "0",
                        "less_other_funds_illness": "0",
                        "total_ilness": "0",
                        "annual_growth_rate_ilness": "3",
                        "ror_ilness": "5",
                        "dependants_required_medical": "",
                        "hospital_surgical_coverage": "",
                        "daily_room_board": "",
                        "surgical_expenses": "",
                        "hospital_income_required": "",
                        "preferred_hospital_type": "",
                        "preferred_ward_type": "",
                        "other_benefit": "",
                        "ride": "",
                        "total_medical_and_hospitalisation": "0",
                        "want_amount_outpatient": "0",
                        "less_existing_insurance_outpatient": "0",
                        "plan_total_amount_outpatient": "0",
                        "want_amount_dental": "0",
                        "less_existing_insurance_dental": "0",
                        "total_amount_plan_dental": "0",
                        "desired_personal_accident_benefit": "0",
                        "less_current_resources_provisions": "0",
                        "total_accident": "0",
                        "monthly_amount_need_ltc": "0",
                        "months_needed_number_ltc": "0",
                        "less_existing_insurance_ltc": "0",
                        "total_amount_ltc": "0",
                        "plan_dependant_protection": {
                            "dependants_total_all": "0",
                            "dependants_needs_list": []
                        },
                        "protection_goals_others": [],
                        "education_fund": [],
                        "total_education_fund": "0",
                        "retirement_income": "0",
                        "year_retirement": "0",
                        "annual_income_required_pv": "0",
                        "average_inflation_rate": "0",
                        "fiannual_income_required_fveld": "0",
                        "less_existing_regular_income": "0",
                        "total_retirement_income_required": "0",
                        "first_year_income": "0",
                        "duration_of_retirement": "0",
                        "adjusted_rate_return": "1.9417475728",
                        "total_amount_required": "0",
                        "available_funds_meet_need": "0",
                        "less_maturity_value": "0",
                        "less_total_asset": "0",
                        "total": "0",
                        "annual_growth_rate_retirement": "3",
                        "ror_retirement": "5",
                        "saving_accumulation_goals_others": []
                    },
                    "basis_recommendations": {
                        "total_annualised_premium": "",
                        "total_single_premium": "",
                        "total_assets_under_advice": "",
                        "recommendation_reason": "",
                        "plan_risk_limitation": "",
                        "fees_charges": "",
                        "is_deviation": "",
                        "deviation_reason": "",
                        "additional_notice": "",
                        "product_recommendations_option": []
                    },
                    "switching_replacement_policy": {
                        "is_purchase": "",
                        "no_purchase_reason": "",
                        "is_advise_replace_policy": "",
                        "is_explained": "",
                        "replacement_declaration": "",
                        "additional_notice": "",
                        "products": []
                    },
                    "client_acknowledgement_declaration": {
                        "is_agree": "",
                        "wish_amendment": "",
                        "is_investment_linked_plan": "",
                        "is_experience": "",
                        "is_aware_ilp_suitability": "",
                        "is_understand_contacting_income": "",
                        "is_understand_approval": "",
                        "is_recommended_plan_nature": "",
                        "is_my_profile": "",
                        "is_contractual_rights": "",
                        "is_recommended_plan_price": "",
                        "is_commitment_required": "",
                        "is_free_look_provision": "",
                        "is_fees_charges": "",
                        "is_warnings_exclusion_disclaimers": "",
                        "is_surrender_claim_procedures": "",
                        "is_recommended_plan_fbrl": "",
                        "is_plan_provider_details": "",
                        "is_insurance_fuide": "",
                        "is_available_policy_document": "",
                        "is_read_understood": "",
                        "option_call": "",
                        "option_email": "",
                        "option_mail": "",
                        "option_sms": "",
                        "is_beneficial_ownership_arrangement": "",
                        "beneficial_owner_name": "",
                        "owner_nric_passportno_fin": "",
                        "owner_birth": "",
                        "owner_gender": "",
                        "owner_nationality_type": "",
                        "owner_nationality_content": "",
                        "owner_proposer_relationship": "",
                        "is_proposer_payor": "",
                        "payor_name": "",
                        "payor_nric_passportno_fin": "",
                        "payor_occupation": "",
                        "payor_proposer_relationship": "",
                        "pay_reason": "",
                        "is_pep": "",
                        "pep_name": "",
                        "pep_title": "",
                        "pep_related_person_name": "",
                        "pep_relationship": "",
                        "payor_others_name": "",
                        "fund_source_policy_proceeds": "",
                        "fund_source_salary_commission": "",
                        "fund_source_sale_asset": "",
                        "fund_source_inheritance": "",
                        "fund_source_others": "",
                        "fund_source_personal_savings": "",
                        "not_employed_details": "",
                        "is_other_language": "",
                        "en_explain_people_name": "",
                        "en_explain_people_relationship": "",
                        "languages_choose": "",
                        "is_explained_by_myself": "",
                        "is_explained_by_other": "",
                        "explain_people_name": "",
                        "explain_people_relationship": "",
                        "client_signature_id": "",
                        "signature_date": "",
                        "next_review_year": "",
                        "next_review_month": "",
                        "next_review_comment": "",
                        "additional_notice": ""
                    }
                }
            ]
        }
    }

    global.kycRequired = {
        "personal_information": {
            "title": "Title",
            "full_name": "Full Name (as in NRIC)",
            "surname": "Surname",
            "gender": "Gender",
            "identity_type": "NRIC / Passport / FIN",
            "nric_passport_fin": "NRIC / Passport / FIN",
            "nationality": "Nationality",
            "date_of_birth": "Date of Birth",
            "race_content": "Others Race Name",
            "language_spoken": "Proficient in spoken language",
            "language_spoken_content": "Others Proficient in spoken language",
            "language_writen": "Proficient in written language",
            "language_writen_content": "Others Proficient in written language",
            "smoker": "Smoke",
            "contact_no_mobile": "Mobile",
            "contact_no_mobile_area": "Area",
            "marial_status": "Marital Status",
            "email": "Email",
            "residential_street": "Residential Block & Street Name",
            "residential_country": "Residential Country",
            "residential_zip_code": "Residential Postal/Zip Code",
            "address_same_as_residential": "Mailing Address Same As Residential Address",
            // "mailing_id": "",
            "mailing_street": "Mailing Block & Street Name",
            "mailing_country": "Mailing Country",
            "mailing_zip_code": "Mailing Postal/Zip Code",
            "employment_country": "Country",
            "employment_zip_code": "Postal/Zip Code",
            "education_level": "Educational Level",
            // "client_source": "",
            "employer": "Company Name",
            "employment_status": "Employment Status",
            "employment_status_content": "Please state content",
            "occupation": "Occupation",
            "heath_condition": "Do you or any of your family members have any medical condition that requires regular attention from clinic or hospital?",
            "heath_condition_description": "Please state description"
        },
        "trusted_individual": {
            "is_old": "",
            "is_gce_no": "",
            "is_not_proficient_english": "",
            "is_accompanied": "",
            "trusted_individual_content_number": "Contact No",
            "trusted_individual_relationship": "Relationship to client",
            "trusted_individual": "Name",
            "trusted_individual_nricnumber": "NRIC Number",
            "trusted_individual_area": "Area",
            "is_not_accompanied_reason": "Please state reason",
            // "field": "",
            // "trusted_individual_langunge": "",
        },
        "dependants_list": {
            "reason": "Please state reason",
            "is_dependants": "Would you like your dependant(s) to be taken into consideration for the Needs Analysis and Recommendation(s)?",
            "dependants_details": "Dependant's details",
        },
        "financial_objectives": {
            "income_protection_death": "Death",
            "income_protection_disability": "Total permanent disability",
            "critical_illness": "Critical Illness",
            "medical_hospitalisation_costs": "Medical and hospitalisation costs",
            "premium_waiver": "Premium Waiver",
            "outpatient_medical_expenses_protection": "Outpatient medical expenses protection",
            "dental_expenses_protection": "Dental expenses protection",
            "personal_accident": "Personal accident",
            "long_term_care": "Long-term care",
            "dependant_protection_planning": "Planning for dependant’s protection",
            "myself_education_fund": "Myself",
            "retirement_needs": "Saving for retirement needs",
            "ror": "Annual Rate of return(ROR)",
            "annual_growth_rate": "Annual Rate of Inflation",
            "dependants_financial_objectives": [],
            "other_financial_objectives": []
        },
        "assets_liabilities": {
            "is_provide_cash_assets_liabilities": "",
            "no_provide_reason": "Please state reason",
            "is_assets_change_dramatically": "Do you foresee any factors within the next 12-18 months which may significantly increase or decrease your net assets position?",
            "assets_change_dramatically_detail": "Please state detail",
        },
        "cashflow": {
            "is_provide_cash_flow": "",
            "no_provide_reason": "Please state reason",
            "is_income_expenditure_change_dramatically": "Do you foresee any factors within the next 12-18 months which may significantly increase or decrease your current income and expenditure position?",
            "income_expenditure_change_details": "Please state detail",
        },
        "existing_investments_insurance": {
            "no_consideration_reson": "Please state reason",
            "is_consideration_exist_portfolio": "Would you like your existing insurance and investment portfolio to be taken into consideration for the needs analysis and recommendation(s)?",
            "existing_investments": "Investments and Life/Health Polices",
            "existing_policies": "Investments and Life/Health Polices",
        },
        "budget": {
            "is_reserve_half_assets": "Is the budget you set aside more than 50% of your assets or surplus?",
            "total_annual": "Budget",
            "total_single": "Budget",
            "deviation_reason":"Please state reason"
        },
        "customer_knowledge_assessment": {
            "assessment_outcome": "Customer Knowledge Assessment"
        },
        "risk_profile_questionnaire": {
            "grade": "Risk Profile Questionnaire",
            "own_choice": "Risk Profile Questionnaire"
        },
        "accredited_investor_declaration": {
            "is_accredited_investor": "Do you want to be assessed and treated like an Accredited Investor if you qualify?",
            "net_personal_assets": "question a",
            "financial_assets": "question b",
            "income": "question c"
        },
        "needs_analysis":{
            "total_death": '',
            "total_disability": '',
            "total_ilness": '',
            // Medical and hospitalisation costs
            "Medical": {
                "dependants_required_medical": '',
                "hospital_surgical_coverage": '',
                "daily_room_board": '',
                "surgical_expenses": '',
                "hospital_income_required": '',
                "ride": '',
                "other_benefit": '',
                "preferred_ward_type": '',
                "preferred_hospital_type": '',
            },
            "plan_total_amount_outpatient": '',
            "total_amount_plan_dental": '',
            "total_accident": '',
            "total_amount_ltc": '',
            // Planning for dependant's protection
            "plan_dependant_protection":{
                'dependants_total_all':''
            },
            // Other Protection
            "protection_goals_others": '',
            "education_fund": '',
            // retirement
            "total": '',
            // Other Wealth Accumulation & Preservation Goals
            "saving_accumulation_goals_others":''
        },
        "basis_recommendations": {
            "is_deviation": "Deviation(s)",
            "deviation_reason": "Please state deviation(s) reason",
            "product_recommendations_option": "Please select at least one product",
        },
        "switching_replacement_policy": {
            "is_purchase": "Do you intend to purchase a policy to replace in part or full any existing or recently terminated insurance policy or investment product from any insurer or other financial institution?",
            "is_advise_replace_policy": "Is the replacement of policy advised by the representative?",
            "is_explained": "My/Our representative has explained the following to my/our satisfaction in the event a replacement of policy should take place.",
            "replacement_declaration": "I have also explained the basis for policy replacement and why the replacement of policy is suitable for the client below:",
            "products": ""
        },
        "client_acknowledgement_declaration": {
            "is_agree": "Question 1)",
            "wish_amendment": "Question 1) Please provide details",
            "is_investment_linked_plan": "Question 2a)",
            // 2a 为Y， 2b 2c 必填
            "is_experience": "Question 2b)",
            "is_aware_ilp_suitability": "Question 2c)",
            // 3）
            "is_recommended_plan_nature": "Question 3)",
            // 5
            "is_read_understood": "Question 5)",
            // 6 ---2023/10/20改为非必填
            // "option_call": "Question 6)",
            // 7
            "is_beneficial_ownership_arrangement": "Question 7)",
            "beneficial_owner_name": "Question 7)",
            // 8
            "is_pep": "Question 8)",
            "pep_name": "Question 8)",
            // 9
            "is_proposer_payor": "Question 9)",
            "payor_name": "Question 9)",
            // 10
            "fund_source_inheritance": "Question 10)"
        }
    }