/*
 *@Author: 段彩翔
 *@Date: 2022-04-11 16:05:15
 *@Description: 多选框组件
*/
<template>
	<div :class="checkBoxType === 'multiple' ? 'boxBorder pl30px mt30px' : ''">
		<el-checkbox @change="checkSingle" v-model="singleCheckedChild" :label="label" v-if="checkBoxType === 'single'" :disabled="disabled">
			<br>
			<slot />
		</el-checkbox>
		<!-- notice节点 -->
		<el-checkbox-group v-model="checkedDataChild" v-if="checkBoxType === 'multiple'" :disabled="disabled" class="multipleCheckClass" style="line-height: 90px;">
			<el-checkbox v-for="item in content" :label="item" :key="item">{{item}}</el-checkbox>
		</el-checkbox-group>
		<!-- 1.2节点 -->
		<el-checkbox-group v-model="checkedDataChild" v-if="checkBoxType === 'list'" @change="check" :disabled="disabled" class="listCheckClass">
			<el-checkbox v-for="item in content" :label="item" :key="item">{{item}}</el-checkbox>
		</el-checkbox-group>
		<!-- 1.10节点 标准standardList -->
		<el-checkbox-group v-model="checkedDataChild" :max="max" v-if="checkBoxType === 'standardList'" class="listCheckClass">
			<el-checkbox v-for="item in content" :label="item.answer" :key="item.answer" @change="checkOne(item)">{{item.answer}}</el-checkbox>
		</el-checkbox-group>
		<!-- 3.2节点 -->
		<el-checkbox-group v-model="checkedDataChild" :max="max" v-if="checkBoxType === 'listForm'" @change="checkOther" class="listCheckClass">
			<el-checkbox v-for="item in content" :label="item" :key="item.value">{{item.label}}</el-checkbox>
		</el-checkbox-group>
		<el-checkbox-group v-model="checkedDataChild" :max="max" v-if="checkBoxType === 'multipleNoBorder'" @change="checkOther" class="multipleCheckClass">
			<el-checkbox v-for="item in content" :label="item" :key="item.value">{{item.label}}</el-checkbox>
		</el-checkbox-group>
	</div>
</template>

<script>

export default {
	components: {},
	props: {
		// 多选框类型：single、multiple、list、standardList
		checkBoxType: {
			type: String,
			default: 'single'
		},
		// 单个多选框是否选中
		singleChecked: {
			type: String,
			default: 'true'
		},
		// 多选-label内容
		content: {
			type: Array,
			default: () => []
		},
		// 最多可选
		max: {
			type: Number
		},
		// 选中数据
		checkedData: {
			type: Array,
			default: () => []
		},
		// 是否禁用
		disabled: {
			type: Boolean,
			default: false
		},
		// 类型为standardList，区分是第几个问题
		question_order: {
			type: String,
			default: ''
		},
		// 3.2 3个多选框区分
		distinguish: {
			type: String,
			default: '1'
		},
		// 保证勾选状态及时切换
		label: ''
	},
	data () {
		return {
			checkedDataChild: this.checkedData,
			singleCheckedChild: this.singleChecked === 'Y'
		};
	},
	computed: {},
	watch: {
		checkedData (val) {
			this.checkedDataChild = val
		}
	},
	methods: {
		checkSingle (val) {
			// this.max 为index
			this.$emit('changeStatus', val, this.max)
			this.$emit('update:singleChecked', val ? 'Y' : 'N')
		},
		check (val) {
			// this.checkedDataChild.lenght  1 选中  0 未选中
			// 参数1 选中对象 参数2 第几个问题  参数3 true选中  false未选中
			this.$emit('changeStatus', val, this.question_order, this.checkedDataChild.length === 1)
		},
		checkOne (val) {
			// 只允许选中1个，选中第2个时，取消第一个
			if (this.checkedDataChild.length > 1) this.checkedDataChild.splice(0, 1)
			this.$emit('changeStatus', val, this.question_order)
		},
		CheckAll (val) {
			this.checkedDataChild = val
		},
		checkOther (val) {
			this.$emit('changeStatus', val, this.distinguish)
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
	},
}
</script>
<style lang='css' scoped>
.el-checkbox >>> .el-checkbox__inner {
	width: 24px;
	height: 24px;
	border-radius: 15px;
	border: 2px solid black;
}
.el-checkbox >>> .el-checkbox__inner::after {
	content: '';
	width: 12px;
	height: 5px;
	border: 2px solid white;
	border-top: transparent;
	border-right: transparent;
	display: block;
	position: absolute;
	top: 6px;
	left: 4px;
	transform: rotate(-45deg) !important;
	border-radius: 0px;
	background: none;
}
.el-checkbox >>> .el-checkbox__input.is-checked .el-checkbox__inner {
	background-color: #ff9941;
	border-color: #ff9941;
}
.el-checkbox >>> .el-checkbox__input.is-checked + .el-checkbox__label {
	color: #ff9941;
}
.el-checkbox >>> .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
	border-color: #ffffff;
}
.boxBorder {
	border: 1px solid #ff9941;
	height: 100px;
}
.multipleCheckClass .el-checkbox {
	margin-top: 10px;
}
.listCheckClass .el-checkbox {
	line-height: 50px;
	width: 100%;
}
.listCheckClass >>> .el-checkbox__label {
	white-space: pre-wrap;
}
</style>