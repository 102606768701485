<template>
	<!-- 2.2节点 preview页面 -->
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Preview</Navbar>
		<User :headerDate="headerDate"></User>
		<div class="p30px h80" style="overflow: scroll;">
			<common-proposal></common-proposal>
		</div>
	</div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import User from "../components/User.vue";
import { getSystemPrompts } from "@/utils/kyc"
import CommonProposal from '../components/CommonProposal.vue';

export default {
	components: { Navbar, User, CommonProposal },
	data () {
		return {
			headerDate: {},
			kycAllData: {},
			dataValue: {},
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () { },
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.dataValue = this.kycAllData.kyc.client[this.kycClient]
		// user值
		this.headerDate = {
			gender: this.dataValue.personal_information.gender,
			title: this.dataValue.personal_information.title,
			name: this.dataValue.personal_information.full_name,
			cka: this.dataValue.customer_knowledge_assessment.assessment_outcome,
			ai: this.dataValue.accredited_investor_declaration.is_ai,
			grade: this.dataValue.risk_profile_questionnaire.is_agree_profile === 'N' ? this.dataValue.risk_profile_questionnaire.own_choice : this.dataValue.risk_profile_questionnaire.grade
		}
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		if (localStorage.getItem('systemPrompts')) {
			setTimeout(() => {
				getSystemPrompts(JSON.parse(localStorage.getItem('systemPrompts')), this.$store, this.$confirm)
			}, 100);
		}
	},
}
</script>
<style lang='scss' scoped>
</style>