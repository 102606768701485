<template>
	<div class=''>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Monthly CPF Outflow</Navbar>
		<div class="wrapper">
			<!-- 表格 -->
			<el-table :data="CPFTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="CPF Outflow">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.AmountShow}}</span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="CPFTableData" @updateArr="updateArr"></AmountInput>
					</template>
				</el-table-column>
			</el-table>
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total S$ <span style="color: black;">{{cpfOutflowTotal}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import AmountInput from "../../components/amountInput.vue";

export default {
	components: { Navbar, AmountInput },
	data () {
		return {
			CPFTableData: [
				{
					Amount: '0',
					name: 'Mortgage – CPF Ordinary Account',
					value: 'mortgage_cpf_oa',
				}, {
					Amount: '0',
					name: 'Investment - CPF Ordinary Account',
					value: 'investment_cpf_oa',
				}, {
					Amount: '0',
					name: 'Investment - CPF Special Account',
					value: 'investment_cpf_sa',
				}, {
					Amount: '0',
					name: 'CPF Medisave Account',
					value: 'cpf_ma_outflow',
				}
			],
			disabled: false,
			kycAllData: {}
		};
	},
	computed: {
		cpf_outflow_total () {
			let total = 0
			this.CPFTableData.map(res => {
				total += parseFloat(res.Amount || 0)
			})
			if (isNaN(total)) total = ''
			return (Math.round(total * 100) / 100).toFixed(10).replace(/(0+)$/g, '')
		},
		cpfOutflowTotal () {
			return this.$formatMoney((Math.round(this.cpf_outflow_total * 100) / 100).toFixed(2))
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.$forceUpdate()
			this.CPFTableData = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			if (!this.disabled) {
				this.CPFTableData.map(res => { this.kycAllData.kyc.client[this.kycClient].cashflow[res.value] = res.Amount })
				this.kycAllData.kyc.client[this.kycClient].cashflow.cpf_outflow_total = this.cpf_outflow_total
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.CPFTableData.map((key, i) => {
			key.Amount = this.kycAllData.kyc.client[this.kycClient].cashflow[key.value]
			key.AmountShow = 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.index = i
		})
		this.disabled = this.$router.currentRoute.fullPath.includes('View')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
}
</script>
<style lang='css' scoped>
span {
	line-height: 24px;
}
</style>