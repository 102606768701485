<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Outpatient medical expenses protection</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :row-class-name="$tableRowClassName">
				<el-table-column prop="name" label="Outpatient medical expenses protection">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<AmountInput :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr">
						</AmountInput>
					</template>
				</el-table-column>
			</el-table>
            <!-- total -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total Amount to plan for S$ <span style="color: black;">{{$formatMoney(planTotal)}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';

export default {
	components: { Navbar, AmountInput },
	data () {
		return {
            assetsTableData: [
				{
					Amount: '',
					name: 'Amount I would like to have',
					value: 'want_amount_outpatient',
				}, {
					Amount: '',
					name: 'Less: Existing insurance',
					value: 'less_existing_insurance_outpatient',
				}
			],
			kycAllData: {}
		};
	},
	computed: {
        plan_total_amount_outpatient () {
            let num1, num2, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'less_existing_insurance_outpatient') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'want_amount_outpatient') num2 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 - num2).toFixed(10)
			return sum
        },
        planTotal () {
			return parseFloat(this.plan_total_amount_outpatient).toFixed(2)
        }
    },
	watch: {},
	methods: {
        updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.assetsTableData.map(res => { this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis[res.value] = res.Amount })
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.plan_total_amount_outpatient = this.plan_total_amount_outpatient
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		// 若有值 则赋值
		this.assetsTableData.map((key, i) => {
            key.Amount = dataValue0.needs_analysis[key.value]
			key.AmountShow = 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.index = i
        })
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
</style>