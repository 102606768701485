<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Main Plan</Navbar>
		<div class="h90 displayFlex flexColumn boxSizing p30px OverflowYAuto">
			<!-- 上  product -->
			<div class="mb50px h30">
				<p style="color: #3C3C3C;font-size: 26px;">{{product.product_name}}</p>
				<div class="mt20px ml20">
					<!-- 图片 -->
					<!-- <img :src="product.product_img" alt="" width="200px" height="200px"> -->
					<!-- company -->
					<div class="companyStyle ml100px">{{product.company}}</div>
					<!-- 描述 -->
					<div class="mt20px" style="color: #5D5D5D;line-height: 24px;">{{product.product_description}}</div>
				</div>
			</div>
			<!-- 中  form -->
			<div class="">
				<!-- CIS -->
				<el-form :model="product" v-if="product.product_line_code === 'CI'" class="my-form addWidth">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Lump Sum Amount" prop="benefit_amount">
								<amount-input-form :currentObj="product" :inputValue="product.benefit_amount" fieldName="benefit_amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Term / Duration" prop="policy_term">
								<el-input v-model="product.policy_term" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Regular Investment Scheme (RIS)" prop="premium_amount">
								<amount-input-form :currentObj="product" :inputValue="product.premium_amount" fieldName="premium_amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Premium Mode" prop="premium_mode">
								<el-select v-model="product.premium_mode" placeholder="">
									<el-option v-for="item in CISPremiumMode" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Need(s) for" prop="product_for">
								<el-select v-model="product.product_for" placeholder="">
									<el-option v-for="item in needsFor" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Model of Payment" prop="model_of_payment">
								<el-select v-model="product.model_of_payment" placeholder="">
									<el-option v-for="item in payment" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- 除了CI之外的 -->
				<el-form :model="product" v-else class="my-form">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Benefit Amount" prop="benefit_amount">
								<amount-input-form :currentObj="product" :inputValue="product.benefit_amount" fieldName="benefit_amount" @updateArr="updateArr"></amount-input-form>
								<!-- <span style="position: relative;left: 70%;top: -40px;">Please refer to BI</span> -->
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Policy Term" prop="policy_term">
								<el-input v-model="product.policy_term" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Premium Amount" prop="premium_amount">
								<amount-input-form :currentObj="product" :inputValue="product.premium_amount" fieldName="premium_amount" @updateArr="updateArr"></amount-input-form>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Premium Mode" prop="premium_mode">
								<el-select v-model="product.premium_mode" placeholder="">
									<el-option v-for="item in premiumMode" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Need(s) for" prop="product_for">
								<el-select v-model="product.product_for" placeholder="">
									<el-option v-for="item in needsFor" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<p style="border-bottom: 6px solid #C1C1C1;" class="mb50px"></p>
			</div>
			<!-- 下  Riders Funds -->
			<div class="">
				<el-tabs v-model="activeName" :stretch="true" style="height: 300px;width: 100%;" class="my-tab">
					<el-tab-pane label="Riders" name="first">
						<div v-for="(item, index) in respRider.rider_products" :key="index" class="textStyle">
							<span>{{item.product_name}}</span>
							<el-button plain style="height: 35px;" @click="AddRiders(item)" v-if="!item.alreadyExists">Add</el-button>
						</div>
					</el-tab-pane>
					<el-tab-pane label="Funds" name="second">
						<!-- ILP 和 CIS 添加查询功能，其余保持原样 -->
						<!-- <template v-if="product.product_type_name === 'ILP' || product.product_line_code === 'CI'"> -->
						<div @click="openDialog" class="circle circle-single" style="position: absolute;right: 10px;">
							<i class="el-icon-plus"></i>
						</div>
						<div v-for="(item, index) in product.fund_list" :key="index" class="textStyle">
							<span>{{item.fund_name}}</span>
						</div>
						<!-- </template>
						<template v-else>
							<div v-for="(item, index) in respRider.fund_products" :key="index" class="textStyle">
								<span>{{item.product_name}}</span>
								<el-button plain style="height: 35px;" v-if="!item.alreadyExists">Add</el-button>
							</div>
						</template> -->
					</el-tab-pane>
				</el-tabs>
				<p style="border-bottom: 6px solid #C1C1C1;width: 100%;" class="mb50px mt40px"></p>
			</div>
			<div class="displayFlex flexJCCenter">
				<el-button type="danger" @click="deleteMainPlan" style="background-color: #F74323;">Remove Main Plan</el-button>
			</div>
		</div>
		<!-- rider添加 -->
		<ElementDialog :Title="Dialog.title" :Visible.sync="Dialog.visible" :showClose="false" class="my-dialog-title">
			<el-form slot="content" :model="detailsForm" class="my-form">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Benefit Amount" prop="benefit_amount">
							<amount-input-form :currentObj="detailsForm" :inputValue="detailsForm.benefit_amount" fieldName="benefit_amount" @updateArr="updateArr2"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Policy Term" prop="policy_term">
							<el-input v-model="detailsForm.policy_term" type="number"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Premium Amount" prop="premium_amount">
							<amount-input-form :currentObj="detailsForm" :inputValue="detailsForm.premium_amount" fieldName="premium_amount" @updateArr="updateArr2"></amount-input-form>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Premium Mode" prop="premium_mode">
							<el-select v-model="detailsForm.premium_mode" placeholder="">
								<el-option v-for="(item, index) in premiumMode" :key="index" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="SaveAdd">Confirm</el-button>
				<el-button @click="Dialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
		<!-- fund添加 -->
		<!-- v-if="product.product_type_name === 'ILP' || product.product_line_code === 'CI'" -->
		<ChooseFunds ref="ChooseFunds" :searchObj="searchObj" :product="product" @add_product_list="add_funds_list"></ChooseFunds>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import CheckBox from "../../components/checkBox.vue";
import { findRecommendationProduct } from '@/api/KYC/index'
import { Message } from 'element-ui';
import ElementDialog from "../../../../components/ElementDialog/index.vue";
import ChooseFunds from "../detail/ChooseFunds.vue";
import AmountInputForm from '../../components/amountInputForm.vue';

export default {
	components: { Navbar, CheckBox, ElementDialog, ChooseFunds, AmountInputForm },
	data () {
		return {
			kycAllData: {},
			dataValue: {},
			product: {},
			premiumMode: global.premiumMode,
			CISPremiumMode: global.CISPremiumMode,
			payment: global.payment,
			needsFor: [
				{ code: 'myself', name: 'Myself' },
			],
			activeName: 'first',
			respRider: {
				rider_products: [],
				fund_products: []
			},
			// dialog
			Dialog: {
				visible: false,
				status: "Add",
				title: ''
			},
			detailsForm: {
				benefit_amount: '',
				policy_term: '',
				premium_amount: '',
				premium_mode: ''
			},
			// true 表示从preview页面打开  false表示从2.2主产品页面打开
			noRecommendation: false,
			currentProduct: {},
			searchObj: {},
			fundObj: {}
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.product = arr
		},
		updateArr2 (arr) {
			this.detailsForm = arr
		},
		formatRound (amount) {
			return 'S$ ' + (Math.round(amount * 100) / 100).toFixed(2)
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			// 1.form表单赋值
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 获取riders和funds
		async getRecommendation () {
			let res = await findRecommendationProduct(this.searchObj)
			this.respRider = res.content
			this.whetherAdded()
		},
		// 判断是否已经添加，若添加则不展示 Add按钮
		whetherAdded () {
			if (this.respRider.rider_products) {
				let arr = []
				this.product.rider_list.map(rider => arr.push(rider.product_id))
				this.respRider.rider_products.map(n => {
					n.alreadyExists = arr.includes(n.infinitum_product_code)
				})
			}
			if (this.respRider.fund_products) {
				let arr = []
				this.product.fund_list.map(rider => arr.push(rider.fund_id))
				this.respRider.fund_products.map(n => {
					n.alreadyExists = arr.includes(n.infinitum_product_code)
				})
			}
		},
		// 添加Riders
		AddRiders (val) {
			// 1.判断是否可以添加
			if (!this.isCanAdd(val.financial_goals.split('|'))) {
				Message.error('The number of products being added reaches its maximum quantity.')
			} else {
				this.currentProduct = val
				this.Dialog.visible = true
				this.Dialog.title = val.product_name
			}
		},
		// 判断是否可以添加
		isCanAdd (goals) {
			const nums = goals.map(goal => {
				const target = this.dataValue.basis_recommendations.product_recommendations_option[
					this.$router.currentRoute.query.proposal
				].protection_needs_setup.find(item => item.protection_needs === goal)
				if (target) {
					return target.select_products_number;
				} else {
					return 0;
				}
			})
			return nums.every(item => item < 999)
		},
		// 添加rider 保存详细信息
		SaveAdd () {
			let obj = {
				...this.detailsForm,
				needs_analysis_list: this.currentProduct.financial_goals.split('|'),
				product_id: this.currentProduct.infinitum_product_code,
				product_name: this.currentProduct.product_name,
				company: this.currentProduct.provider_name,
				is_select_product: this.noRecommendation ? 'Y' : 'N',
				is_recommendation: this.noRecommendation ? 'N' : 'Y',
				product_description: this.currentProduct.product_description,
				product_img: this.currentProduct.product_img,
				provider_logo_url: this.currentProduct.provider_logo_url
			}
			this.product.rider_list.push(obj)
			this.whetherAdded()
			this.Dialog.visible = false
		},
		openDialog () {
			console.log('openDialog');
			this.$refs.ChooseFunds.Dialog.visible = true
		},
		// 添加funds
		add_funds_list (val) {
			this.product.fund_list.push(val)
		},
		setParam () {
			let grade = this.dataValue.risk_profile_questionnaire.is_agree_profile === 'N' ? this.dataValue.risk_profile_questionnaire.own_choice : this.dataValue.risk_profile_questionnaire.grade
			this.searchObj = {
				infinitum_product_code: this.product.product_id,
				financial_goals: this.product.financial_goals,
				grade: this.product.is_diy === 'N' ? '' : grade
			}
			this.fundObj = {
				infinitum_product_code: this.product.product_id,
				financial_goals: this.product.financial_goals,
				grade: grade
			}
		},
		setNeedsfor () {
			// 根据1.3节点 判断是否有依赖人
			if (this.dataValue.dependants_list.is_dependants === 'Y') {
				this.dataValue.dependants_list.dependants_details.map(res => {
					this.needsFor.push({ code: res.dependant_id, name: res.name })
				})
			}
		},
		// 删除产品
		deleteMainPlan () {
			this.dataValue.basis_recommendations.product_recommendations_option[this.$router.currentRoute.query.proposal].product_list.splice(this.$router.currentRoute.query.product, 1)
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			// 不需要调用校验金额方法
			localStorage.removeItem('systemPrompts')
			this.$router.go(-1)
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.dataValue = this.kycAllData.kyc.client[this.kycClient]
		// 若有值 则赋值  proposalIndex第几个tab页  productIndex第几条数据
		let proposalIndex = this.$router.currentRoute.query.proposal
		let productIndex = this.$router.currentRoute.query.product
		localStorage.setItem('systemPrompts', JSON.stringify(this.$router.currentRoute.query))
		this.product = this.dataValue.basis_recommendations.product_recommendations_option[proposalIndex].product_list[productIndex]
		console.log(this.product, 'product');
		// 设置查询参数
		this.setParam()
		// 设置needs for 下拉选值
		this.setNeedsfor()
		this.noRecommendation = !this.$router.currentRoute.query.hasOwnProperty('proposal')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.getRecommendation()
	},
}
</script>
<style lang='css' scoped>
.companyStyle {
	border: 2px solid #2680eb;
	height: 30px;
	font-size: 14px;
	color: #2680eb;
	text-align: center;
	line-height: 30px;
	width: fit-content;
	width: -webkit-fit-content;
	width: -moz-fit-content;
}
.textStyle {
	border-bottom: 1px solid #0b0b0b33;
	line-height: 50px;
	padding-left: 20px;
	color: #646464;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.el-tabs >>> .el-tabs__content {
	height: 280px;
	overflow: scroll;
}
.addWidth >>> .el-input__inner {
	padding-left: 260px;
}
.addWidth >>> .el-input--prefix .el-input__inner {
	padding-left: 260px;
}
</style>