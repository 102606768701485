<template>
	<div class='h100'>
		<Navbar :showBack="true" backgroundColor="#FF9941" color="#fff">Personal Information</Navbar>
		<!-- 上下布局 -->
		<div class="wrapper h90 OverflowYAuto">
			<!-- 上 头像 名称 成绩 -->
			<div class="displayFlex user" style="padding: 20px 50px">
				<img :src="imgs[headerDate.gender]" alt="" width="200px" height="200px">
				<img :src="imgs['Ai']" alt="" v-if="headerDate.ai === 'Y'" class="marker">
				<!-- name mobile Email -->
				<div class="w60 center-style">
					<span style="color: #646464;font-size: 32px;font-weight: 400; padding-bottom: 50px;">{{headerDate.title}} {{headerDate.name}}</span>
					<img :src="imgs[!!headerDate.cka && '1,2'.indexOf(headerDate.cka) > -1 ? 'CKA' : 'N']" class="w80px ml20px" alt="">
					<p style="margin-top: 30px;margin-bottom: 30px;">Mobile {{headerDate.mobile}}</p>
					<p>Email {{headerDate.email}}</p>
				</div>
				<!-- 成绩 -->
				<div class="gradeBoxsNormal" v-show="headerDate.level">
					<span class="mt10px">{{headerDate.grade}}</span>
				</div>
			</div>
			<!-- 下 personal details -->
			<div style="padding: 30px 80px;">
				<MyTitle Title="Personal Details" class="mb20px" />
				<el-form label-position="left" label-width="400px" :model="formData">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Full Name (as in NRIC)">
								<span>{{formData.personal_information.full_name}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Preferred Name">
								<span>{{formData.personal_information.surname}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Identity Type">
								<span>{{formData.personal_information.identity_type}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="NRIC / Passport / FIN">
								<span>{{formData.personal_information.nric_passport_fin}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Nationality">
								<span>{{formData.personal_information.nationality}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Others Nationality Name" v-if="formData.personal_information.nationality === 'Others'">
								<span>{{formData.personal_information.nationality_content}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Race">
								<span>{{formData.personal_information.race}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Others Race Name" v-if="formData.personal_information.race === 'Others'">
								<span>{{formData.personal_information.race_content}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Proficient in spoken language">
								<span>{{formData.personal_information.language_spoken}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Others Proficient in spoken language" v-if="formData.personal_information.language_spoken === 'Others'">
								<span>{{formData.personal_information.language_spoken_content}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Proficient in written language">
								<span>{{formData.personal_information.language_writen}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Others Proficient in written language" v-if="formData.personal_information.language_writen === 'Others'">
								<span>{{formData.personal_information.language_writen_content}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Date of Birth" v-if="formData.personal_information.date_of_birth">
								<span>{{formData.personal_information.date_of_birth | dateFmt('MM/DD/YYYY')}}</span>
							</el-form-item>
							<el-form-item label="Date of Birth" v-else>
								<span>{{formData.personal_information.date_of_birth}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Marital Status">
								<span>{{formData.personal_information.marial_status}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Email">
								<span>{{formData.personal_information.email}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- Contact No -->
				<MyTitle Title="Contact No" :titleIndex="2" />
				<el-form label-position="left" label-width="400px" :model="formData">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Mobile">
								<span>{{formData.personal_information.contact_no_mobile_area + ' ' + formData.personal_information.contact_no_mobile}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Home">
								<span>{{formData.personal_information.contact_no_home_area + ' ' + formData.personal_information.contact_no_home}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Office">
								<span>{{formData.personal_information.contact_no_office_area + ' ' + formData.personal_information.contact_no_office}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- Residential Address -->
				<MyTitle Title="Residential Address" :titleIndex="2" />
				<el-form label-position="left" label-width="400px" :model="formData">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Block & Street Name">
								<span>{{formData.personal_information.residential_street}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Unit No.">
								<span>{{formData.personal_information.residential_unit_no}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Building Name">
								<span>{{formData.personal_information.residential_building_name}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Country">
								<span>{{formData.personal_information.residential_country}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Postal/Zip Code">
								<span>{{formData.personal_information.residential_zip_code}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="Mailing Address Same As Residential Address:" label-width="600px">
								<span v-if="formData.personal_information.address_same_as_residential === 'Y'">Yes</span>
								<span v-if="formData.personal_information.address_same_as_residential === 'N'">No</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- Mailing Address -->
				<MyTitle Title="Mailing Address" :titleIndex="2" />
				<el-form label-position="left" label-width="400px" :model="formData">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Block & Street Name">
								<span>{{formData.personal_information.mailing_street}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Unit No.">
								<span>{{formData.personal_information.mailing_unit_no}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Building Name">
								<span>{{formData.personal_information.mailing_building_name}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Country">
								<span>{{formData.personal_information.mailing_country}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Postal/Zip Code">
								<span>{{formData.personal_information.mailing_zip_code}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- Education -->
				<MyTitle Title="Education" :titleIndex="2" />
				<el-form label-position="left" label-width="400px" :model="formData">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Educational Level">
								<span>{{formData.personal_information.education_level}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- Employment -->
				<MyTitle Title="Employment" :titleIndex="2" />
				<el-form label-position="left" label-width="400px" :model="formData">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Employment Status">
								<span>{{formData.personal_information.employment_status}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Other Employment Status">
								<span v-if="formData.personal_information.employment_status === 'Others'">{{formData.personal_information.employment_status_content}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Company Name">
								<span>{{formData.personal_information.employer}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Occupation">
								<span>{{formData.personal_information.occupation}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Block & Street Name">
								<span>{{formData.personal_information.employment_street}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Unit No.">
								<span>{{formData.personal_information.employment_unit_no}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Building Name">
								<span>{{formData.personal_information.employment_building_name}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Country">
								<span>{{formData.personal_information.employment_country}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Postal/Zip Code">
								<span>{{formData.personal_information.employment_zip_code}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- Health Condition -->
				<MyTitle Title="Health Condition" />
				<div style="color: #6C6C6C">
					<p>Do you smoke?</p>
					<p v-if="formData.personal_information.smoker === 'Y'">Yes</p>
					<p v-if="formData.personal_information.smoker === 'N'">No</p>
					<p>Do you or any of your family members have any medical condition that requires regular attention from clinic or hospital?</p>
					<p v-if="formData.personal_information.heath_condition === 'Y'">Yes</p>
					<p v-if="formData.personal_information.heath_condition === 'N'">No</p>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Navbar from '../../../../../components/Navbar.vue';
import MyTitle from '../../../components/MyTitle.vue';
import { queryList } from '@/api/KYC/index.js'

export default {
	components: { Navbar, MyTitle },
	data () {
		return {
			headerDate: {},
			imgs: {
				CKA: require('@/assets/img/cka/CKA.passed.png'),
				Male: require('@/assets/img/avatar/avatar.mr.lg.png'),
				Female: require('@/assets/img/avatar/avatar.mrs.lg.png'),
				Ai: require('@/assets/img/ai/AI.png')
			},
			formData: {},
			nationalityOptions: [],
			countryCodes: []
		};
	},
	computed: {},
	watch: {},
	methods: {
		async setCountry () {
			this.nationalityOptions = []
			this.countryCodes = []
			let res = await queryList({})
			res.content.countryList.rows.map(item => {
				this.countryCodes.push({
					code: item.id,
					name: item.englishShortName
				})
			})
			res.content.nationalityList.rows.map(item => {
				this.nationalityOptions.push({
					code: item.id,
					name: item.nationality
				})
			})
			let nationalityArr = this.nationalityOptions.filter(item => {
				return item.code === this.formData.personal_information.nationality
			})
			if (nationalityArr && nationalityArr.length > 0) this.formData.personal_information.nationality = nationalityArr[0].name
			
			let residential_country_arr = this.countryCodes.filter(item => {
				return item.code === this.formData.personal_information.residential_country
			})
			if (residential_country_arr && residential_country_arr.length > 0) this.formData.personal_information.residential_country = residential_country_arr[0].name

			let mailing_country_arr = this.countryCodes.filter(item => {
				return item.code === this.formData.personal_information.mailing_country
			})
			if (mailing_country_arr && mailing_country_arr.length > 0) this.formData.personal_information.mailing_country = mailing_country_arr[0].name

			let employment_country_arr = this.countryCodes.filter(item => {
				return item.code === this.formData.personal_information.employment_country
			})
			if (employment_country_arr && employment_country_arr.length > 0) this.formData.personal_information.employment_country = employment_country_arr[0].name
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		this.formData = JSON.parse(JSON.stringify(dataValue0))
		this.setCountry()
		// header值
		let grade = dataValue0.risk_profile_questionnaire.is_agree_profile == 'N' ? dataValue0.risk_profile_questionnaire.own_choice : dataValue0.risk_profile_questionnaire.grade
		this.headerDate = {
			gender: dataValue0.personal_information.gender,
			title: dataValue0.personal_information.title,
			name: dataValue0.personal_information.full_name,
			cka: dataValue0.customer_knowledge_assessment.assessment_outcome,
			ai: dataValue0.accredited_investor_declaration.is_ai,
			grade: grade,
			mobile: dataValue0.personal_information.contact_no_mobile,
			email: dataValue0.personal_information.email,
			level: global.gradeLevel.filter(item => item.name == grade).length > 0 ? global.gradeLevel.filter(item => item.name == grade)[0].code : ''
		}
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
.marker {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 100px;
	left: 210px;
	border-radius: 20px;
	border-color: grey;
	color: #fff;
	font-size: 124px;
	display: flex;
	text-align: center;
	line-height: 40px;
}
.center-style {
	color: #707070;
	font-size: 20px;
	padding: 20px 60px;
	margin: 10px 50px;
}
.user {
	border-bottom: 6px solid #e8e8e8;
}
.gradeBoxsNormal {
	width: 140px;
	height: 140px;
	margin: 0 10px;
	padding: 0 10px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 5px solid red;
	color: red;
}
.gradeBoxsNormal span {
	font-weight: bold;
	font-size: 20px;
}
h1 {
	font-size: 60px;
}
.el-form >>> .el-form-item__label,
.el-form >>> .el-form-item__content,
p {
	font-size: 18px !important;
}
p {
	line-height: 40px;
}
</style>