
export default {
  /**
   * 根据日期算年龄
   * birthday  2021-02-02
   * 返回年龄
   * **/
  getAgebyDate (birthday) {
    if (birthday) {
      birthday = birthday.split('-');
      // 新建日期对象
      let date = new Date();
      // 今天日期，数组，同 birthday
      let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
      // 分别计算年月日差值
      let age = today.map((val, index) => {
        return val - birthday[index]
      })
      // 当天数为负数时，月减 1，天数加上月总天数
      if (age[2] < 0) {
        // 简单获取上个月总天数的方法，不会错
        let lastMonth = new Date(today[0], today[1], 0)
        age[1]--
        age[2] += lastMonth.getDate()
      }
      // 当月数为负数时，年减 1，月数加上 12
      if (age[1] < 0) {
        age[0]--
        age[1] += 12
      }
      return age[0]
      // console.log(age[0] + '岁' + age[1] + '月' + age[2] + '天');
    }
  },
  /**
   * 获取当前日期
   * 返回格式 yyyy-MM-dd
   */
  getCurrentDate () {
    let date = new Date()
    let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    if (today[1] < 10) today[1] = '0' + today[1]
    if (today[2] < 10) today[2] = '0' + today[2]
    return today[0] + '-' + today[1] + '-' + today[2]
  }
}
