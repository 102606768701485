import CashflowDetail from '../views/KYC/KYC&FNA/CashflowDetail'
import NeedAnalysisDetail from '../views/KYC/KYC&FNA/NeedAnalysisDetail' 
import Recommendations from '../views/KYC/Recommendations/index'
export default [
    // KYC汇总页面
    {
        path: '/kyc',
        name: 'kyc',
        component: () => import('../pages/searchComponents/KYCDetailView.vue'),
    },
    {
        path: '/NoticeToClient',
        name: 'NoticeToClient',
        component: () => import('../views/KYC/Notice.vue'),
    },
    {
        path: '/BeforeStart',
        name: 'BeforeStart',
        component: () => import('../views/KYC/BeforeStart.vue'),
    },
    {
        path: '/MyInfo',
        name: 'MyInfo',
        component: () => import('../views/KYC/components/MyInfo.vue'),
    },
    {
        path: '/KYC&FNA',
        name: 'KYC&FNA',
        component: () => import('../views/KYC/KYC&FNA/index.vue'),
    },
    // 1.5 Assets & Liabilities 详情页面
    {
        path: '/PersonalAssets',
        name: 'PersonalAssets',
        component: () => import('../views/KYC/KYC&FNA/AssetsLiabilitiesDetail/PersonalAssets.vue')
    },
    {
        path: '/InvestedAssets',
        name: 'InvestedAssets',
        component: () => import('../views/KYC/KYC&FNA/AssetsLiabilitiesDetail/InvestedAssets.vue')
    },
    {
        path: '/ShortTerm',
        name: 'ShortTerm',
        component: () => import('../views/KYC/KYC&FNA/AssetsLiabilitiesDetail/ShortTerm.vue')
    },
    {
        path: '/LongTerm',
        name: 'LongTerm',
        component: () => import('../views/KYC/KYC&FNA/AssetsLiabilitiesDetail/LongTerm.vue')
    },
    // 1.7 Existing 详情页面
    {
        path: '/ExistingInvestmentsList',
        name: 'ExistingInvestmentsList',
        component: () => import('../views/KYC/KYC&FNA/ExistingInvestmentsInsuranceDetail/ExistingInvestmentsList.vue')
    },
    {
        path: '/ExistingPoliciesList',
        name: 'ExistingPoliciesList',
        component: () => import('../views/KYC/KYC&FNA/ExistingInvestmentsInsuranceDetail/ExistingPoliciesList.vue')
    },
    // 1.6 Cashflow 详情页面
    {
        path: '/MonthlyCashInflow',
        name: 'MonthlyCashInflow',
        component: CashflowDetail.MonthlyCashInflow
    },
    {
        path: '/MonthlyCashOutflow',
        name: 'MonthlyCashOutflow',
        component: CashflowDetail.MonthlyCashOutflow
    },
    {
        path: '/MonthlyCPFInflow',
        name: 'MonthlyCPFInflow',
        component: CashflowDetail.MonthlyCPFInflow
    },
    {
        path: '/MonthlyCPFOutflow',
        name: 'MonthlyCPFOutflow',
        component: CashflowDetail.MonthlyCPFOutflow
    },
    // 1.12 Need Analysis 详情页面
    {
        path: '/IncomeProtectionUponDeath',
        name: 'IncomeProtectionUponDeath',
        component: NeedAnalysisDetail.IncomeProtectionUponDeath
    },
    {
        path: '/TotalPermanentDisability',
        name: 'TotalPermanentDisability',
        component: NeedAnalysisDetail.TotalPermanentDisability
    },
    {
        path: '/CriticalIllness',
        name: 'CriticalIllness',
        component: NeedAnalysisDetail.CriticalIllness
    },
    {
        path: '/MedicalAndHospitalisationCosts',
        name: 'MedicalAndHospitalisationCosts',
        component: NeedAnalysisDetail.MedicalAndHospitalisationCosts
    },
    {
        path: '/OutpatientMedicalExpensesProtection',
        name: 'OutpatientMedicalExpensesProtection',
        component: NeedAnalysisDetail.OutpatientMedicalExpensesProtection
    },
    {
        path: '/DentalExpensesProtection',
        name: 'DentalExpensesProtection',
        component: NeedAnalysisDetail.DentalExpensesProtection
    },
    {
        path: '/PersonalAccident',
        name: 'PersonalAccident',
        component: NeedAnalysisDetail.PersonalAccident
    },
    {
        path: '/LongTermCareProtection',
        name: 'LongTermCareProtection',
        component: NeedAnalysisDetail.LongTermCareProtection
    },
    {
        path: '/PlanningForDependantProtection',
        name: 'PlanningForDependantProtection',
        component: NeedAnalysisDetail.PlanningForDependantProtection
    },
    {
        path: '/ProtectionGoalsOthers',
        name: 'ProtectionGoalsOthers',
        component: NeedAnalysisDetail.ProtectionGoalsOthers
    },
    // Savings
    {
        path: '/EducationList',
        name: 'EducationList',
        component: NeedAnalysisDetail.EducationList
    },
    {
        path: '/EducationFund',
        name: 'EducationFund',
        component: NeedAnalysisDetail.EducationFund
    },
    {
        path: '/RetirementFund',
        name: 'RetirementFund',
        component: NeedAnalysisDetail.RetirementFund
    },
    {
        path: '/SavingAccumulationGoalsOthers',
        name: 'SavingAccumulationGoalsOthers',
        component: NeedAnalysisDetail.SavingAccumulationGoalsOthers
    },
    
    // 2.1 Personal Information
    {
        path: '/PersonalInformation',
        name: 'PersonalInformation',
        component: Recommendations.PersonalInformation
    },
    // 2.1 Trusted Individuals
    {
        path: '/TrustedIndividuals',
        name: 'TrustedIndividuals',
        component: Recommendations.TrustedIndividuals
    },
    // 2.2 Product Recommendations 详情页面
    {
        path: '/BasisOfRecommendation',
        name: 'BasisOfRecommendation',
        component: Recommendations.BasisOfRecommendation
    },
    // 2.2 Product Needs setup页面
    {
        path: '/NeedsView',
        name: 'NeedsView',
        component: Recommendations.NeedsView
    },
    {
        path: '/MainPlanView',
        name: 'MainPlanView',
        component: Recommendations.MainPlanView
    },
    {
        path: '/RiderView',
        name: 'RiderView',
        component: Recommendations.RiderView
    },
    {
        path: '/FundView',
        name: 'FundView',
        component: Recommendations.FundView
    },
    // 2.2 Preview页面
    {
        path: '/Presentation',
        name: 'Presentation',
        component: Recommendations.Presentation
    }
]