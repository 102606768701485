<template>
	<div :style="{textAlign:`${textAlign}`}" :class="{ hidden: hidden }" class="pagination-container">
		<el-pagination
			:background="background"
			:current-page.sync="pages.pageNum"
			:page-size.sync="pages.pageSize"
			:layout="layout"
			:page-sizes="pageSizes"
			:total="pages.total"
			v-bind="$attrs"
			@size-change="SetLimit"
			@current-change="SetPage" />
	</div>
</template>

<script>
export default {
	name: "Pagination",
	props: {
		textAlign: {
			type: String,
			default: 'right',
		},
		pages: {
			type: Object,
			default: () => {
				return {
					total: Number,
					pageSize: Number,
					pageNum: Number,
				}
			}
		},
		pageSizes: {
			type: Array,
			default () {
				return [10, 20, 30, 50];
			},
		},
		layout: {
			type: String,
			default: "total, sizes, prev, pager, next, jumper",
		},
		background: {
			type: Boolean,
			default: true,
		},
		hidden: {
			type: Boolean,
			default: false,
		},
		listKey: {
			type: String,
			default: "GetList"
		},
		pageKey: {
			type: String,
			default: "pages"
		},
		// 是否需要三层parent
		Multilevel: {
			type: Boolean,
			default: false,
		}
	},
	data () {
		return {
		}
	},
	computed: {},
	methods: {
		// 当前条
		SetLimit (val) {
			this.pages.pageSize = Number(val)
			this.SetPagination()
		},
		// 当前页
		SetPage (val) {
			this.pages.pageNum = Number(val)
			this.SetPagination()
		},
		// 发送状态
		SetPagination () {
			this.$SetPagination({
				pages: {
					pageNum: this.pages.pageNum,
					pageSize: this.pages.pageSize,
					total: this.pages.total
				},
				pointer: this.Multilevel ? this.$parent.$parent.$parent : this.$parent,
				pageKey: this.pageKey,
				listKey: this.listKey

			})
		}
	},
};
</script>

<style scoped>
.pagination-container {
	background: #fff;
	padding: 15px 20px 0 0;
	text-align: right;
	height: 45px;
}
.pagination-container.hidden {
	display: none;
}
</style>
