<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">{{navigationName}}</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :show-header="false">
				<el-table-column prop="name" label="" />
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<el-input v-if="scope.row.type === 'disabled'" v-model="scope.row.AmountShow" disabled></el-input>
						<el-input v-else-if="scope.row.type === 'num'" type="number" v-model="scope.row.Amount"></el-input>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :ifAmount="scope.row.ifAmount" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr" />
					</template>
				</el-table-column>
			</el-table>
			<!-- total_show -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total(surplus/shortfall) S$ <span style="color: black;">{{$formatMoney(total_show)}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from '../../../../components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';

export default {
	components: { Navbar, AmountInput },
	data () {
		return {
			navigationName: '',
			assetsTableData: [
				{
					Amount: '0',
					name: 'Lump sum needed in today’s value (PV1)',
					value: 'lump_sum_needed_today',
				},
				{
					Amount: '0',
					name: 'When is fund needed (n1)',
					value: 'when_fund_needed',
					type: 'num'
				}, {
					Amount: '0',
					name: 'Inflation rate (i1), (if applicable)',
					value: 'inflation_rate',
          ifAmount: false
				}, {
					Amount: '0',
					name: 'Total fund needed (FV1)',
					value: 'total_fund_needed',
					type: 'disabled'
				},
				{
					Amount: '0',
					name: 'Current resources (savings and investment) (PV2)',
					value: 'current_resources',
				}, {
					Amount: '0',
					name: 'Expected rate of return (i2)',
					value: 'expected_rate_return',
          ifAmount: false
				},
				{
					Amount: '0',
					name: 'Less: expected future value of resources (FV2)',
					value: 'less_expected_furture',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Less: expected cash value of life policies',
					value: 'less_expected_cash',
				}
			],
			kycAllData: {}
		};
	},
	computed: {
		total () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'less_expected_cash') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'less_expected_furture') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'total_fund_needed') num3 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 + num2 - num3).toFixed(10)
			return sum
		},
		total_show () {
			return parseFloat(this.total).toFixed(2)
		},
        total_fund_needed () {
            let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'when_fund_needed') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'lump_sum_needed_today') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'inflation_rate') num3 = parseFloat(res.Amount || 0)
			})
			sum = (Math.pow(1 + num3 / 100, num1) * num2).toFixed(10)
			return sum
        },
        less_expected_furture () {
            let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'when_fund_needed') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'current_resources') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'expected_rate_return') num3 = parseFloat(res.Amount || 0)
			})
			sum = (Math.pow(1 + num3 / 100, num1) * num2).toFixed(10)
			return sum
        },
	},
	watch: {
        total_fund_needed (val) {
			this.changeTableData(val, 'total_fund_needed')
		},
        less_expected_furture (val) {
			this.changeTableData(val, 'less_expected_furture')
		},
    },
	methods: {
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.saving_accumulation_goals_others.map(n => {
				if (n.index === this.$router.currentRoute.query.id) {
					this.assetsTableData.map(res => { n[res.value] = res.Amount })
					n.total = this.total
				}
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
        // 修改表格中的计算数据
		changeTableData (val, field) {
			this.assetsTableData.map(res => {
				if (res.value === field && !isNaN(val)) {
					res.Amount = val
					res.AmountShow = 'S$ ' + this.$formatMoney(parseFloat(val).toFixed(2))
				}
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 取kyc中的值
		let current_saving_goals_others = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.saving_accumulation_goals_others.filter(n => n.index === this.$router.currentRoute.query.id)[0]
		this.assetsTableData.map((key, i) => {
			key.Amount = current_saving_goals_others[key.value] || 0
			let show = this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.AmountShow = key.ifAmount === false ? show + ' %' : 'S$ ' + show
			key.index = i
		})
		this.navigationName = current_saving_goals_others.other_name
	},
}
</script>
<style lang='scss' scoped>
</style>