<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Education Fund</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" :show-header="false" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="type" label="Education Fund">
				</el-table-column>
				<el-table-column prop="Amount" label="Annual Amount" width="200px">
					<template slot-scope="scope">
						<span>{{scope.row.AmountShow}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="Lump Sum" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import AmountInput from '../../components/amountInput.vue';

export default {
	components: { Navbar, AmountInput },
	data () {
		return {
			assetsTableData: [],
			kycAllData: {}
		};
	},
	computed: {},
	watch: {},
	methods: {
		updataAllData () {
			// 返回时计算education total
			let total_education_fund = 0
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.education_fund.map(res => {
				total_education_fund += parseFloat(res.total || 0)
			})
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.total_education_fund = total_education_fund
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		}
		
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.education_fund
		dataValue0.map((res, i) => {
			let obj = {
				type: res.dependant_name,
				Amount: parseFloat(res.total || 0),
				AmountShow: 'S$ ' + this.$formatMoney((Math.round(parseFloat(res.total || 0) * 100) / 100).toFixed(2)),
				index: i,
				path: '/EducationFund?id=' + res.dependant_id
			}
			this.assetsTableData.push(obj)
		})
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
</style>