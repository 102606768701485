
export default {
  /**
   * 分页处理
   * pages == Object == 最新条和页
   * pointer == Object == this对象
   * pageKey == String == 条和页的对象名称
   * listKey == String == List接口名称
   * 调用:SetPagination=$SetPagination
   * **/
  SetPagination ({ pages, pointer, pageKey, listKey }) {
    pointer[pageKey] = pages
    pointer[listKey]()
  },
  // 状态字典项转换
  dictStatus (val) {
    if (val === '5') {
      return 'Invalid'
    } else if (val === '10') {
      return 'Draft'
    } else if (val === '15') {
      return 'Locked'
    } else if (val === '20' || val === '55') {
      return 'Pending'
    } else if (val === '30') {
      return 'Approved'
    } else if (val === '40') {
      return 'Rejected'
    } else if (val === '50') {
      return 'Expired'
    }
  },
  /**
   * 重置传递的对象
   * @param {Object} data
   */
  ResetFrom (data) {
    let item = {}
    Object.keys(data).forEach(key => {
      item[key] = ''
    })
    return item
  },

  /**
   * 判断NRIC是否合规
   */
  validateIC (IC) {
    if (IC == null || IC.length != 9) {
      return false;
    }
    // Judge whether the first place meets the specification
    const pre = IC.substr(0, 1);
    // The first digit must be a fixed letter
    if (['S', 'T', 'G', 'F', 'M'].indexOf(pre) < 0) {
      return false;
    }
    const coefficient = [2, 7, 6, 5, 4, 3, 2];
    const num = IC.substr(1, 7);
    let total = 0;
    for (let i = 0; i < num.length; i++) {
      total += coefficient[i] * parseInt(num[i]);
    }
    // If the first letter of the IC begins with T or G, add 4 to the total.
    if (['T', 'G'].indexOf(pre) > -1) {
      total += 4;
    }
    // Calculate remainder
    const remainder = total % 11;
    // Mantissa
    const sum = IC.substr(8, 1);
    if (['S', 'T'].indexOf(pre) > -1) {
      // Alphabet of s or T
      const STList = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
      return STList[remainder] == sum;
    }
    // 2021 new rule
    if (['M'].indexOf(pre) > -1) {
      const lastLetter = IC.substr(8, 1)
      const MList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
      const isNum = /^\d+$/.test(IC.substr(1, 7));
      return isNum && MList.indexOf(lastLetter) > -1
    }
    // Alphabet of f or G
    const FGList = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
    return FGList[remainder] == sum;
  },

  /**
   * table设置背景色
   * @param {*} row 
   * @returns 
   */
  tableRowClassName ({ row }) {
    if (row.type == 'none') {
      return 'row-grey';
    }
  },

  /**
   * get time_horizon
   */
  getTimehorizon (need, needs_analysis) {
    const yearField = global.needs.find(item => item.code == need.protection_needs).yearKey
    let time = ''
    if (need.protection_needs === '463') {
      needs_analysis.education_fund.map(res => {
        if (need.protection_name.includes(res.dependant_name)) {
          time = res.save_years_number
        }
      })
    } else {
      time = needs_analysis[yearField]
    }
    return time
  }
}
