import { render, staticRenderFns } from "./PersonalAccident.vue?vue&type=template&id=f05d991c&scoped=true&"
import script from "./PersonalAccident.vue?vue&type=script&lang=js&"
export * from "./PersonalAccident.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f05d991c",
  null
  
)

export default component.exports