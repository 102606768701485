import MonthlyCashInflow from './MonthlyCashInflow';
import MonthlyCashOutflow from './MonthlyCashOutflow';
import MonthlyCPFInflow from './MonthlyCPFInflow';
import MonthlyCPFOutflow from './MonthlyCPFOutflow';
export default {
  MonthlyCashInflow,
  MonthlyCashOutflow,
  MonthlyCPFInflow,
  MonthlyCPFOutflow
};
