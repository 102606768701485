<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Protection Needs Setup</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="needsData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="protection_name" label="Protection Needs">
				</el-table-column>
				<el-table-column prop="total" label="Shortfall/surplus" width="200">
					<template slot-scope="scope" v-if="scope.row.total !== undefined && scope.row.total">
						S$ {{parseFloat(scope.row.total).toFixed(2)}}
					</template>
				</el-table-column>
				<el-table-column prop="time_horizon" label="Time horizon" width="200">
					<template slot-scope="scope" v-if="scope.row.time_horizon">
						{{scope.row.time_horizon}} year(s)
					</template>
				</el-table-column>
				<el-table-column prop="is_select_type" label="Filter" width="80">
					<template slot-scope="scope">
						<CheckBox :singleChecked.sync="scope.row.is_select_type" :max="scope.$index" @changeStatus="changeStatus"></CheckBox>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import CheckBox from "../../components/checkBox.vue";

export default {
	components: { Navbar, CheckBox },
	data () {
		return {
			needsData: [],
			proposalIndex: 0,
			kycAllData: {},
			dataValue: {}
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
		},
		changeStatus (val, i) {
			if (this.proposalIndex || this.proposalIndex === 0) {
				this.kycAllData.kyc.client[this.kycClient].basis_recommendations.product_recommendations_option[this.proposalIndex].protection_needs_setup = this.needsData
			}
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.needsData = this.$store.state.KYC.protectionNeeds[[this.kycClient]]
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.dataValue = this.kycAllData.kyc.client[this.kycClient]
		// 若有值 则赋值
		this.proposalIndex = this.$router.currentRoute.query.proposal
		if (this.proposalIndex || this.proposalIndex === 0) {
			this.needsData = this.dataValue.basis_recommendations.product_recommendations_option[this.proposalIndex].protection_needs_setup
		}
		this.needsData.map(res => {
			if (res.time_horizon == undefined) {
				res.time_horizon = this.$getTimehorizon(res, this.dataValue.needs_analysis)
			}
		})
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
}
</script>
<style lang='css' scoped>
</style>