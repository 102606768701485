const name = {
    state: {
        // Submission详情
        submissionDetail: null
    },

    getters: {
    },

    mutations: {
        SET_SUBMISSION_DETAIL (state, data) {
            state.submissionDetail = data
        },
    },
    actions: {
    },
}
export default name