import axios from 'utils/request.js'
export const getAreaCode = (data) => {
    return axios.request({
        url: "/api/common/portal/common/areaCode/list",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// 区域字典
export const queryList = (data) => {
    return axios.request({
        url: "/api/product/portal/countryInfo/queryList",
        method: "post",
        title: "",
        data
    })
}
// KYC列表查询
export const kycList = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/kycList",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// submission列表查询
export const submissionList = (data) => {
    return axios.request({
        url: "/api/common/portal/common/submission/getSubmissionList",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// policy列表查询
export const policyList = (data) => {
    return axios.request({
        url: "/api/policyadmin/portal/portal/policy/list",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// KYC保存\修改
export const kycUpdate = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/update",
        method: "post",
        title: "", //设置加载title
        data
    })
}
export const kycUpdateNOLoading = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/update",
        method: "post",
        title: "", //设置加载title
        data,
        showLoading: false
    })
}
// KYC详情
export const editKyc = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/edit",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// KYC删除
export const deleteKyc = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/deleteKyc",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// submission详情
export const editSubmission = (data) => {
    return axios.request({
        url: "/api/policyadmin/portal/portal/lodgment/view",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// policy详情
export const editPolicy = (data) => {
    return axios.request({
        url: "/api/policyadmin/portal/portal/policy/view",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// 调查问卷列表
export const getQuestionAndAnswer = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/getQuestionAndAnswer",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// 根据问卷调查得分查询评级
export const getGrade = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/getGrade",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// KYC审批
export const approval = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/approval",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// KYC请求签名
export const miSignRequest = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/miSignRequest",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// search 模块切换yes/no
export const whetherRecord = (data) => {
    return axios.request({
        url: "/api/common/common/remote/whetherRecord",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// search 模块打开双录
export const videoRecordingStatus = (data) => {
    return axios.request({
        url: "/api/common/common/kyc/videoRecordingStatus",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// KYC PDF生成
export const creatKycPdf = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/creatKycPdf",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// 所有调查问卷分数对应风险等级
export const getAllMatchRiskRating = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/getAllMatchRiskRating",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// 根据邮编查地址
export const getAddress = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/getAddress",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// 校验身份证是否重复
export const verifyKycRepeated = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/verifyKycRepeated",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// 根据身份证查客户KYC列表信息
export const getCopyList = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/getCopyList",
        method: "post",
        title: "", //设置加载title
        data,
        showLoading: false
    })
}
// 复制客户KYC详细信息
export const getCopyDetils = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/getCopyDetils",
        method: "post",
        title: "", //设置加载title
        data
    })
}
// KYC改变锁定状态
export const updateLockStatus = (data) => {
    return axios.request({
        url: "/api/common/portal/common/kyc/updateLockStatus",
        method: "post",
        title: "", //设置加载title
        data
    })
}

// ClientEdit/RepEdit  切换
export const editAuth = (data) => {
    return axios.request({
        url: "/api/common/common/remote/updateEditAuthority",
        method: "post",
        data
    })
}

// 创建邀请链接
export const createKYCLink = (data) => {
    return axios.request({
        url: "/api/common/common/remote/createKycLink",
        method: "post",
        data
    })
}

// 选择产品
export const recommendationProducts = (data) => {
    return axios.request({
        url: "/api/product/portal/api/productapi/productRecommandListQuery",
        method: "post",
        data,
        // showLoading: true
    })
}

// Riders和Funds
export const findRecommendationProduct = (data) => {
    return axios.request({
        url: "/api/product/portal/api/productapi/productRecommandSubListQuery",
        method: "post",
        data
    })
}

// funds search
export const getProductFundsByParam = (data) => {
    return axios.request({
        url: "/api/product/portal/api/productapi/getProductFundsByParam",
        method: "post",
        data
    })
}

export const getPDF = (url) => {
    return axios.request({
        url: url,
        method: "get",
        type: "notoken",
        responseType: 'blob',
        showLoading: false
    })
}