<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Income protection upon critical illness</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :row-class-name="$tableRowClassName">
				<el-table-column prop="name" label="Critical Illness" />
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<el-input v-if="scope.row.type === 'disabled'" v-model="scope.row.AmountShow" disabled></el-input>
						<el-input v-else-if="scope.row.type === 'num'" type="number" v-model="scope.row.Amount"></el-input>
						<div v-else-if="scope.row.type === 'cal'" class="displayFlex flexAlignCenter">
							<span>{{scope.row.AmountShow}}</span>
							<img src="@/assets/img/calculation.png" @click="openAssumptions" alt="" class="ml20px">
						</div>
						<span v-else-if="scope.row.type === 'none'"></span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr">
						</AmountInput>
					</template>
				</el-table-column>
			</el-table>
			<!-- total -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total(surplus/shortfall) S$ <span style="color: black;">{{$formatMoney(totalIlness)}}</span></div>
		</div>
		<AssumptionsDialog ref="AssumptionsDialog" :assumptions="AssumptionsForm" :updateAssumptionsForm="updateAssumptionsForm" :key="timer"></AssumptionsDialog>
	</div>
</template>

<script>
import Navbar from '../../../../components/Navbar.vue';
import AssumptionsDialog from './AssumptionsDialog.vue';
import AmountInput from '../../components/amountInput.vue';
import utils from './index'

export default {
	components: { Navbar, AssumptionsDialog, AmountInput },
	data () {
		return {
			AssumptionsForm: {
				annual_growth_rate: '',
				ror: '',
				inflation_adjusted: ''
			},
			assetsTableData: [
				// {
				// 	Amount: '',
				// 	name: 'Amount required by your dependants',
				// 	value: 'dependants_required_ilness',
				// 	type: 'none'
				// },
				{
					Amount: '',
					name: 'Desired income required to sustain lifestyle during illness (today’s value -PMT)',
					value: 'sustain_ifestyle_required_ilness',
				}, {
					Amount: '0',
					name: 'Number of years income required (n)',
					value: 'required_years_ilness',
					type: 'num'
				}, {
					Amount: '0',
					name: 'Inflation Adjusted Rate Of Return',
					value: 'inflation_adjusted_return_rate_ilness',
					type: 'cal'
				}, {
					Amount: '0',
					name: 'Lump sum required to maintain lifestyle (PV)',
					value: 'maintain_lifestyle_sum_ilness',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Add: Cost of medical treatment',
					value: 'add_medical_cost',
				}, {
					Amount: '0',
					name: 'Add: Other funding needs',
					value: 'add_other_funding_needs',
				}, {
					Amount: '0',
					name: 'Estimated amount required for illness',
					value: 'estimated_amount_required_illness',
					type: 'disabled'
				}, {
					Amount: '0',
					name: 'Less: Existing critical illness insurance',
					value: 'less_existing_critical_illness_insurance',
				}
			],
			kycAllData: {},
			timer: ''
		};
	},
	computed: {
		total_ilness () {
			let num1, num2, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'less_existing_critical_illness_insurance') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'estimated_amount_required_illness') num2 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 - num2).toFixed(10)
			return sum
		},
		totalIlness () {
			return parseFloat(this.total_ilness).toFixed(2)
		},
		estimated_amount_required_illness () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'maintain_lifestyle_sum_ilness') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'add_medical_cost') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'add_other_funding_needs') num3 = parseFloat(res.Amount || 0)
			})
			sum = parseFloat(num1 + num2 + num3).toFixed(10)
			return sum
		},
		// pv
		maintain_lifestyle_sum_ilness () {
			let num1, num2, num3, sum = 0
			this.assetsTableData.map(res => {
				if (res.value === 'inflation_adjusted_return_rate_ilness') num1 = parseFloat(res.Amount || 0)
				if (res.value === 'required_years_ilness') num2 = parseFloat(res.Amount || 0)
				if (res.value === 'sustain_ifestyle_required_ilness') num3 = parseFloat(res.Amount || 0)
			})
			sum = utils.PV(num1, num2, num3)
			return sum
		}
	},
	watch: {
		estimated_amount_required_illness (val) {
			this.changeTableData(val, 'estimated_amount_required_illness')
		},
		maintain_lifestyle_sum_ilness (val) {
			this.changeTableData(val, 'maintain_lifestyle_sum_ilness')
		}
	},
	methods: {
		// 更新rate
		updateAssumptionsForm (val) {
			// 更新子组件
			this.timer = new Date().getTime()
			if (val !== 'close') {
				this.AssumptionsForm = val
				this.assetsTableData.map(res => {
					if (res.value === 'inflation_adjusted_return_rate_ilness' && !isNaN(val.inflation_adjusted)) {
						res.Amount = val.inflation_adjusted
						res.AmountShow = parseFloat(val.inflation_adjusted).toFixed(2)
					}
				})
				this.$forceUpdate();
				this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.annual_growth_rate_ilness = this.AssumptionsForm.annual_growth_rate
				this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.ror_ilness = this.AssumptionsForm.ror
			} else {
				this.setAssumptionsForm()
			}
		},
		setAssumptionsForm () {
			let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
			// 1.先取needs_analysis中的值 2.如果没有则取financial_objectives中的值
			if (dataValue0.needs_analysis.annual_growth_rate_ilness) {
				this.AssumptionsForm.annual_growth_rate = dataValue0.needs_analysis.annual_growth_rate_ilness
			} else {
				this.AssumptionsForm.annual_growth_rate = dataValue0.financial_objectives.annual_growth_rate ? dataValue0.financial_objectives.annual_growth_rate : '0'
			}
			if (dataValue0.needs_analysis.ror_ilness) {
				this.AssumptionsForm.ror = dataValue0.needs_analysis.ror_ilness
			} else {
				this.AssumptionsForm.ror = dataValue0.financial_objectives.ror ? dataValue0.financial_objectives.ror : 0
			}
		},
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		openAssumptions () {
			this.$refs.AssumptionsDialog.Dialog.visible = true
			this.$refs.AssumptionsDialog.AssumptionsFormDialog = this.AssumptionsForm
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			this.assetsTableData.map(res => { this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis[res.value] = res.Amount })
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].needs_analysis.total_ilness = this.total_ilness
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 修改表格中的计算数据
		changeTableData (val, field) {
			this.assetsTableData.map(res => {
				if (res.value === field && !isNaN(val)) {
					res.Amount = val
					res.AmountShow = 'S$ ' + this.$formatMoney(parseFloat(val).toFixed(2))
				}
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		let dataValue0 = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		// 若有值 则赋值
		this.assetsTableData.map((key, i) => {
			key.Amount = dataValue0.needs_analysis[key.value]
			key.AmountShow = key.type === 'cal' ? (Math.round(key.Amount * 100) / 100).toFixed(2) : 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100).toFixed(2))
			key.index = i
		})
		this.setAssumptionsForm()
	},
}
</script>
<style lang='css' scoped>
</style>