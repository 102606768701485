import operate from './module/operate' //操作处理
import dateHandler from './module/dateHandler' //日期处理
import amountFormat from './module/amountFormat' //金额处理


export default {
  ...operate,
  ...dateHandler,
  ...amountFormat
}
